import React from 'react';
import { Box, Card, CircularProgress, Typography } from '@mui/material';

const DetailsCard = (props) => {

     return (

          <Card
               sx={{ 
                    height: '100%',
                    minHeight: '420px',
                    width: '100%',
                    boxShadow: '1px 1px 8px 3px #DBD8F1',
               }}
          >
               <Box pt={.6}>
                    <Typography
                         sx={{
                              fontSize: '19px',
                              fontWeight: 'bold',
                              color: theme => theme.palette.grey.main,
                              textAlign: 'center',
                         }}
                    >
                         {props.title}
                    </Typography>
               </Box>

               {props.loading ? (

                    <Box sx={{
                         width: '100%',
                         height: '100%',
                         display: 'flex',
                         flexDirection: 'column',
                         justifyContent: 'center',
                         alignItems: 'center',
                    }}>
                         <CircularProgress />
                         <Typography sx={{ color: theme => theme.palette.grey.main }}>Loading...</Typography>
                    </Box>

                    ) : (

                         props.cardData.map((item, index) => 
                              <Box key={index} 
                                   sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                        backgorund: '#FFFFFF',
                                        boxShadow: '1px 1px 6px 3px #DBD8F1',
                                        borderRadius: '10px',
                                        margin: '10px',
                                        p: 1.2,
                                   }}
                              >
                                   <Box>
                                        <Typography 
                                             sx={{
                                                  color: theme => theme.palette.primary.main,
                                                  fontSize: '14px',
                                                  fontWeight: 'bold',
                                             }}
                                        >
                                             {item.name}
                                        </Typography>
                                   </Box>
                                   <Box>
                                        <Typography
                                             sx={{ 
                                                  color: theme => theme.palette.grey.main,
                                                  fontSize: '14px',
                                                  fontWeight: 'bold',
                                             }}
                                        >
                                             {item.value}
                                        </Typography>
                                   </Box>
          
                              </Box>
                         )
               )}

          </Card>
     );
}

export default DetailsCard;