import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import AuthMethods from 'app/services';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Div from '@jumbo/shared/Div/Div';
import CustomDialog from '@custom/Components/CustomDialog';
import LoginRedirect from './LoginRedirect';

const useStyles = makeStyles({
     required: {
          color: '#E73145',
     },   
});

export default function ChangePassword(props) {

     const classes = useStyles();

     const { authUser } = useJumboAuth();
     const { enqueueSnackbar } = useSnackbar();

     const [formData, setFormData] = useState({
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
     });
     const [errorFormData, setErrorFormData] = useState({
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
     });
     const [showCurrentPassword, setShowCurrentPassword] = useState(false);
     const [showNewPassword, setShowNewPassword] = useState(false);
     const [showConfirmPassword, setShowConfirmPassword] = useState(false);
     const [message, setMessage] = useState('');
     const [openDialog, setOpenDialog] = useState(false);
  
     const handleShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
     const handleShowNewPassword = () => setShowNewPassword((show) => !show);
     const handleShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  
     const handleMouseDownPassword = (event) => {
       event.preventDefault();
     };

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const handleDialogOpen = () => {
          setOpenDialog(true);
     };

     const savePasswordForm = () => {

          let passwordData = {
               current_password: formData['currentPassword'],
               password: formData['newPassword'],
               confirm_password: formData['confirmPassword'] 
          }

          AuthMethods['auth'].changeUserPassword(authUser['data']['id'], passwordData, setMessage);
     };

     const checkFormData = name => {

          if (!formData[name]) {
               setErrorFormData(prev => {
                    return { ...prev, [name]: 'This field is required' }
               });
               return false;
          }

          if (name === 'confirmPassword') {
               if (formData['newPassword'] !== formData['confirmPassword']) {
                    setErrorFormData(prev => {
                         return { ...prev, [name]: 'Passwords do not match' };
                    });
                    return false;
               }
          }

          return true;
     };

     const changePassword = (e) => {
          e.preventDefault();

          let isValid = true;

          isValid = checkFormData('currentPassword') && isValid;
          isValid = checkFormData('newPassword') && isValid;
          isValid = checkFormData('confirmPassword') && isValid;

          if (isValid) {
               savePasswordForm();
          }
     };

     useEffect(() => {

          if (message !== '') {
               if (message === 'Success') {
                    enqueueSnackbar('Password changed successfully', {
                         variant: 'success'
                    });
                    
                    handleDialogOpen();
               } else {
                    enqueueSnackbar(message, {
                         variant: 'error'
                    });
               }

               setMessage('');
          }
          
     }, [message]);

     return (
          
          <React.Fragment>
               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Change User Password
               </DialogTitle>

               <form>
                    <DialogContent dividers>
                         <Div sx={{ width: '100%' }}>
                              <Div>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Current Password <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <FormControl variant="outlined" fullWidth>
                                        <OutlinedInput
                                             id="outlined-adornment-current-password"
                                             size="small"
                                             type={showCurrentPassword ? 'text' : 'password'}
                                             endAdornment={
                                                  <InputAdornment position="end">
                                                       <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleShowCurrentPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                       >
                                                       {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                                       </IconButton>
                                                  </InputAdornment>
                                             }
                                             value={formData['currentPassword']}
                                             onChange={e => {
                                                  setFormData({ ...formData, currentPassword: e.target.value });
                                                  setErrorFormData({ ...errorFormData, currentPassword: '' })
                                             }}
                                        />
                                        <FormHelperText sx={{
                                             color: theme => theme.palette.error.main,
                                             ml: 2,
                                             minHeight: '3vh'
                                        }}
                                        >
                                             {errorFormData['currentPassword']}
                                        </FormHelperText>
                                   </FormControl>
                              </Div>
                              <Div>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        New Password <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <FormControl variant="outlined" fullWidth>
                                        <OutlinedInput
                                             id="outlined-adornment-new-password"
                                             size="small"
                                             type={showNewPassword ? 'text' : 'password'}
                                             endAdornment={
                                                  <InputAdornment position="end">
                                                       <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleShowNewPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                       >
                                                       {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                       </IconButton>
                                                  </InputAdornment>
                                             }
                                             value={formData['newPassword']}
                                             onChange={e => {
                                                  setFormData({ ...formData, newPassword: e.target.value });
                                                  setErrorFormData({ ...errorFormData, newPassword: '' });
                                             }}
                                        />
                                        <FormHelperText sx={{
                                             color: theme => theme.palette.error.main,
                                             ml: 2,
                                             minHeight: '3vh'
                                        }}
                                        >
                                             {errorFormData['newPassword']}
                                        </FormHelperText>
                                   </FormControl>
                              </Div>
                              <Div>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Confirm Password <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <FormControl variant="outlined" fullWidth>
                                        <OutlinedInput
                                             id="outlined-adornment-confirm-password"
                                             size="small"
                                             type={showConfirmPassword ? 'text' : 'password'}
                                             endAdornment={
                                                  <InputAdornment position="end">
                                                       <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleShowConfirmPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                       >
                                                       {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                       </IconButton>
                                                  </InputAdornment>
                                             }
                                             value={formData['confirmPassword']}
                                             onChange={e => {
                                                  setFormData({ ...formData, confirmPassword: e.target.value });
                                                  setErrorFormData({ ...errorFormData, confirmPassword: '' });
                                             }}
                                        />
                                        <FormHelperText sx={{
                                             color: theme => theme.palette.error.main,
                                             ml: 2,
                                             minHeight: '3vh'
                                        }}
                                        >
                                             {errorFormData['confirmPassword']}
                                        </FormHelperText>
                                   </FormControl>
                              </Div>
                         </Div>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between', flexWrap: 'wrap' }}>
                              <Box pb={1}>
                                   <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                        Cancel
                                   </CustomButton>
                              </Box>
                              <Box pb={1}>
                                   <CustomButton variant="contained" color="primary" icon="" onClick={changePassword}>
                                        Change Password
                                   </CustomButton>
                              </Box>
                         </Box>
                    </DialogActions>
               </form>

               <CustomDialog openDialog={openDialog}>
                    <LoginRedirect setOpenDialog={setOpenDialog} />
               </CustomDialog>

          </React.Fragment>
     );
}
