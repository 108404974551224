import React, { useEffect, useState } from 'react';
import AuthMethods from 'app/services';
import _, { isNull } from 'lodash';
import DetailsCard from '@custom/Components/DetailsCard';

export default function AuditeeSiteCard(props) {

     const [loading, setLoading] = useState(false);
     const [cardData, setCardData] = useState([]);

     useEffect(() => {
          if (props.auditPlanDataId !== '') {
               
               setLoading(true);
               AuthMethods['auditee'].getAuditeeSiteDetails(parseInt(props.auditPlanDataId)).then(data => {
                    // console.log(data);
                    setLoading(false);

                    if (_.size(data['audited_data']) > 0) {

                         let date = !isNull(data['audited_data']['auditor_review_submitted_date']) ? data['audited_data']['auditor_review_submitted_date'].split('-') : '';
                         let subDate = date ? (date[1] + '/' + date[2] + '/' + date[0]) : '';
                         
                         props.setAuditeeStatus(data['audited_data']['status']);
                         props.setReviewer1Date(data['audited_data']['reviewer1_submitted_date']);
                         props.setReviewer2Date(data['audited_data']['reviewer2_submitted_date']);
                         props.setAuditorSubDate(subDate);

                         setCardData([
                              {
                                   name: 'State',
                                   value: _.size(data['audited_data']['state']) > 0 ? data['audited_data']['state']['name'] : '-', 
                              },
                              {
                                   name: 'Site',
                                   value: _.size(data['audited_data']['site']) > 0 ? data['audited_data']['site']['name'] : '-',
                              },
                              {
                                   name: 'Capacity (MW)',
                                   value: _.size(data['audited_data']['site']) > 0 ? data['audited_data']['site']['ac_capacity'] : '-',
                              },
                              {
                                   name: 'Auditor',
                                   value: _.size(data['audited_data']['auditor_1']) > 0 ? data['audited_data']['auditor_1']['name'] : '-',
                              },
                              {
                                   name: 'Auditee',
                                   value: _.size(data['audited_data']['auditee']) > 0 ? data['audited_data']['auditee']['name'] : '-',
                              },
                              {
                                   name: 'Audit Duration',
                                   value: data['audited_data']['start_date'] + ' - ' + data['audited_data']['end_date'],
                              },
                              {
                                   name: 'Current | Initial',
                                   value: '-',
                              }
                         ]);

                         AuthMethods['common'].getSiteHealthScore(parseInt(props.auditPlanDataId)).then(data => {
                              // console.log(cardData);
                              if (_.size(data['audited_data']) > 0) {
          
                                   setCardData(prev => {
                                        // console.log(prev);
                                        let length = prev.length;
                                        prev[length - 1] = {
                                             'name': 'Current | Initial',
                                             'value': data['audited_data']['current'] + '% | ' + data['audited_data']['initial'] + '%',
                                        };
          
                                        return [...prev];
                                   });
                              }
                         });
                    }
               });

          }

     }, [props.auditPlanDataId, props.cardDataChanged]);

     return (

          <DetailsCard
               title="Site Details" 
               loading={loading}
               cardData={cardData}
          />
     );
}
