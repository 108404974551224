import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import SolarTable from '@custom/Components/SolarTable';
import { Delete, Edit } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomDialog from '@custom/Components/CustomDialog';
import AddCategory from './AddCategory';
import ChecklistHeader from './ChecklistHeader';
import AddChecklist from './AddChecklist';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AuthMethods from 'app/services';

const columns = [
     { title: 'ID', field: 'id', hidden: true, hiddenByColumnsButton: true },
     { title: 'Checklist', field: 'checklist' },
     { title: 'Weightage', field: 'weightage' },
];

export default function ChecklistTable(props) {

     const MySwal = withReactContent(Swal);
     const categoryId = props.rowData.rowData.id;
     const categoryName = props.rowData.rowData.category;
     const checklistTableDataChanged = props.checklistTableDataChanged;
     const setChecklistTableDataChanged = props.setChecklistTableDataChanged;

     const [tableData, setTableData] = useState([]);     
     const [openDialog, setOpenDialog] = useState(false);
     const [action, setAction] = useState('');
     const [loading, setLoading] = useState(false);
     const [checklistId, setChecklistId] = useState('');

     const editChecklist = (rowData) => {
          setAction('edit');
          setOpenDialog(true);
          setChecklistId(rowData.id);
     };
     
     const handleDeleteChecklist = (rowData) => {
          MySwal.fire({
               title: 'Are you sure to delete this checklist?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Yes, delete!',
               cancelButtonText: 'No, cancel!',
               reverseButtons: true,
          }).then(result => {
               if (result.value) {
                    setLoading(true);
                    AuthMethods['checklist'].deleteChecklist(rowData.id)
                         .then(data => {

                              let tempData = [...tableData];
                              tempData = tempData.filter(item => 
                                   item.id !== rowData.tableData.id
                              );
                              setTableData(tempData);

                              Swal.fire('Deleted!', 'Checklist has been deleted', 'success');
                              setLoading(false);
                         })
                         .catch(error => {
                              Swal.fire('Error occured while deleting', '');
                              setLoading(false);
                         });
               } else if (
                    result.dismiss === Swal.DismissReason.cancel
               ) {
                    Swal.fire('Cancelled!', 'Checklist has not been deleted', 'error');
               }
          });
     };

     useEffect(() => {
          setLoading(true);
          AuthMethods['checklist'].getChecklistByCategoryId(categoryId, setLoading).then(data => {
               // console.log(data);

               if (data['data'].length > 0) {
                    let temp = data['data'].map(item => {
                         return {
                              id: item.id,
                              checklist: item.name,
                              weightage: item.weightage
                         }
                    });
                    setTableData(temp);
               }
          });

     }, [checklistTableDataChanged]);

     return (

          <React.Fragment>
               <Box sx={{ padding: '20px 10px' }}>
                    <SolarTable
                         title={categoryName + ' - Checklist Items'}
                         columns={columns}
                         data={tableData}
                         loading={loading}
                         actions={
                              [
                                   {
                                        icon: () => {
                                        return <Edit 
                                        fontSize="small" 
                                        sx={{ color: theme => theme.palette.info.main }}
                                        />;
                                        },
                                        tooltip: "Edit Checklist",
                                        onClick: (event, rowData) => {
                                             editChecklist(rowData);
                                        }
                                   },
                                   {
                                        icon: () => {
                                        return <Delete 
                                        fontSize="small" 
                                        sx={{ color: theme => theme.palette.error.main }} 
                                        />;
                                        },
                                        tooltip: "Delete Checklist",
                                        onClick: (event, rowData) => {
                                             handleDeleteChecklist(rowData);
                                        }
                                   },
                              ]
                         }
                         detailPanel={[]}
                         insideDetailPanel={true}
                    />
               </Box>

               <CustomDialog openDialog={openDialog}>
                    <AddChecklist setOpenDialog={setOpenDialog} action={action} checklistId={checklistId} setChecklistTableDataChanged={setChecklistTableDataChanged} />
               </CustomDialog>

          </React.Fragment>

     );
}
