import React, { useEffect, useRef } from "react";
import { Box, Paper } from "@mui/material";
import { MessageLeft, MessageRight } from "./Message.jsx";
// import { TextInput } from "./TextInput.jsx";

export default function ChatBox(props) {

     const messageRef = useRef(null);

     useEffect(() => {
          if (messageRef.current) {
               // console.log(messageRef);
               messageRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
               });
          }
        }, [props.commentsData]);
     
     return (
          <Box>
               <Paper
                    sx={{
                         width: '100%',
                         height: '60vh',
                         padding: '15px',
                         overflowY: 'auto',
                         overflowX: 'hidden',
                    }}
               >
                    <Box ref={messageRef}>
                         {props.commentsData.length > 0 ? (
                              props.commentsData.map((item, index) => {
                                   // console.log(item);
                                   return (
                                        <MessageLeft
                                             key={index}
                                             message={item.comments}
                                             displayName={item.name}
                                             timestamp={item.time}
                                             avatarDisp={false}
                                        />
                                   );
                              })
                         ) : (
                              <MessageLeft noMessage={'No Comments'} />
                         )}
                    </Box>
                    
                    {/* <MessageRight
                         message="My Name is Shweta"
                         timestamp="YYYY-MM-DD 00:00"
                         photoURL=""
                         displayName="User1"
                         avatarDisp={true}
                    />
                    <MessageRight
                         message="Oh wow!"
                         timestamp="YYYY-MM-DD 00:00"
                         photoURL=""
                         displayName="User2"
                         avatarDisp={false}
                    /> */}
               </Paper>
          {/* <TextInput /> */}
          </Box>
     );
}
