import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField, Typography } from '@mui/material';
import Dropdown from '@custom/Components/Dropdown';
import ErrorMessage from '@custom/Components/ErrorMessage';
import CustomButton from '@custom/Components/CustomButton';
import { makeStyles } from '@mui/styles';
import ImageDropzone from '@custom/Components/ImageDropzone';
import AuthMethods from 'app/services';
import PageLoader from '@custom/Components/PageLoader';
import _, { isNull } from 'lodash';
import CustomDialog from '@custom/Components/CustomDialog';
import { useSnackbar } from 'notistack';
import CommonPreview from '../AuditorSummary/CommonPreview';

const useStyles = makeStyles({
     required: {
          color: '#E73145',
     },
});

const severityDropdown = [ 
     { id: 0, name: 'Ok' }, 
     { id: 1, name: 'Low' }, 
     { id: 2, name: 'Medium' },
     { id: 3, name: 'High' },
];

export default function ARChecklistDetails(props) {

     const classes = useStyles();
     const { enqueueSnackbar } = useSnackbar();

     const [loading, setLoading] = useState(false);
     const [severity, setSeverity] = useState(0);
     const [severityError, setSeverityError] = useState('');
     const [remarks, setRemarks] = useState('');
     const [remarksError, setRemarksError] = useState('');
     const [image, setImage] = useState(null);
     const [imageError, setImageError] = useState('');
     const [newImageError, setNewImageError] = useState('');
     const [uploadedImage, setUploadedImage] = useState(null);
     const [openDialog, setOpenDialog] = useState(false);
     const [disable, setDisable] = useState(false);
     const [message, setMessage] = useState('');

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const handlePreview = () => {
          setOpenDialog(true);
     };

     const saveForm = () => {

          setLoading(true);

          const formData = new FormData();
          
          formData.append('audit_sample_data_id', props.auditSampleDataId);
          formData.append('severity', severity);

          if (severity === 2 || severity === 3) {
               formData.append('remarks', remarks);
               formData.append('upload_file', image);
          } else {
               if (remarks !== '' ) {
                    formData.append('remarks', remarks);          
               }
               if (!isNull(image)) {
                    formData.append('upload_file', image);               
               }
          }

          // console.log([...formData]);

          AuthMethods['reviewer'].saveARChecklistDetails(formData, props.setTableDataChanged, props.setCardDataChanged, setMessage, setLoading);

     };

     const handleSave = (e) => {
          e.preventDefault();

          let isValid = true;

          if (severity === '') {
               setSeverityError('This field is required');
               isValid = false;
          }

          if (severity === 2 || severity === 3) {
               if (!remarks) {
                    setRemarksError('This field is required');
                    isValid = false;
               }
               if (!uploadedImage) {
                    if (!image) {
                         setImageError('This field is required');
                         isValid = false;
                    }
               }

               if (disable) {
                    if (!image) {
                         setNewImageError('This field is required');
                         isValid = false;
                    }
               }
          }

          if (isValid) {
               saveForm();
          }

     };

     useEffect(() => {

          if (message !== '') {
               if (message === 'Success') {
                    props.setOpenDialog(false);
                    enqueueSnackbar('Details saved successfully', {
                         variant: 'success'
                    });
               } else {
                    enqueueSnackbar(message, {
                         variant: 'error'
                    });
               }
               setMessage('');
          }
          
     }, [message]);

     useEffect(() => {
          
          if (!isNull(image)) {
               setImageError('');
               setNewImageError('');
          }

     }, [image]);

     useEffect(() => {
          setLoading(true);
          if (props.auditSampleDataId !== '' && props.reviewStatus !== '') {
               AuthMethods['reviewer'].getARChecklistDetails(props.auditSampleDataId).then(data => {
                    // console.log(data);
                    setLoading(false);
                    if (_.size(data['data']) > 0) {
                         setSeverity(data['data']['severity']);
                         setRemarks(!isNull(data['data']['auditor_remarks']) ? data['data']['auditor_remarks'] : '');
                         setUploadedImage(data['data']['auditor_image_url']);
                    }
               });
          }
     }, [props.auditSampleDataId, props.reviewStatus]);

     return (

          <React.Fragment>

               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Checklist Details
               </DialogTitle>
          
               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px',
                                             whiteSpace: 'break-spaces',
                                        }}
                                   >
                                        Equipment -
                                   </InputLabel>
                                   <Typography sx={{
                                        color: theme => theme.palette.blue.dark,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                   }}>
                                        {props.equipmentChecklistName[0]}
                                   </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px',
                                             whiteSpace: 'break-spaces',
                                        }}
                                   >
                                        Checklist Item -
                                   </InputLabel>
                                   <Typography sx={{
                                        color: theme => theme.palette.blue.dark,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                   }}>
                                        {props.equipmentChecklistName[1]}
                                   </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Severity Dropdown <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select Severity"
                                        name="severity"
                                        value={severity}
                                        dropdown={severityDropdown}
                                        onChange={e => {
                                             setSeverity(e.target.value);
                                             setSeverityError('');
                                             setRemarksError('');
                                             setImageError('');
                                             setNewImageError('');
                                        }}
                                        disabled={(props.reviewStatus === 3) ? false : true}
                                   />
                                   <ErrorMessage>{severityError}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Remarks {(severity === 2 || severity === 3) ? <span className={classes.required}>*</span> : ''}
                                   </InputLabel>
                                   <TextField
                                        name="remarks"
                                        type="text"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        size="small"
                                        value={remarks}
                                        onChange={e => {
                                             setRemarks(e.target.value);
                                             setRemarksError('');
                                        }}
                                        disabled={(props.reviewStatus === 3) ? false : true}
                                   />
                                   <ErrorMessage>{remarksError}</ErrorMessage>
                              </Grid>
                              {!isNull(uploadedImage) ? (
                                   <React.Fragment>
                                        <Grid item xs={12} sm={12}>
                                             <InputLabel
                                                  sx={{ color: theme => theme.palette.text.secondary,
                                                  fontSize: '13px',
                                                  fontWeight: 'bold',
                                                  pb: '3px'
                                                  }}
                                             >
                                                  Uploaded Image
                                             </InputLabel>
                                             <CustomButton
                                                       variant="contained"
                                                       color="grey"
                                                       icon="getPreviewIcon" 
                                                       onClick={handlePreview}
                                                       disabled={disable ? true : false}
                                                  >
                                                       Preview
                                             </CustomButton>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                        <InputLabel
                                             sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px'
                                             }}
                                        >
                                             Upload New Image {disable ? (severity === 2 || severity === 3) ? <span className={classes.required}>*</span> : '' : ''}
                                        </InputLabel>
                                        <ImageDropzone
                                             image={image}
                                             setImage={setImage} 
                                             setDisable={setDisable}
                                             reviewStatus={props.reviewStatus}
                                        />
                                        <ErrorMessage>{newImageError}</ErrorMessage>
                                   </Grid>
                              </React.Fragment>
                              ) : (
                                   <Grid item xs={12} sm={12}>
                                        <InputLabel
                                             sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px'
                                             }}
                                        >
                                             Upload Image {(severity === 2 || severity === 3) ? <span className={classes.required}>*</span> : ''}
                                        </InputLabel>
                                        <ImageDropzone
                                             image={image}
                                             setImage={setImage} 
                                             setDisable={setDisable}
                                             reviewStatus={props.reviewStatus}
                                        />
                                        <ErrorMessage>{imageError}</ErrorMessage>
                                   </Grid>
                              )}
                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton 
                                   variant="contained" 
                                   color="primary" icon="" 
                                   onClick={handleSave} 
                                   disabled={(props.reviewStatus === 3) ? false : true}
                              >
                                   Save
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>

               <CustomDialog openDialog={openDialog} maxWidth="lg">
                    <CommonPreview setOpenDialog={setOpenDialog} image={uploadedImage} />
               </CustomDialog>

          </React.Fragment>
     );
}