import React from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
} from "@mui/material";
import ColumnHighcharts from "@custom/Charts/ColumnHighcharts";
import SolarTable from "@custom/Components/SolarTable";
import ChartLoader from "@custom/Charts/ChartLoader";
import NextPlanIcon from '@mui/icons-material/NextPlan';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import _ from "lodash";

const compColumns = [
  {
  title: "ID",
  field: "id",
  filtering: false,
  hidden: true,
  hiddenByColumnsButton: true,
  },
  {
  title: "Component",
  field: "component",
  filtering: false,
  },
  {
  title: "Initial Observations",
  field: "planned",
  filtering: false,
  },
  {
  title: "Closed Observations",
  field: "done",
  filtering: false,
  },
  {
  title: "Completion (%)",
  field: "completion",
  filtering: false,
  },
];

const stateColumns = [
  {
  title: "ID",
  field: "id",
  filtering: false,
  hidden: true,
  hiddenByColumnsButton: true,
  },
  {
  title: "State",
  field: "state",
  filtering: false,
  },
  {
  title: "Initial Observations",
  field: "planned",
  filtering: false,
  },
  {
  title: "Closed Observations",
  field: "done",
  filtering: false,
  },
  {
  title: "Completion (%)",
  field: "completion",
  filtering: false,
  },
];

export default function StatusTab(props) {

  return (
    <React.Fragment>
      <Grid container spacing={3} mb={3}>
        
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              boxShadow: '1px 1px 8px 3px #DBD8F1',
              background: theme => theme.palette.info.dark, 
            }}
          >
            <Box
              sx={{ 
                p: 2,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
               }}
            >
              <NextPlanIcon sx={{ 
                color: theme => theme.palette.secondary.main, 
                fontSize: '65px' 
              }} 
              />
              <Box pl={2}>
                <Typography
                  sx={{ 
                    color: theme => theme.palette.secondary.main, 
                    fontSize: '16px',
                   }}
                >
                  Planned Sites
                </Typography>
                  <Typography
                    sx={{
                      color: theme => theme.palette.secondary.main, 
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {props.planned}
                  </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              boxShadow: '1px 1px 8px 3px #DBD8F1',
              background: theme => theme.palette.purple.main, 
            }}
          >
            <Box
              sx={{ 
                p: 2,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <VerifiedUserIcon sx={{ 
                color: theme => theme.palette.secondary.main, 
                fontSize: '65px' 
              }} 
              />
              <Box pl={2}>
                <Typography
                  sx={{ 
                    color: theme => theme.palette.secondary.main, 
                    fontSize: '16px',
                   }}
                >
                  Audited Sites
                </Typography>
                  <Typography
                    sx={{
                      color: theme => theme.palette.secondary.main, 
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {props.audited}
                  </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              boxShadow: '1px 1px 8px 3px #DBD8F1',
              background: theme => theme.palette.success.main, 
            }}
          >
            <Box
              sx={{ 
                p: 2,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <EventAvailableIcon sx={{ 
                color: theme => theme.palette.secondary.main, 
                fontSize: '65px' 
              }} 
              />
              <Box pl={2}>
                <Typography
                  sx={{ 
                    color: theme => theme.palette.secondary.main, 
                    fontSize: '16px',
                   }}
                >
                  Completed Sites
                </Typography>
                  <Typography
                    sx={{
                      color: theme => theme.palette.secondary.main, 
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {props.completed}
                  </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={12}>
          <Card
            sx={{
              p: 1,
              boxShadow: "0px 0px 10px 1px #DBD8F1",
              height: "100%",
              minHeight: "420px",
            }}
          >
            {props.loadingS ? (
              <ChartLoader title="Loading..." />
            ) : (
              <ColumnHighcharts chartData={props.chartDataAuditPlansReport} />
            )}
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Grid container spacing={1}
            sx={{ 
              background: 'white', 
              boxShadow: '0px 0px 6px 0px #DBD8F1',
              borderRadius: '10px',
              padding: '0 10px 10px 0',
            }}
          >
            <Grid item xs={12} sm={12}>
              <Typography
                sx={{
                  p: 1,
                  pb: 1.5,
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: 'center',
                  color: theme => theme.palette.primary.main,
                }}
              >
                Technical Health Assessment - FY {props.fyName}
              </Typography>
              <Box 
                sx={{
                  display: "flex", 
                  flexWrap: "wrap", 
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: .3,
                }}
              >
                <Box 
                  sx={{ 
                    p: 1,
                    mb: .6,
                    background: theme => theme.palette.purple.main,
                    borderRadius: '10px',
                    boxShadow: '1px 1px 8px 3px #DBD8F1',
                  }}
                >
                  <Typography sx={{ color: theme => theme.palette.secondary.main, fontSize: '16px', fontWeight: 'bold', }}>Overall Summary</Typography>
                </Box>
                <Box 
                  sx={{ 
                    display: "flex",
                    flexWrap: "wrap", 
                    p: 1,
                    mb: .6,
                    background: theme => theme.palette.purple.main,
                    borderRadius: '10px',
                    boxShadow: '1px 1px 8px 3px #DBD8F1',
                  }}
                >
                  <Typography sx={{ color: theme => theme.palette.secondary.main, pr: 3, fontSize: '16px', fontWeight: 'bold', }}>Initial Observations - {props.tCardData['planned']}</Typography>
                  <Typography sx={{ color: theme => theme.palette.secondary.main, pr: 3, fontSize: '16px', fontWeight: 'bold', }}>Closed Observations - {props.tCardData['done']}</Typography>
                  <Typography sx={{ color: theme => theme.palette.secondary.main, fontSize: '16px', fontWeight: 'bold', }}>Completion - {props.tCardData['completion']}</Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <SolarTable
                title="Component Wise"
                columns={compColumns}
                data={props.componentData}
                filtering={false}
                loading={props.tableLoadingS}
                search={false}
                columnsButton={false}
                paging={false}
                actions={[]}
                detailPanel={[]}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SolarTable
                title="State Wise"
                columns={stateColumns}
                data={props.stateData}
                filtering={false}
                loading={props.tableLoadingS}
                search={false}
                columnsButton={false}
                paging={false}
                actions={[]}
                detailPanel={[]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </React.Fragment>

  );
}
