
import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import Dropdown from '@custom/Components/Dropdown';
import ErrorMessage from '@custom/Components/ErrorMessage';
import { makeStyles } from '@mui/styles';
import AuthMethods from 'app/services';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
     required: {
       color: '#E73145',
     },
});

export default function Approval(props) {

     const classes = useStyles();
     const userId = localStorage.getItem('userId');
     const {enqueueSnackbar} = useSnackbar();

     const [approval, setApproval] = useState('');
     const [approvalError, setApprovalError] = useState('');
     const [comment, setComment] = useState('');
     const [commentError, setCommentError] = useState('');
     const [message, setMessage] = useState('');

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const submitApproval = () => {
          let isValid = true;

          if (props.approver1Role || props.approver2Role) {
               if (!approval) {
                    setApprovalError('This field is required');
                    isValid = false;
               }
          }

          if (!comment) { 
               setCommentError('This field is required');
               isValid = false;
          }

          if (isValid) {

               let planApproval = approval === '1' ? true : false;
               
               let approvalData = {
                    user_id: userId,
                    fy_id: props.financialYear,
                    audit_plan_id: props.auditPlanID,
                    plan_approve: planApproval,
                    comments: comment
               };

               props.setLoading(true);
               AuthMethods['auditPlan'].sendApproval(approvalData, props.setTableDataChanged, setMessage, props.setLoading);
          }

     };

     useEffect(() => {
          if (message !== '') {
               if (message === 'Success') {
                    props.setOpenDialog(false);
                    enqueueSnackbar('Approval sent successfully', {
                         variant: 'success'
                    });
               } else {
                    enqueueSnackbar(message, {
                         variant: 'error'
                    });
               }
               setMessage('');
          }

     }, [message]);

     return (

          <React.Fragment>
               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Approval Form
               </DialogTitle>
          
               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2} mb={2}>
                              
                              {(props.approver1Role || props.approver2Role) && (
                                   <Grid item xs={12} sm={12}>
                                        <InputLabel
                                             sx={{ color: theme => theme.palette.text.secondary,
                                                  fontSize: '13px',
                                                  fontWeight: 'bold',
                                                  pb: '3px'
                                             }}
                                        >
                                             Approve / Reject <span className={classes.required}>*</span>
                                        </InputLabel>
                                        <RadioGroup
                                             row
                                             aria-labelledby="demo-controlled-radio-buttons-group"
                                             name="controlled-radio-buttons-group"
                                             value={approval}
                                             onChange={e => {
                                                  setApproval(e.target.value);
                                                  setApprovalError('');
                                             }}
                                        >
                                             <FormControlLabel
                                                  value="1"
                                                  control={<Radio color="blue" />} 
                                                  label={<Typography
                                                       sx={{
                                                            color: theme => theme.palette.blue.main,
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                       }}
                                                       >
                                                            Approve
                                                       </Typography>
                                                  }
                                             />
                                             <FormControlLabel 
                                                  value="0"
                                                  control={<Radio color="grey" />} 
                                                  label={<Typography
                                                       sx={{
                                                            color: theme => theme.palette.grey.main,
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                       }}
                                                       >
                                                            Reject
                                                       </Typography>
                                                  } 
                                             />
                                        </RadioGroup>
                                        <ErrorMessage>{approvalError}</ErrorMessage>
                                   </Grid>
                              )}
                              
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px'
                                        }}
                                   >
                                        Comments <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        name="comments"
                                        type="text"
                                        size="small"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        value={comment}
                                        onChange={e => {
                                             setComment(e.target.value);
                                             setCommentError('');
                                        }}
                                   />
                                   <ErrorMessage>{commentError}</ErrorMessage>
                              </Grid>
                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton variant="contained" color="primary" icon="" onClick={submitApproval}>
                                   Submit
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>
          </React.Fragment>

     );
}
