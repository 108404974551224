import React from 'react';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CategoryIcon from '@mui/icons-material/Category';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import PreviewIcon from '@mui/icons-material/Preview';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BrightnessLowIcon from '@mui/icons-material/BrightnessLow';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import DownloadIcon from '@mui/icons-material/Download';

const buttonIcons = {
     getPersonAddIcon: () => {
          return <PersonAddIcon />;
     },
     getCategoryIcon: () => {
          return <CategoryIcon />;
     },
     getBeenhereIcon: () => {
          return <BeenhereIcon />;
     },
     getDomainAddIcon: () => {
          return <DomainAddIcon />;
     },
     getPostAddIcon: () => {
          return <PostAddIcon />;
     },
     getAddToQueueIcon: () => {
          return <AddToQueueIcon />;
     },
     getPreviewIcon: () => {
          return <PreviewIcon />;
     },
     getCheckCircleOutlineIcon: () => {
          return <CheckCircleOutlineIcon />;
     },
     getBrightnessLowIcon: () => {
          return <BrightnessLowIcon />;
     },
     getBrightnessMediumIcon: () => {
          return <BrightnessMediumIcon />;
     },
     getBrightnessHighIcon: () => {
          return <BrightnessHighIcon />;
     },
     getMarkEmailReadIcon: () => {
          return <MarkEmailReadIcon />;
     },
     getDownloadIcon: () => {
          return <DownloadIcon />;
     },
};

export default function CustomButton(props) {

     let icon = props.icon === "" ? null : buttonIcons[props.icon]();

     if (props.iconButton) {
          return (
               <Tooltip title={props.tooltip}>
                    <IconButton
                         variant={props.variant} 
                         color={props.color} 
                         onClick={props.onClick}
                    >
                         {icon}
                    </IconButton>
             </Tooltip>
          );     
     }

     return (
          <Stack direction="row" spacing={2}>
               <Button 
                    sx={{ whiteSpace: 'nowrap' }}
                    variant={props.variant} 
                    color={props.color} 
                    startIcon={icon} 
                    onClick={props.onClick}
                    disabled={props.disabled ? props.disabled : false}
               >
                    {props.children}
               </Button>
          </Stack>
     );

}
