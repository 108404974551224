import axios from "../config";

const Reviewer = {

     getReviewerSummary: (reviewerData, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/reviewer/reviewer-summary', reviewerData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(reject);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     getARSiteDetails: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/reviewer/get-reviewer-site-details', {
                         'audit_plan_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     getARChecklistCategory: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/reviewer/get-checklist-category', {
                         'audit_plan_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else { 
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     getARChecklist: (reviewerChecklistData, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/reviewer/get-reviewer-checklist-details', reviewerChecklistData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status == 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     getARChecklistDetails: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/reviewer/get-reviewer-checklist-sample-data', {
                         'audit_sample_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },
     
     saveARChecklistDetails: (checklistData, setTableDataChanged, setCardDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/reviewer/save-reviewer-checklist-sample-data', checklistData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setTableDataChanged(1);
                              setCardDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage('Error');
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     submitARChecklist: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/reviewer/submit-audit-reviewer-checklist', {
                         'audit_plan_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status == 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     submitReviewChecklist: (reviewData) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/reviewer/submit-reviewer-checklist', reviewData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

}

export default Reviewer;