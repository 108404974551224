import axios from "../config";

const InverterOem = {

     getAllInverterOem: (setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .get('/inverter_oem')
                    .then(({ data }) => {
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     addOem: (oemData, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/inverter_oem/add', oemData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     deleteOem: (oemData, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/inverter_oem/delete', oemData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },
};

export default InverterOem;