import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import AuthMethods from 'app/services';
import ErrorMessage from '@custom/Components/ErrorMessage';
import { useSnackbar } from 'notistack';
import PageLoader from '@custom/Components/PageLoader';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
     required: {
          color: '#E73145',
     }
});

export default function AddCategory(props) {

     const classes = useStyles();
     const { enqueueSnackbar } = useSnackbar();
     const categoryId = props.categoryId;
     
     const [categoryName, setCategoryName] = useState('');
     const [categoryNameError, setCategoryNameError] = useState('');
     const [shortName, setShortName] = useState('');
     const [shortNameError, setShortNameError] = useState('');
     const [message, setMessage] = useState('');
     const [loading, setLoading] = useState(false);

     const [criteriaLow, setCriteriaLow] = useState('');
     const [criteriaMid, setCriteriaMid] = useState('');
     const [criteriaHigh, setCriteriaHigh] = useState('');

     const [criteriaLowError, setCriteriaLowError] = useState('');
     const [criteriaMidError, setCriteriaMidError] = useState('');
     const [criteriaHighError, setCriteriaHighError] = useState('');

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const saveCategoryForm = () => {
          setLoading(true);
          let categoryData = {
               'name': categoryName,
               'short_name': shortName,
               'creteria': [`${criteriaLow}%`,`${criteriaMid}%`,`${criteriaHigh}%`]
          };

          if (props.action ==='add') {
               AuthMethods['category'].addCategory(categoryData, props.setCategoryTableDataChanged, setMessage, setLoading);
          } else {
               AuthMethods['category'].updateCategory(categoryId, categoryData, props.setCategoryTableDataChanged, setMessage, setLoading);
          }
     };


     const saveForm = (e) => {
          e.preventDefault();

          let isValid = true;

          if (!categoryName) {
               setCategoryNameError('This field is required');
               isValid = false;
          }

          if (!shortName) {
               setShortNameError('This field is required');
               isValid = false;
          }

          if (!criteriaLow) {
               setCriteriaLowError('This field is required');
               isValid = false;
          }
          if (!criteriaMid) {
               setCriteriaMidError('This field is required');
               isValid = false;
          }
          if (!criteriaHigh) {
               setCriteriaHighError('This field is required');
               isValid = false;
          }

          if (isValid) {
               saveCategoryForm();
          }

     };

     useEffect(() => {
          if (message !== '') {
               if (props.action === 'add') {
                    if (message === 'Success') {
                         props.setOpenDialog(false);
                         enqueueSnackbar('Category added successfully', {
                              variant: 'success'
                         });
                    } else {
                         enqueueSnackbar(message, {
                              variant: 'error'
                         });
                    }
                    setMessage('');
               } else {
                    if (message === 'Success') {
                         props.setOpenDialog(false);
                         enqueueSnackbar('Category updated successfully', {
                              variant: 'success'
                         });
                    } else {
                         enqueueSnackbar(message, {
                              variant: 'error'
                         });
                    }
                    setMessage('');
               }
          }

     }, [message]);

     useEffect(() => {
          if (props.action === 'edit') {
               setLoading(true);
               AuthMethods['category'].getSingleCategory(categoryId).then(data => {
                    // console.log(data);
                    setLoading(false);
                    if (Object.entries(data['data'][0]).length > 0) {
                         setCategoryName(data['data'][0]['name']);
                         setShortName(data['data'][0]['short_name']);
                         let allCriteria = JSON.parse(data['data'][0]['creteria']);

                         // to extract the number from the string
                         setCriteriaLow(parseInt(allCriteria[0].replace('%', ''), 10));
                         setCriteriaMid(parseInt(allCriteria[1].replace('%', ''), 10));
                         setCriteriaHigh(parseInt(allCriteria[2].replace('%', ''), 10));
                    }
               });
          }

     }, [categoryId]);

     return (

          <React.Fragment>
               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    {props.action === 'edit' ? 'Edit Category' : 'Add New Category' }
               </DialogTitle>

               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2} mb={2}>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Category Name <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        name="categoryName"
                                        type="text"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        size="small"
                                        value={categoryName}
                                        onChange={e => {
                                             setCategoryName(e.target.value);
                                             setCategoryNameError('');
                                        }}
                                   />
                                   <ErrorMessage>{categoryNameError}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Short Name <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        name="shortName"
                                        type="text"
                                        fullWidth
                                        size="small"
                                        value={shortName}
                                        onChange={e => {
                                             setShortName(e.target.value);
                                             setShortNameError('');
                                        }}
                                   />
                                   <ErrorMessage>{shortNameError}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        }}
                                   >
                                        Criteria :
                                   </InputLabel>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        {"X<=50"} <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        name="criteriaLow"
                                        type="number"
                                        fullWidth
                                        size="small"
                                        value={criteriaLow}
                                        onChange={e => {
                                             setCriteriaLow(e.target.value);
                                             setCriteriaLowError('');
                                        }}
                                   />
                                   <ErrorMessage>{criteriaLowError}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        {"50<X<100"} <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        name="criteriaMid"
                                        type="number"
                                        fullWidth
                                        size="small"
                                        value={criteriaMid}
                                        onChange={e => {
                                             setCriteriaMid(e.target.value);
                                             setCriteriaMidError('');
                                        }}
                                   />
                                   <ErrorMessage>{criteriaMidError}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        {"X>=100"} <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        name="criteriaHigh"
                                        type="number"
                                        fullWidth
                                        size="small"
                                        value={criteriaHigh}
                                        onChange={e => {
                                             setCriteriaHigh(e.target.value);
                                             setCriteriaHighError('');
                                        }}
                                   />
                                   <ErrorMessage>{criteriaHighError}</ErrorMessage>
                              </Grid>
                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton variant="contained" color="primary" icon="" onClick={saveForm}>
                                   {props.action === 'add' ? 'Add' : 'Update'}
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>
          </React.Fragment>
     );
}
