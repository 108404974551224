import axios from "../config";

const AuditPlan = {

     getAuditPlan: (auditPlanData, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/audit_plans', auditPlanData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     createUpdateAuditPlan: (auditData, setTableDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/create/audit_plan', auditData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setTableDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     auditPlanComments: (id, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .get('/audit_comments/' + id)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     sendApproval: (approvalData, setTableDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/approval', approvalData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setTableDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     getGanttData: (approvalData) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/dashboard/gantt_chart', approvalData)
                    .then(({ data }) => {
                         if (data.status === 'success') {
                              resolve(data.data);
                         }else if (data.status === 'error' && data.code === 404) {
                              resolve(['No Data']);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                         return [];
                    });
          });
     },

     sendAuditMail: (mailData) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/send-mail-plant-change', mailData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

};

export default AuditPlan;
