import React from "react";
import { Card, Grid } from "@mui/material";
import ChartLoader from "@custom/Charts/ChartLoader";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import _ from "lodash";
import ColumnHighcharts from "@custom/Charts/ColumnHighcharts";

drilldown(Highcharts);

export default function GraphsTab(props) {

  return (
    <Grid container spacing={2}>

      <Grid item xs={12} sm={6}>
        <Card
          sx={{
            p: 1,
            boxShadow: "0px 0px 10px 1px #DBD8F1",
            height: "100%",
            minHeight: "420px",
          }}
        >
          {props.loadingG ? (
            <ChartLoader title="Loading..." />
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              options={props.stateInitial}
            />
          )}
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card
          sx={{
            p: 1,
            boxShadow: "0px 0px 10px 1px #DBD8F1",
            height: "100%",
            minHeight: "420px",
          }}
        >
          {props.loadingG ? (
            <ChartLoader title="Loading..." />
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              options={props.stateCurrent}
            />
          )}
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card
          sx={{
            p: 1,
            boxShadow: "0px 0px 10px 1px #DBD8F1",
            height: "100%",
            minHeight: "420px",
          }}
        >
          {props.loadingCompG ? (
            <ChartLoader title="Loading..." />
          ) : (
            <ColumnHighcharts chartData={props.componentInitial} />
          )}
        </Card>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Card
          sx={{
            p: 1,
            boxShadow: "0px 0px 10px 1px #DBD8F1",
            height: "100%",
            minHeight: "420px",
          }}
        >
          {props.loadingCompG ? (
            <ChartLoader title="Loading..." />
          ) : (
            <ColumnHighcharts chartData={props.componentCurrent} />
          )}
        </Card>
      </Grid>

    </Grid>
  );
}
