import axios from "../config";

const Common = {
     
     getFinancialYear: () => {
          return new Promise((resolve, reject) => {
               axios
                    .get('/financial/year')
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     getHealthScoreChart: (chartData, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/get-health-score-graph-data', chartData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     getSeverityChart: (chartData, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/get-severity-graph-data', chartData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     getSiteHealthScore: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/get-site-health-score-graph-data', {
                         'audit_plan_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     getPreviousYearData: (fy, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/site/previous_year_data', {
                         'fy_year': fy
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

};

export default Common;