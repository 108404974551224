import * as React from 'react';
import { Box, Tooltip } from '@mui/material';

export default function CustomTooltip(props) {

     return (
          <Box
               sx={{
                    display: 'flex',
               }}
          >
               <Tooltip 
                    title={props.title}
                    arrow
                    componentsProps={{
                         tooltip: {
                              sx: {
                                   background: theme => theme.palette.background.primary,
                                   color: theme => theme.palette.text.primary,
                                   fontWeight: 'bold',
                                   '& .MuiTooltip-arrow': {
                                             color: theme => theme.palette.background.primary,
                                        },
                                   },
                         },
                    }}
               >
                    <Box p={.6}>
                         {props.children}
                    </Box>
               </Tooltip>
          </Box>
     );
}