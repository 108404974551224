import React, { useState } from 'react';
import Avatar from "@mui/material/Avatar";
import {ListItemIcon, ListItemText, ThemeProvider, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CustomDialog from '@custom/Components/CustomDialog';
import ChangePassword from './ChangePassword';
import { isNull } from 'lodash';

const AuthUserDropdown = () => {

    const navigate = useNavigate();
    const {theme} = useJumboTheme();
    const {authUser, setAuthToken} = useJumboAuth();

    const [openDialog, setOpenDialog] = useState(false);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const onLogout = () => {
        setAuthToken(null);
        navigate("/login");
    };

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        // src={authUser.profile_pic}
                        sizes={"small"}
                        sx={{
                            boxShadow: 25, 
                            cursor: 'pointer',
                            background: theme => theme.palette.grey.dark, 
                            color: theme => theme.palette.secondary.main,
                        }}
                    />
                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar 
                        // src={authUser.profile_pic} 
                        alt={!isNull(authUser) ? authUser['data']['name'] : ''} 
                        sx={{
                            width: 60, 
                            height: 60, 
                            mb: 2
                        }}
                    />
                    <Typography variant={"h5"}>
                        { !isNull(authUser) ? authUser['data']['name'] : '' }
                    </Typography>
                    <Typography variant={"body1"} color="text.secondary">
                        { !isNull(authUser) ? authUser['data']['email'] : '' }
                    </Typography>
                </Div>
                <Divider/>
                <nav>
                    <List disablePadding sx={{pb: 1}}>
                        {/* <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <PersonOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Profile" sx={{my: 0}}/>
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <EditOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Edit Profile" sx={{my: 0}}/>
                        </ListItemButton> */}
                        <ListItemButton onClick={handleDialogOpen}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <PublishedWithChangesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Change User Password" sx={{my: 0}} />
                        </ListItemButton> 
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{my: 0}} />
                        </ListItemButton>
                    </List>
                </nav>
                <CustomDialog openDialog={openDialog}>
                    <ChangePassword setOpenDialog={setOpenDialog} />
                </CustomDialog>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
