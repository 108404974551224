import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import Dropdown from '@custom/Components/Dropdown';
import { makeStyles } from '@mui/styles';
import ErrorMessage from '@custom/Components/ErrorMessage';
import AuthMethods from 'app/services';
import { useSnackbar } from 'notistack';
import { isNull } from 'lodash';
import PageLoader from '@custom/Components/PageLoader';

const useStyles = makeStyles({
     required: {
          color: '#E73145',
     },
});

const stateNameMapping = [{'State': 'Andhra Pradesh', 'ShortName': 'AP'},
{'State': 'Arunachal Pradesh', 'ShortName': 'AR'},
{'State': 'Assam', 'ShortName': 'AS'},
{'State': 'Bihar', 'ShortName': 'BR'},
{'State': 'Chhattisgarh', 'ShortName': 'CT'},
{'State': 'Goa', 'ShortName': 'GA'},
{'State': 'Gujarat', 'ShortName': 'GJ'},
{'State': 'Haryana', 'ShortName': 'HR'},
{'State': 'Himachal Pradesh', 'ShortName': 'HP'},
{'State': 'Jharkhand', 'ShortName': 'JH'},
{'State': 'Karnataka', 'ShortName': 'KA'},
{'State': 'Kerala', 'ShortName': 'KL'},
{'State': 'Madhya Pradesh', 'ShortName': 'MP'},
{'State': 'Maharashtra', 'ShortName': 'MH'},
{'State': 'Manipur', 'ShortName': 'MN'},
{'State': 'Meghalaya', 'ShortName': 'ML'},
{'State': 'Mizoram', 'ShortName': 'MZ'},
{'State': 'Nagaland', 'ShortName': 'NL'},
{'State': 'Odisha', 'ShortName': 'OD'},
{'State': 'Punjab', 'ShortName': 'PB'},
{'State': 'Rajasthan', 'ShortName': 'RJ'},
{'State': 'Sikkim', 'ShortName': 'SK'},
{'State': 'Tamil Nadu', 'ShortName': 'TN'},
{'State': 'Telangana', 'ShortName': 'TS'},
{'State': 'Tripura', 'ShortName': 'TR'},
{'State': 'Uttarakhand', 'ShortName': 'UL'},
{'State': 'Uttar Pradesh', 'ShortName': 'UP'},
{'State': 'West Bengal', 'ShortName': 'WB'},
{'State': 'Andaman and Nicobar Islands', 'ShortName': 'AN'},
{'State': 'Chandigarh', 'ShortName': 'CH'},
{'State': 'Dadra and Nagar Haveli', 'ShortName': 'DN'},
{'State': 'Daman and Diu', 'ShortName': 'DD'},
{'State': 'Delhi', 'ShortName': 'DL'},
{'State': 'Jammu and Kashmir', 'ShortName': 'JK'},
{'State': 'Ladakh', 'ShortName': 'LA'},
{'State': 'Lakshadweep', 'ShortName': 'LD'},
{'State': 'Pondicherry', 'ShortName': 'PY'}];

export default function AddEditState(props) {

     const classes = useStyles();
     const { enqueueSnackbar } = useSnackbar();
     const stateId = props.stateId;
     const roleStateLead  = {
          'short_name': 'reviewer_1'
     };
     const roleOperationLead = {
          'short_name': 'reviewer_2'
     };

     const [formData, setFormData] = useState({
          stateName: '',
          shortName: '',
          reliabilityLead: '',
          operationLead: ''
     });

     const [errorFormData, setErrorFormData] = useState({
          stateName: '',
          shortName: '',
          reliabilityLead: '',
          operationLead: ''
     });
     const [reliabilityLeadDropdown, setReliabilityLeadDropdown] = useState([]);
     const [operationLeadDropdown, setOperationLeadDropdown] = useState([]);
     const [message, setMessage] = useState('');
     const [loading, setLoading] = useState(false);
     
     const handleStateChange = (event, isStateName = false) => {
       const { name, value } = event.target;
       setFormData((prevFormData) => ({
         ...prevFormData,
         [name]: value,
         ...(isStateName && { shortName: stateNameMapping.find((item) => item.State === value)?.ShortName || '' }),
       }));
     };

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const handleChange = (e, required) => {
          const { name, value } = e.target;
          
          setFormData(prev => {
               return { ...prev, [name]: value };
          });

          if (required) {
               setErrorFormData(prev => {
                    return { ...prev, [name]: '' };
               });
          }
     };

     const saveStateForm = () => {
          let stateData = {
               name: formData['stateName'],
               short_name: formData['shortName'],
               state_reliability_lead: formData['reliabilityLead'],
               state_operation_lead: formData['operationLead']
          }

          setLoading(true);
          if (props.action === 'add') {
               AuthMethods['state'].addState(stateData, props.setTableDataChanged, setMessage, setLoading);
          } else {
               AuthMethods['state'].updateState(stateId, stateData, props.setTableDataChanged, setMessage, setLoading);
          }
     };

     const checkFormData = name => {

          if (!formData[name]) {
               setErrorFormData(prev =>{
                    return { ...prev, [name]: 'This field is required' };
               });
               return false;
          }

          return true;

     };

     const saveForm = (e) => {
          e.preventDefault();

          let isValid = true;

          isValid = checkFormData('stateName') && isValid;
          isValid = checkFormData('shortName') && isValid;
          isValid = checkFormData('reliabilityLead') && isValid;
          isValid = checkFormData('operationLead') && isValid;

          if (isValid) {
               saveStateForm();
          }

     };

     useEffect(() => {
          if (props.action === 'edit') {
               setLoading(true);
               AuthMethods['state'].getStateById(stateId).then(data => {
                    // console.log(data);
                    setLoading(false);
                    if (Object.entries(data['data'].length > 0)) {
                         setFormData({
                              stateName: data['data']['name'],
                              shortName: data['data']['short_name'],
                              reliabilityLead: isNull(data['data']['state_reliability_lead']) ? '' : data['data']['state_reliability_lead']['id'],
                              operationLead: isNull(data['data']['state_operation_lead']) ? '' : data['data']['state_operation_lead']['id'],
                         });
                    }
               });
          }

     }, [stateId]);

     useEffect(() => {
          if (message !== '') {
               if (props.action === 'add') {
                    if (message === 'Success') {
                         props.setOpenDialog(false);
                         enqueueSnackbar('State added successfully', {
                              variant: 'success'
                         });
                    } else {
                         enqueueSnackbar(message, {
                              variant: 'error'
                         });
                    }
                    setMessage('');
               } else {
                    if (message === 'Success') {
                         props.setOpenDialog(false);
                         enqueueSnackbar('State updated successfully', {
                              variant: 'success'
                         });
                    } else {
                         enqueueSnackbar(message, {
                              variant: 'error'
                         });
                    }
                    setMessage('');
               }
          }

     }, [message]);

     useEffect(() => {
          setLoading(true);
          AuthMethods['roles'].getUsersByRole(roleStateLead, setLoading).then(data => {
               if (data['data'].length > 0) {
                    if (data['data'][0]['user'].length > 0) {
                         let tempData = [];
                         data['data'][0]['user'].forEach(item => {
                              let obj = {
                                   id: item.id,
                                   name: item.name
                              };
                              tempData.push(obj);
                         });
                         setReliabilityLeadDropdown(tempData);
                    }
               }  
          });

          AuthMethods['roles'].getUsersByRole(roleOperationLead, setLoading).then(data => {
               if (data['data'].length > 0) {
                    if (data['data'][0]['user'].length > 0) {
                         
                         let tempData = [];
                         
                         data['data'][0]['user'].forEach(item => {
                              let obj = {
                                   id: item.id,
                                   name: item.name
                              };
                              tempData.push(obj);
                         });
                         setOperationLeadDropdown(tempData);
                    }
               }  
          });

     }, []);

     return (

          <React.Fragment>
               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    {props.action === 'add' ? 'Add New State' : 'Edit State'}
               </DialogTitle>
          
               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2} mb={2}>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '13px', fontWeight: 'bold', pb: '3px' }}
                                   >
                                        State Name <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Select
                                   sx={{width: '100%'}}
                                   name="stateName"
                                   value={formData['stateName']}
                                   onChange={(e) => handleStateChange(e, true)}
                                   displayEmpty
                                   inputProps={{ 'aria-label': 'Without label' }}
                                   size="small"
                                   >
                                   <MenuItem value="" disabled>
                                   Select State
                                   </MenuItem>
                                   {stateNameMapping.map((option) => (
                                   <MenuItem key={option.State} value={option.State}>
                                        {option.State}
                                   </MenuItem>
                                   ))}
                                   </Select>
                              <ErrorMessage>{errorFormData['stateName']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                              <InputLabel
                                   sx={{ color: (theme) => theme.palette.text.secondary, fontSize: '13px', fontWeight: 'bold', pb: '3px' }}
                              >
                                   Short Name <span className={classes.required}>*</span>
                              </InputLabel>
                              <TextField
                                   name="shortName"
                                   type="text"
                                   fullWidth
                                   size="small"
                                   value={formData['shortName']}
                                   onChange={(e) => handleStateChange(e)}
                                   disabled // Disable the Short Name input
                              />
                              <ErrorMessage>{errorFormData['shortName']}</ErrorMessage>
                              </Grid>
                              {/* <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        State Name <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        name="stateName"
                                        type="text"
                                        fullWidth
                                        size="small"
                                        value={formData['stateName']}
                                        onChange={e => handleChange(e, true)}
                                   />
                                   <ErrorMessage>{errorFormData['stateName']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Short Name <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        name="shortName"
                                        type="text"
                                        fullWidth
                                        size="small"
                                        value={formData['shortName']}
                                        onChange={e => handleChange(e, true)}
                                   />
                                   <ErrorMessage>{errorFormData['shortName']}</ErrorMessage>
                              </Grid> */}
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        State Reliability Lead (Reviewer 1) <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select State Reliability Lead"
                                        name="reliabilityLead"
                                        value={formData['reliabilityLead']}
                                        dropdown={reliabilityLeadDropdown}
                                        onChange={e => handleChange(e, true)}
                                        disabled={false}
                                   />
                                   <ErrorMessage>{errorFormData['reliabilityLead']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        State Operation Lead (Reviewer 2) <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select State Operation Lead"
                                        name="operationLead"
                                        value={formData['operationLead']}
                                        dropdown={operationLeadDropdown}
                                        onChange={(e) => handleChange(e, true)}
                                        disabled={false}
                                   />
                                   <ErrorMessage>{errorFormData['operationLead']}</ErrorMessage>
                              </Grid>
                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton variant="contained" color="primary" onClick={saveForm} icon="">
                                   {props.action === 'add' ? 'Add' : 'Update'}
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>
          </React.Fragment>
     );
}
