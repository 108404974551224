import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function BasicDatePicker(props) {
     return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DatePicker
                    name={props.name}
                    format="YYYY-MM-DD"
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    value={props.value}
                    onChange={props.onChange}
                    disabled={props.disabled ? props.disabled : false}
                    slotProps={{
                         textField: {
                              size: 'small',
                              error: false,
                         }
                    }}
                    sx={{
                         '& .MuiButtonBase-root': {
                              color: theme => theme.palette.primary.main
                         },
                         width: props.width ? props.width : '100%',
                    }}
               />
          </LocalizationProvider>
     );
}
