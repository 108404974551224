import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import CustomDialog from '@custom/Components/CustomDialog';
import Approval from './Approval';
import AuthMethods from 'app/services';
import { useSnackbar } from 'notistack';
import { useJumboTheme } from '@jumbo/hooks';

export default function AuditPlanHeader(props) {
     // console.log(props);

     const {theme} = useJumboTheme();
     const {enqueueSnackbar} = useSnackbar();

     const [openDialog, setOpenDialog] = useState(false);
     const [message, setMessage] = useState('');

     const handleApprovalDialogOpen = () => {
          setOpenDialog(true);
     };

     const savePlan = () => {
          if (props.checkedRows.current.length > 0) {
               let arr = [];
               props.checkedRows.current.forEach(item => {

                    let startDate = item['startDate']['$d'].getFullYear() + '-' + ((item['startDate']['$d'].getMonth() + 1) > 9 ? (item['startDate']['$d'].getMonth() + 1) : '0' + (item['startDate']['$d'].getMonth() + 1)) + '-' + (item['startDate']['$d'].getDate() > 9 ? item['startDate']['$d'].getDate() : '0' + item['startDate']['$d'].getDate());

                    
                    let endDate = item['endDate']['$d'].getFullYear() + '-' + ((item['endDate']['$d'].getMonth() + 1) > 9 ? (item['endDate']['$d'].getMonth() + 1) : '0' + (item['endDate']['$d'].getMonth() + 1)) + '-' + (item['endDate']['$d'].getDate() > 9 ? item['endDate']['$d'].getDate() : '0' + item['endDate']['$d'].getDate());

                         
                    let obj = {
                         state_id: item.stateId,
                         site_id: item.siteId,
                         start_date: startDate,
                         end_date: endDate,
                         auditor_1: item.auditor1,
                         auditor_2: item.auditor2,
                    };

                    arr.push(obj);
               });

               let auditData = {
                    fy_id: props.financialYear,
                    data: arr
               };

               // console.log(auditData);
               props.setLoading(true);
               AuthMethods['auditPlan'].createUpdateAuditPlan(auditData, props.setTableDataChanged, setMessage, props.setLoading);
          }
     };

     const handlePlan = (e) => {
          e.preventDefault();
          // console.log(props.checkedRows.current);

          if (props.checkedRows.current.length > 0) {
               let isValidCommon = true;
               let isValidDate = true;

               props.checkedRows.current.forEach(item => {
                    if (!item['startDate'] || !item['endDate'] || !item['auditor1']) {
                         isValidCommon = false;
                    }

                    if (item['endDate']['$d'] <= item['startDate']['$d']) {
                         isValidDate = false;
                    }
               });

               if (!isValidCommon) {
                    props.setTableError('Start Date, End Date and Auditor 1 fields are required');
               }

               if (!isValidDate) {
                    props.setTableError('End Date should be greater than Start Date');
               }

               if (isValidCommon && isValidDate) {
                    savePlan();
               }
          }
     };

     useEffect(() => {
          if (message !== '') {
               if (message === 'Success') {
                    enqueueSnackbar('Audit Plan added successfully', {
                         variant: 'success'
                    });
               } else {
                    enqueueSnackbar(message, {
                         variant: 'error'
                    });
               }
               setMessage('');
          }

     }, [message]);

     return (

          <React.Fragment> 
               <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                    
                    <Box 
                         sx={{ 
                              display: 'flex', 
                              justifyContent: 'flex-end',

                              [theme.breakpoints.down('sm')]: {
                                   flexWrap: 'wrap',
                                   justifyContent: 'flex-start',
                              },
                         }}
                    >
                         <Box p={1}>
                              <CustomButton 
                                   variant="outlined" 
                                   color="primary" 
                                   icon=""
                                   onClick={handlePlan}
                                   disabled={props.adminRole ? false : true}
                                   >
                                        Save Plan Details
                              </CustomButton>
                         </Box>

                         {/* admin (Send For Approval enable)
                         admin status 0 || approver 1 status 2 || approver 2 status 2 */}

                         {props.adminRole ? (
                              <Box p={1}>
                                   <CustomButton 
                                        variant="contained" 
                                        color="primary" 
                                        icon=""
                                        onClick={handleApprovalDialogOpen}
                                        disabled={(props.adminStatus === 0 || props.approver1Status === 2 || props.approver2Status === 2) ? false : true}
                                   >
                                        Send For Approval
                                   </CustomButton>
                              </Box>

                         // approver 1 (Send For Approval enable)
                         // admin status 1 && approver 1 status 0

                         ) : (props.approver1Role) ? (
                              <Box p={1}>
                                   <CustomButton 
                                        variant="contained" 
                                        color="primary" 
                                        icon=""
                                        onClick={handleApprovalDialogOpen}
                                        disabled={(props.adminStatus === 1 && props.approver1Status === 0) ? false : true}
                                   >
                                        Approve / Reject
                                   </CustomButton>
                              </Box>

                              // approver 2 (Send For Approval enable)
                              // aprrover 1 status 1 && approver 2 status 0

                         ) : (props.approver2Role) ? (
                              <Box p={1}>
                                   <CustomButton 
                                        variant="contained" 
                                        color="primary" 
                                        icon=""
                                        onClick={handleApprovalDialogOpen}
                                        disabled={(props.approver1Status === 1 && props.approver2Status === 0) ? false : true}
                                   >
                                        Approve / Reject
                                   </CustomButton>
                              </Box>
                         ) : '' }

                    </Box>
               </Box>

               <CustomDialog openDialog={openDialog}>
                    <Approval 
                         setOpenDialog={setOpenDialog} 
                         financialYear={props.financialYear} 
                         auditPlanID={props.auditPlanID}
                         setTableDataChanged={props.setTableDataChanged}
                         setLoading={props.setLoading} 
                         approver1Role={props.approver1Role}
                         approver2Role={props.approver2Role}
                    />
               </CustomDialog>
          
          </React.Fragment>

     );
}
