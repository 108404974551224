import React, { useEffect, useState } from 'react';
import ChatBox from '@custom/Components/ChatBox';
import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import AuthMethods from 'app/services';
import _ from 'lodash';
import PageLoader from '@custom/Components/PageLoader';

export default function AuditPlanChatBox(props) {

     const [loading, setLoading] = useState(false);
     const [commentsData, setCommentsData] = useState([]);

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     useEffect(() => {
          if (props.auditPlanID !== '') {
               setLoading(true);
               AuthMethods['auditPlan'].auditPlanComments(props.auditPlanID, setLoading).then(data => {
                    // console.log(data);
                    if (data.data.length > 0) {
                         let arr = [];
                         data.data.forEach(item => {
                              let obj = {
                                   comments: item['comments'],
                                   name: _.size(item['comment_added_by']) > 0 ? item['comment_added_by']['name'] : '',
                                   time: item['comment_added_on'],
                              };
                              arr.push(obj);
                         });
                         // console.log(arr);
                         setCommentsData(arr);
                    }
               });
          }
     }, [props.auditPlanID]);

     return (

          <React.Fragment>
               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Audit Plan Comments
               </DialogTitle>
          
               <DialogContent dividers 
                    sx={{ p: 0 }}
               >
                    <ChatBox commentsData={commentsData} />
               </DialogContent>

               <DialogActions>
                    <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'center' }}>
                         <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                              Cancel
                         </CustomButton>
                    </Box>
               </DialogActions>
                         
          </React.Fragment>
     );
}