import React, { useEffect, useState } from "react";
import { Box, Card, Grid, InputLabel } from "@mui/material";
import ColumnHighcharts from "@custom/Charts/ColumnHighcharts";
import AuthMethods from "app/services";
import ChartLoader from "@custom/Charts/ChartLoader";
import _ from "lodash";
import Dropdown from "@custom/Components/Dropdown";
import MultiSelect from "@custom/Components/MultiSelect";
import { makeStyles } from "@mui/styles";
import CustomButton from "@custom/Components/CustomButton";
import ErrorMessage from "@custom/Components/ErrorMessage";
import { siteIds } from "@custom/Functions/helperFunction";
import CompOverall from "./CompOverall";

const useStyles = makeStyles({
  required: {
    color: '#E73145',
  },
});

export default function ComponentGraphTab(props) {

  const classes = useStyles();

  const userId = localStorage.getItem('userId');

  const [dropLoading, setDropLoading] = useState(true);
  const [chartLoading, setChartLoading] = useState(false);
  const [state, setState] = useState('');
  const [stateDropdown, setStateDropdown] = useState([]);
  const [stateError, setStateError] = useState('');
  const [selectedSite, setSelectedSite] = useState([]);
  const [siteDropdown, setSiteDropdown] = useState([]);
  const [siteError, setSiteError] = useState('');
  const [seriesStateSite, setSeriesStateSite] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [stateName, setStateName] = useState('');
  const [siteId, setSiteId] = useState([]);

  const stateSite = {
    chart: {
      type: "column",
    },

    title: {
      text: stateName,
    },


    xAxis: {
      type: "category",
    },

    yAxis: [
      {
        // Primary yAxis
        title: {
          text: 'Count',
        },
      },
      {
        // Secondary yAxis
        title: {
          text: 'HS (%)',
        },
        min: 0,
        max: 100,
        opposite: true,
      },
    ],

    tooltip: {
      pointFormatter: function() {
        let point = this;
        return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + (point.series.name === 'Health Score' && point.y === 0 ? '-' : point.y) + '</b><br/>';
      },
      shared: true,
      useHTML: true,
    },

    legend: {
      enabled: true,
    },

    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },

    series: seriesStateSite,

  };

  const handleView = () => {

    let isValid = true;

    if (!state) {
      setStateError('This field is required');
      isValid = false;
    }

    if (selectedSite.length === 0) {
      setSiteError('This field is required');
      isValid = false;
    }

    if (isValid) {
      setShowTable(true);

      let componentData = {
        fy_id: props.financialYear,
        user_id: userId,
        state_id: state,
        site_id: siteId,
      };
      
      setChartLoading(true);
      AuthMethods["chartAndTable"].componentWiseData(componentData, setChartLoading).then(data => {
        // console.log(data);
        if (_.size(data['data']) > 0) {
          if (_.size(data['data']['state_wise_component']) > 0) {

            let points = {
              'High': '#E95363',
              'Mid': '#F4A738',
              'Low': '#7B9BEA'
            };
    
            let compArr = [];
            for (let point in points) {
              let compObj = {
                name: point,
                color: points[point],
                data: [],
              };
    
              for (let state in data['data']['state_wise_component']) {
                setStateName(state);
                for (let comp in data['data']['state_wise_component'][state]) {
                  let obj = {
                    name: comp,
                    y: data['data']['state_wise_component'][state][comp][point],
                  };
      
                  compObj['data'].push(obj);
                }
              }
              compArr.push(compObj);
            }
    
            let hsObj = {
              type: 'spline',
              lineWidth: 0,
              marker: {},
              states: {
                hover: {
                  lineWidthPlus: 0
                }
              },
              name: 'Health Score',
              color: '#065DF3',
              yAxis: 1,
              data: [],
            };
            
            for (let state in data['data']['state_wise_component']) {
              for (let comp in data['data']['state_wise_component'][state]) {
                let newObj = {
                  name: comp,
                  color: data['data']['state_wise_component'][state][comp]['hs'] === 0 ? 'transparent' : '#065DF3',
                  y: data['data']['state_wise_component'][state][comp]['hs'],
                }

                let radius = {
                  radius: 0
                };

                if (data['data']['state_wise_component'][state][comp]['hs'] === 0) {
                  hsObj['marker'] = radius;
                }

                hsObj['data'].push(newObj);
              }
            }

            compArr.push(hsObj);
            // console.log(compArr);
            setSeriesStateSite(compArr);
          }
        }
      });
    }
  };
  
  useEffect(() => {
    if (selectedSite.length > 0) {
        let sites = siteIds(siteDropdown, selectedSite);
        // console.log(siteIds);
        setSiteId(sites);
        setSiteError('');
    }
  }, [selectedSite]);

  useEffect(() => {
    if (state) {
      let siteData = {
        'state_id': state,
        'user_id': userId,
      };

      AuthMethods['site'].getSitesByStateId(siteData).then(data => {
        // console.log(data);
        if (data['data'].length > 0) {
          let obj = data['data'].map(item => {
            return {
              id: item.id,
              value: item.name,
            };
          });
          setSiteDropdown(obj);
        }
      });

    }

  }, [state]);
  
  useEffect(() => {
    
    let stateData = {
      user_id: userId,
    };

    AuthMethods['state'].getAllStates(setDropLoading, stateData).then(data => {
      // console.log(data);
      if (data['data'].length > 0) {
        let obj = data['data'].map(item => {
          return {
            id: item.id,
            name: item.name
          };
        });
        
        setStateDropdown(obj);
      }
    });

  }, []);

  return (

    <React.Fragment>

      <CompOverall 
        loadingC={props.loadingC}
        compOverall={props.compOverall}
      />

      <Box display="flex" flexWrap="wrap" alignItems="flex-end" mb={1}>
        <Box sx={{ p: 1, width: 320 }}>
          <InputLabel
            sx={{ color: theme => theme.palette.text.secondary,
            fontSize: '13px',
            fontWeight: 'bold',
            pb: '3px'
            }}
          >
            State <span className={classes.required}>*</span>
          </InputLabel>
          <Dropdown
            labelkey="id"
            labelname="name"
            placeholder="Select State"
            name="state"
            value={state}
            dropdown={stateDropdown}
            onChange={e => {
              setState(e.target.value);
              setSelectedSite([]);
              setSiteDropdown([]);
              setStateError('');
            }}
            disabled={false}
          />
            <ErrorMessage>{stateError}</ErrorMessage>
        </Box>

        <Box sx={{ p: 1, width: 320 }}>
          <InputLabel
            sx={{ color: theme => theme.palette.text.secondary,
            fontSize: '13px',
            fontWeight: 'bold',
            pb: '3px'
            }}
          >
            Site <span className={classes.required}>*</span>
          </InputLabel>
          <MultiSelect
            placeholder="Select Site"
            selected={selectedSite}
            setSelected={setSelectedSite}
            dropdown={siteDropdown}
          />
            <ErrorMessage>{siteError}</ErrorMessage>
        </Box>

        <Box sx={{ p: 1, pb: 2.4 }}>
              <CustomButton
                  variant="contained"
                  color="primary"
                  icon=""
                  onClick={handleView}
              >
                  View
              </CustomButton>
        </Box>
      </Box>

      {showTable && (
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} sm={12}>
            <Card
              sx={{
                p: 1,
                boxShadow: "0px 0px 10px 1px #DBD8F1",
                height: "100%",
                minHeight: "420px",
              }}
              >
              {chartLoading ? (
                <ChartLoader title="Loading..." />
                ) : (
                  <ColumnHighcharts chartData={stateSite} />
                  )}
            </Card>
          </Grid>
        </Grid>
      )}


    </React.Fragment>

  );
}
