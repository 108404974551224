import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({

     indeterminateColor: {
       color: "#f50057"
     },

     selectAllText: {
       fontWeight: 'bold'
     },

     selectedAll: {
       backgroundColor: "rgba(0, 0, 0, 0.08)",
       "&:hover": {
         backgroundColor: "rgba(0, 0, 0, 0.08)"
       }
     },

     menuItems: {
          padding: '0px',
     },

});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
     maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
     width: 100,
    },
  },
  getcontentanchorel: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

export default function MultiSelect(props) {

     const classes = useStyles();

     let selected = props.selected;
     let setSelected = props.setSelected;
     const [data, setData] = useState(props.dropdown);

     const isAllSelected = data.length > 0 && selected.length === data.length;

     const handleChange = (event) => {
          const { value } = event.target;
          if (value[value.length - 1] === "all") {
               setSelected(
                    selected.length === data.length
                         ? []
                         : data.map(item => {
                              return item.value;
                         }),
               );
               return;
          }
          // console.log(value);

          if (props.roles) {
               if (value.includes('Approver-1')) {
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value === 'Approver-2') {
                                   item.disabled = true;
                              }
                         });
                         return prev;
                    });
                    // console.log(data);
               } else if (value.includes('Approver-2')) {
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value === 'Approver-1') {
                                   item.disabled = true;
                              }
                         });
                         return prev;
                    });
               } else if (!value.includes('Approver-1')) {
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value !== 'Reviewer-1' && item.value !== 'Reviewer-2') {
                                   item.disabled = false;
                              }
                         });
                         return prev;
                    });
               } else if (!value.includes('Approver-2')) {
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value !== 'Reviewer-1' && item.value !== 'Reviewer-2') {
                                   item.disabled = false;
                              }
                         });
                         return prev;
                    });
               } else {
                    setSelected(value);
               }

               if (value.includes('Reviewer-1')) {
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value === 'Reviewer-2') {
                                   item.disabled = true;
                              }
                         });
                         return prev;
                    });
                    // console.log(data);
               } else if (value.includes('Reviewer-2')) {
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value === 'Reviewer-1') {
                                   item.disabled = true;
                              }
                         });
                         return prev;
                    });
               } else if (!value.includes('Reviewer-1')) {
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value !== 'Approver-1' && item.value !== 'Approver-2') {
                                   item.disabled = false;
                              }
                         });
                         return prev;
                    });
               } else if (!value.includes('Reviewer-2')) {
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value !== 'Approver-1' && item.value !== 'Approver-2') {
                                   item.disabled = false;
                              }
                         });
                         return prev;
                    });
               } else {
                    setSelected(value);
               }
          }

          setSelected(value);

     };

     useEffect(() => {

          setData(props.dropdown);

          if (props.roles) {

               if (selected.includes('Approver-1')) {
                    // console.log(props.dropdown);
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value === 'Approver-2') {
                                   item.disabled = true;
                              }
                         });
                         return prev;
                    });
               }

               if (selected.includes('Approver-2')) {
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value === 'Approver-1') {
                                   item.disabled = true;
                              }
                         });
                         return prev;
                    });
               }

               if (selected.includes('Reviewer-1')) {
                    // console.log(props.dropdown);
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value === 'Reviewer-2') {
                                   item.disabled = true;
                              }
                         });
                         return prev;
                    });
               }

               if (selected.includes('Reviewer-2')) {
                    setData(prev => {
                         prev.forEach(item => {
                              if (item.value === 'Reviewer-1') {
                                   item.disabled = true;
                              }
                         });
                         return prev;
                    });
               }

          }

     }, [props.dropdown, props.roles, selected]);

     return (

          <FormControl sx={{ width: '100%' }}>
               <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selected}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    displayEmpty
                    renderValue={(selected) => {
                         if (selected.length === 0) {
                              return <Typography>{props.placeholder}</Typography>;
                         }
                         if (isAllSelected) return 'All';
                         else if (selected.length > 3) return selected.length + ' Selected';
                         else return selected.join(', ');
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                    size="small"
               >
                    <MenuItem disabled value="">
                         {props.placeholder}
                    </MenuItem>
                    {!props.roles && (
                         <MenuItem
                              className={classes.menuItems}
                              value="all"
                              classes={{
                              root: isAllSelected ? classes.selectedAll : ""
                              }}
                         >
                              <ListItemIcon>
                                   <Checkbox
                                        classes={{ indeterminate: classes.indeterminateColor }}
                                        checked={isAllSelected}
                                        indeterminate={
                                             selected.length > 0 && selected.length < data.length
                                        }
                                   />
                              </ListItemIcon>
                              <ListItemText
                                   classes={{ primary: classes.selectAllText }}
                                   primary="Select All"
                              />
                         </MenuItem>
                    )}
                    {data.map((item, index) => (
                         // console.log(item),
                         <MenuItem key={index} value={item.value} className={classes.menuItems} disabled={item.disabled}>
                              <ListItemIcon>
                                   <Checkbox checked={selected.indexOf(item.value) > -1} />
                              </ListItemIcon>
                              <ListItemText primary={item.value} />
                         </MenuItem>
                    ))}
               </Select>
          </FormControl>
     );
}
