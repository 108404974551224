import axios from "../config";

const Checklist = {

     getChecklistByCategoryId: (id, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .get('/checklist/category/' + id)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     addChecklist: (checklistData, setChecklistTableDataChanged, setMessage, setLoading) =>{
          return new Promise((resolve, reject) => {
               axios
                    .post('/checklist/create', checklistData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setChecklistTableDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     getSingleChecklist: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .get('/checklist/' + id)
                    .then(({ data }) => {
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     updateChecklist: (id, checklistData, setChecklistTableDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .put('/checklist/' + id, checklistData)
                    .then(({ data }) => {
                         console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setChecklistTableDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     deleteChecklist: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .delete('/checklist/' + id)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     getAuditorChecklistCategory: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/get-checklist-category', {
                         'audit_plan_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

};

export default Checklist;