import React from "react";
import EventNoteIcon from '@mui/icons-material/EventNote';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ViewListIcon from '@mui/icons-material/ViewList';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FoundationIcon from '@mui/icons-material/Foundation';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SummarizeIcon from '@mui/icons-material/Summarize';
import GradingIcon from '@mui/icons-material/Grading';
import DatasetIcon from '@mui/icons-material/Dataset';

const menus = [
    {
        label: 'Dashboard',
        uri: "/dashboard",
        type: "nav-item",
        icon: <DashboardIcon sx={{ fontSize: 20 }} />
    },

    {
        label: 'Admin',
        type: "collapsible",
        icon: <EventNoteIcon sx={{ fontSize: 20 }}/>,
        children: [
            {
                uri: "/admin/users",
                label: 'Users',
                type: "nav-item",
                icon: <GroupIcon sx={{ fontSize: 20 }}/>,
            },
            {
                uri: "/admin/state",
                label: 'State',
                type: "nav-item",
                icon: <FoundationIcon sx={{ fontSize: 20 }}/>,
            },
            {
                uri: "/admin/site",
                label: 'Site',
                type: "nav-item",
                icon: <ViewListIcon sx={{ fontSize: 20 }}/>,
            },
            {
                uri: "/admin/equipment",
                label: 'Equipment',
                type: "nav-item",
                icon: <HomeRepairServiceIcon sx={{ fontSize: 20 }}/>,
            },
            {
                uri: "/admin/checklist",
                label: 'Checklist',
                type: "nav-item",
                icon: <FactCheckIcon sx={{ fontSize: 20 }}/>,
            },
        ]
    },

    {
        label: 'Audit Plan',
        uri: "/audit-plan",
        type: "nav-item",
        icon: <ReceiptLongIcon sx={{ fontSize: 20 }} />
    },
    {
        label: 'Auditor Summary',
        uri: "/auditor-summary",
        type: "nav-item",
        icon: <SummarizeIcon sx={{ fontSize: 20 }} />
    },
    {
        label: 'Auditee Summary',
        uri: "/auditee-summary",
        type: "nav-item",
        icon: <SummarizeIcon sx={{ fontSize: 20 }} />
    },
    {
        label: 'Reviewer Summary',
        uri: "/reviewer-summary",
        type: "nav-item",
        icon: <GradingIcon sx={{ fontSize: 20 }} />
    },
    {
        label: 'Previous Year Audit Data',
        uri: "/previous-year-audit-data",
        type: "nav-item",
        icon: <DatasetIcon sx={{ fontSize: 20 }} />
    },

];

export default menus;
