import React, { useEffect, useState } from 'react';
import SolarTable from "@custom/Components/SolarTable";
import { Box, InputLabel, Typography } from "@mui/material";
import CustomButton from "@custom/Components/CustomButton";
import Dropdown from '@custom/Components/Dropdown';
import AuthMethods from 'app/services';
import _ from 'lodash';
import CustomDialog from '@custom/Components/CustomDialog';
import EquipmentSelection from './EquipmentSelection';
import { Link } from 'react-router-dom';
import { financialYearData, statusName } from '@custom/Functions/helperFunction';
import { AES } from 'crypto-js';

export default function AuditorSummary() {

     const userId = localStorage.getItem('userId');

     const [loading, setLoading] = useState(false);
     const [fYDropdown, setFYDropdown] = useState([]);
     const [financialYear, setFinancialYear] = useState('');
     const [showTable, setShowTable] = useState(false);
     const [tableData, setTableData] = useState([]);
     const [openDialog, setOpenDialog] = useState(false);
     const [siteId, setSiteId] = useState('');
     const [auditPlanDataId, setAuditPlanDataId] = useState('');
     const [auditSummaryChanged, setAuditSummaryChanged] = useState(false);

     const columns = [
          { 
               title: "ID", 
               field: "id",
               filtering: false, 
               hidden: true, 
               hiddenByColumnsButton: true 
          },
          { 
               title: "State",
               field: "stateName",
               filtering: false, 
          },     
          { 
               title: "Site",
               field: "siteName",
               filtering: false, 
          },
          { 
               title: "Start Date",
               field: "startDate",
               filtering: false, 
          },
          { 
               title: "End Date", 
               field: "endDate",
               filtering: false,
          },
          { 
               title: "Auditee", 
               field: "auditee",
               filtering: false, 
          },
          {
               title: "Status",
               field: "statusName",
               filtering: false,
          },
          {
               title: "Equipment Selection",
               field: "equipment",
               filtering: false,
               render: rowData => {
                    // console.log(rowData);
                    return (
                         <Box p={1}>
                              <CustomButton
                                   variant="contained"
                                   color="grey"
                                   icon=""
                                   onClick={e => {
                                        // console.log(rowData);
                                        handleSelectEquipment(rowData);
                                   }}
                                   disabled={rowData['status'] === 1 ? false : true}
                              >
                                   Select Equipment
                              </CustomButton>
                         </Box>
                    )
               }
          },
          {
               title: "Checklist",
               field: "chechlist",
               filtering: false,
               render: rowData => {
               // console.log(rowData);
                    
               const valueToEncrypt = rowData.auditPlanDataId.toString();

               // Encryption
               const encryptedValue = AES.encrypt(valueToEncrypt, 'secret-key').toString();

                    return (
                         <Box p={1}>
                              <Link 
                                   to={`/auditor-checklist?value=${encodeURIComponent(encryptedValue)}`} 
                                   style={{ 
                                        textDecoration: 'none',
                                        pointerEvents: (rowData['status'] === 0 || rowData['status'] === 1) ? 'none' : 'visible',
                                   }}
                              >
                                   <CustomButton
                                        variant="contained"
                                        color="blue"
                                        icon=""
                                        disabled={(rowData['status'] === 0 || rowData['status'] === 1) ? true : false}
                                   >
                                        Checklist
                                   </CustomButton>
                              </Link>
                         </Box>
                    )
               }
          },
     ];

     // status
     // 1 - eq enable (rest all disable)
     // 0,1 - checklist disable 
     // 2,3,4,5,6 -checklist enable 

     const handleAuditTable = () => {
          setShowTable(true);
     };

     const handleSelectEquipment = (rowData) => {
          // console.log(rowData);
          setOpenDialog(true);
          setAuditPlanDataId(rowData.auditPlanDataId);
          setSiteId(rowData.siteId);
     };

     useEffect(() => {
          if (financialYear !== '') {
               setLoading(true);
               let summaryData = {
                    year: financialYear,
                    user_id: userId
               };
               AuthMethods['auditorEquipment'].getAuditorSummary(summaryData, setLoading).then(data => {
                    // console.log(data);
                    if (data['audited_data'].length > 0) {
                         let arr = [];
                         data['audited_data'].forEach((item, index) => {
                              let obj = {
                                   id: index,
                                   auditPlanDataId: item['id'],
                                   stateName: item['state_name'],
                                   siteId: item['site_id'],
                                   siteName: item['site_name'], 
                                   startDate: item['start_date'],
                                   endDate: item['end_date'],
                                   auditee: item['auditee_name'],
                                   status: item['status'],
                                   statusName: statusName[item['status']],
                              };
                              arr.push(obj);
                         });
                         // console.log(arr);
                         setTableData(arr);
                    }
               });
          }

     }, [financialYear, auditSummaryChanged]);

     useEffect(() => {

          AuthMethods['common'].getFinancialYear().then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = financialYearData(data['data']);
                    // console.log(arr);
                    setFYDropdown(arr[0]);
                    setFinancialYear(arr[1]);
               }
          });

     }, []);

     return (

          <React.Fragment>

               <Box mb={3}>
                    <Typography
                         sx={{ 
                         color: theme => theme.palette.text.primary,
                         fontSize: '21px',
                         fontWeight: 'bold'
                         }}
                         >
                              Auditor Summary
                    </Typography>
               </Box>

               <Box display="flex" flexWrap="wrap" alignItems="flex-end" mb={3}>
                    <Box sx={{ p: 1, width: 200 }}>
                         <InputLabel
                              sx={{ 
                                   color: theme => theme.palette.text.secondary,
                                   fontSize: '13px',
                                   fontWeight: 'bold',
                                   pb: '3px'
                              }}
                         >
                              Financial Year
                         </InputLabel>
                         <Dropdown
                              labelkey="id"
                              labelname="name"
                              name="financialYear"
                              placeholder="Select Financial Year"
                              value={financialYear}
                              dropdown={fYDropdown}
                              onChange={e => {
                                   setFinancialYear(e.target.value);
                                   setShowTable(false);
                                   setTableData([]);
                              }}
                              disabled={false}
                         />
                    </Box>
                    <Box sx={{ p: 1, width: 200 }}>
                         
                         <CustomButton variant="contained" color="primary" icon="" onClick={handleAuditTable}>
                              View
                         </CustomButton>
                    </Box>            
               </Box>

               {showTable && (
                    <SolarTable
                         title="Auditor Summary Table"
                         columns={columns}
                         data={tableData}
                         filtering={false}
                         loading={loading}
                         actions={[]}
                         detailPanel={[]}
                    />
               )}

               <CustomDialog openDialog={openDialog} maxWidth="lg">
                    <EquipmentSelection
                         setOpenDialog={setOpenDialog} 
                         auditPlanDataId={auditPlanDataId} 
                         siteId={siteId} 
                         setAuditSummaryChanged={setAuditSummaryChanged}
                    />
               </CustomDialog>

          </React.Fragment>

     );
}