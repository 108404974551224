import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import { makeStyles } from '@mui/styles';
import { isNull } from 'lodash';
import PageLoader from '@custom/Components/PageLoader';

const useStyles = makeStyles({
     image: {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
     },
});

export default function CommonPreview(props) {

     // console.log(props.image);

     const classes = useStyles();

     const [loading, setLoading] = useState(false);
     const [displayImage , setDisplayImage] = useState('');

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     // useEffect(() => {
     //      if (displayImage !== '') {
     //           setTimeout(() => {
     //                setLoading(false);
     //           }, 1000);
     //      }
     // }, [displayImage]);

     useEffect(() => {
          setLoading(true);
          if (!isNull(props.image)) {
               if (typeof(props.image) === 'object') {
                    setDisplayImage(URL.createObjectURL(props.image));
               } else {
                    setDisplayImage(props.image);
               }
               setLoading(false);
          } else {
               setLoading(false);
          }
     }, [props.image]);

     return (

          <React.Fragment>

               {loading && <PageLoader title="Loading..." />}

               <DialogTitle 
                    sx={{ 
                         m: 0, 
                         p: 2.3, 
                         color: '#505F79', 
                         fontSize: '19px', 
                         fontWeight: 'bold',
                         textAlign: 'center',
                         color: theme => theme.palette.primary.main,
                    }}
               >
                    Image Preview
               </DialogTitle>
          
               <form>
                    <DialogContent dividers
                         sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '72vh',
                         }}
                    >
                         {(displayImage !== '') ? (
                              <img className={classes.image} src={displayImage} alt="image" />
                         ) : (
                              <img className={classes.image} src={props.image} alt="no image" />                              
                         )}
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'center' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>

          </React.Fragment>
     );
}