import React, { useEffect, useState } from 'react';
import { Box, InputLabel, Tab, Tabs, Typography } from '@mui/material';
import BorderAllIcon from "@mui/icons-material/BorderAll";
import BarChartIcon from '@mui/icons-material/BarChart';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CompareIcon from '@mui/icons-material/Compare';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReportsTab from './ReportsTab';
import GraphsTab from './GraphsTab';
import ComponentGraphTab from './ComponentGraphTab';
import StatusTab from './StatusTab';
import ComparisonTab from './ComparisonTab';
import IssuesTab from './IssuesTab';
import AuthMethods from 'app/services';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { AES } from 'crypto-js';
import Highcharts from 'highcharts';
import { financialYearData } from '@custom/Functions/helperFunction';
import Dropdown from '@custom/Components/Dropdown';
import PropTypes from 'prop-types';

function TabPanel(props) {
     const { children, value, index, ...other } = props;

     return (
          <div
               role="tabpanel"
               hidden={value !== index}
               id={`simple-tabpanel-${index}`}
               aria-labelledby={`simple-tab-${index}`}
               {...other}
          >
               {value === index && (
                    <Box p={1}>
                         {children}
                    </Box>
               )}
          </div>
     );
}

TabPanel.propTypes = {
     children: PropTypes.node,
     index: PropTypes.number.isRequired,
     value: PropTypes.number.isRequired,
};

function a11yProps(index) {
     return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
     };
}

export default function DashboardTabs(props) {

     const userId = localStorage.getItem('userId');

     const [financialYear, setFinancialYear] = useState('');
     const [fYDropdown, setFYDropdown] = useState([]);
     const [fyFull, setFyFull] = useState({});
     const [fyName, setFyName] = useState('');

     const [value, setValue] = useState(0);

     // reports tab     
     const [chartLoadingR, setChartLoadingR] = useState(false);
     const [cardLoadingR, setCardLoadingR] = useState(false);
     const [loadingR, setLoadingR] = useState(false);
     const [loading_1, setLoading_1] = useState(false);
     const [stateInitialColumn, setStateInitialColumn] = useState([]);
     const [stateInitialData, setStateInitialData] = useState([]);
     const [stateCurrentColumn, setStateCurrentColumn] = useState([]);
     const [stateCurrentData, setStateCurrentData] = useState([]);
     const [componentInitialData, setComponentInitialData] = useState([]);
     const [componentCurrentData, setComponentCurrentData] = useState([]);
     const [healthScore, setHealthScore] = useState([]);
     const [initialObservation, setInitialObservation] = useState({
          'high': '',
          'mid': '',
          'low': ''
     });
     const [pendingObservation, setPendingObservation] = useState({
          'high': '',
          'mid': '',
          'low': ''
     });
     const [reportsFlag, setReportsFlag] = useState(false);

     // graphs tab
     const [loadingG, setLoadingG] = useState(false);
     const [loadingCompG, setLoadingCompG] = useState(false);
     const [seriesInitial, setSeriesInitial] = useState([]);
     const [drillInitial, setDrillInitial] = useState([]);
     const [seriesCurrent, setSeriesCurrent] = useState([]);
     const [drillCurrent, setDrillCurrent] = useState([]);
     const [seriesCompInitial, setSeriesCompInitial] = useState([]);
     const [seriesCompCurrent, setSeriesCompCurrent] = useState([]);
     const [graphsFlag, setGraphsFlag] = useState(false);

     // componentGraphs tab
     const [loadingC, setLoadingC] = useState(false);
     const [seriesCompOverall, setSeriesCompOverall] = useState([]);
     const [componentGraphsFlag, setComponentGraphsFlag] = useState(false);

     // status tab
     const [loadingS, setLoadingS] = useState(false);
     const [tableLoadingS, setTableLoadingS] = useState(false);
     const [seriesData, setSeriesData] = useState([]);
     const [catState, setCategoriesState] = useState({});
     const [componentData, setComponentData] = useState([]);
     const [stateData, setStateData] = useState([]);
     const [planned, setPlannedData] = useState('-');
     const [audited, setAuditedData] = useState('-');
     const [completed, setCompletedData] = useState('-');
     const [tCardData, setTCardData] = useState({});
     const [statusFlag, setStatusFlag] = useState(false);

     // fyComparison tab
     const [loadingFC, setLoadingFC] = useState(true);
     const [fyColumnsCat, setFyColumnsCat] = useState([]);
     const [fyColumns, setFyColumns] = useState([]);
     const [fySeries, setFySeries] = useState([]);
     const [fyTableData, setFyTableData] = useState([]);
     const [fyColumnsIssue, setFyColumnsIssue] = useState([]);
     const [fySeriesIssue, setFySeriesIssue] = useState([]);
     const [fyComparisonFlag, setFyComparisonFlag] = useState(false);
     
     // reports tab     
     const initialScore = {

     chart: {
          type: 'solidgauge',
     },

     title: {
          enabled: true,
          text: 'Initial Score'
     },

     pane:{
          center: ['50%', '80%'],
          size: '140%',
          startAngle: -90,
          endAngle: 90,
          background: {
               backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
               innerRadius: '60%',
               outerRadius: '90%',
               shape: 'arc'
          }
     },

     exporting: {
          enabled: false
     },

     plotOptions: {
          solidgauge: {
               dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
               }
          }
     },

     yAxis: {
          stops: [
               [0, '#DDDF0D']
          ],
          min: 0,
          max: 100,
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
               y: -70
          },
          labels: {
               y: 16
          }
     },

     series: [
          {
               name: 'initial',
               data: [{
                    y: parseFloat(healthScore[0]),
                    radius: 60,
                    innerRadius: 90
               }],
               dataLabels: {
                    format:
                         '<div style="text-align:center">' +
                         '<span style="font-size:25px">{y}</span><br/>' +
                         '<span style="font-size:12px;opacity:0.4">%</span>' +
                         '</div>'
               },
               tooltip: {
                    valueSuffix: ' %'
               },
          }
     ],
     };

     const currentScore = {

     chart: {
          type: 'solidgauge',
     },

     title: {
          enabled: true,
          text: 'Current Score'
     },

     pane:{
          center: ['50%', '80%'],
          size: '140%',
          startAngle: -90,
          endAngle: 90,
          background: {
               backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
               innerRadius: '60%',
               outerRadius: '90%',
               shape: 'arc'
          }
     },

     exporting: {
          enabled: false
     },

     plotOptions: {
          solidgauge: {
               dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
               }
          }
     },

     yAxis: {
          stops: [
               [0, '#55BF3B']
          ],
          min: 0,
          max: 100,
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
               y: -70
          },
          labels: {
               y: 16
          }
     },

     series: [
          {
               name: 'current',
               data: [{
                    y: parseFloat(healthScore[1]),
                    radius: 60,
                    innerRadius: 90
               }],
               dataLabels: {
                    format:
                         '<div style="text-align:center">' +
                         '<span style="font-size:25px">{y}</span><br/>' +
                         '<span style="font-size:12px;opacity:0.4">%</span>' +
                         '</div>'
               },
               tooltip: {
                    valueSuffix: ' %'
               },
          }
     ],
     };

     // graphs tab
     const stateInitial = {
     credits: {
          enabled: false,
     },

     chart: {
          type: "column",
          events: {
          render: function() {
               this.reflow();
          },
          redraw: function(event) {
               // when api is not working
               if (this.series.length === 0) {
               this.showLoading('Cannot Load Data Currently');
               } else if (this.series[0].data.length === 0) {
               // no data 
               this.showLoading('No Data');
               } else {
               this.hideLoading();
               }
          },
          },
     },

     title: {
          text: "State Wise Initial Status",
          style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#666A7B',
          },
     },

     xAxis: {
          type: "category",
     },

     yAxis: [
          {
          // Primary yAxis
          title: {
               text: 'Count',
          },
          },
          {
          // Secondary yAxis
          title: {
               text: 'HS (%)',
          },
          min: 0,
          max: 100,
          opposite: true,
          },
     ],

     tooltip: {
          pointFormatter: function() {
          let point = this;
          return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + (point.series.name === 'Health Score' && point.y === 0 ? '-' : point.y) + '</b><br/>';
          },
          shared: true,
          useHTML: true,
     },

     legend: {
          enabled: true,
     },

     plotOptions: {
          column: {
          pointPadding: 0.2,
          borderWidth: 0,
          },
     },

     series: seriesInitial,

     drilldown: {
          series: drillInitial
     },

     };

     const stateCurrent = {
     credits: {
          enabled: false,
     },

     chart: {
          type: "column",
          events: {
          render: function() {
               this.reflow();
          },
          redraw: function(event) {
               // when api is not working
               if (this.series.length === 0) {
               this.showLoading('Cannot Load Data Currently');
               } else if (this.series[0].data.length === 0) {
               // no data 
               this.showLoading('No Data');
               } else {
               this.hideLoading();
               }
          },
          },
     },

     title: {
          text: "State Wise Current Status",
          style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#666A7B',
          },
     },

     xAxis: {
          type: "category",
     },

     yAxis: [
          {
          // Primary yAxis
          title: {
               text: 'Count',
          },
          },
          {
          // Secondary yAxis
          title: {
               text: 'HS (%)',
          },
          min: 0,
          max: 100,
          opposite: true,
          },
     ],

     tooltip: {
          pointFormatter: function() {
          let point = this;
          return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + (point.series.name === 'Health Score' && point.y === 0 ? '-' : point.y) + '</b><br/>';
          },
          shared: true,
          useHTML: true,
     },

     legend: {
          enabled: true,
     },

     plotOptions: {
          column: {
          pointPadding: 0.2,
          borderWidth: 0,
          },
     },

     series: seriesCurrent,

     drilldown: {
          series: drillCurrent,
     },

     };

     const componentInitial = {

     chart: {
          type: 'column'
     },

     title: {
          text: 'Component Wise Initial Status'
     },

     xAxis: {
          type: "category",
     },

     yAxis: [
          {
          // Primary yAxis
          title: {
               text: 'Count',
          },
          },
          {
          // Secondary yAxis
          title: {
               text: 'HS (%)',
          },
          min: 0,
          max: 100,
          opposite: true,
          },
     ],

     tooltip: {
          pointFormatter: function() {
          let point = this;
          return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + (point.series.name === 'Health Score' && point.y === 0 ? '-' : point.y) + '</b><br/>';
          },
          shared: true,
          useHTML: true,
     },

     legend: {
          enabled: true,
     },

     plotOptions: {
          column: {
          pointPadding: 0.2,
          borderWidth: 0,
          },
     },

     series: seriesCompInitial,
     
     };

     const componentCurrent = {

     chart: {
          type: 'column'
     },

     title: {
          text: 'Component Wise Current Status'
     },

     xAxis: {
          type: "category",
     },

     yAxis: [
          {
          // Primary yAxis
          title: {
               text: 'Count',
          },
          },
          {
          // Secondary yAxis
          title: {
               text: 'HS (%)',
          },
          min: 0,
          max: 100,
          opposite: true,
          },
     ],

     tooltip: {
          pointFormatter: function() {
          let point = this;
          return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + (point.series.name === 'Health Score' && point.y === 0 ? '-' : point.y) + '</b><br/>';
          },
          shared: true,
          useHTML: true,
     },

     legend: {
          enabled: true,
     },

     plotOptions: {
          column: {
          pointPadding: 0.2,
          borderWidth: 0,
          },
     },

     series: seriesCompCurrent,
     
     };
     
     // componentGraphs tab
     const compOverall = {
          chart: {
               type: "column",
          },
     
          title: {
               text: "Overall",
          },
     
          xAxis: {
               type: "category",
          },
          
          yAxis: [
               {
               // Primary yAxis
               title: {
                    text: 'Count',
               },
               },
               {
               // Secondary yAxis
               title: {
                    text: 'HS (%)',
               },
               min: 0,
               max: 100,
               opposite: true,
               },
          ],
     
          tooltip: {
               pointFormatter: function() {
                    let point = this;
                    return '<span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': <b>' + (point.series.name === 'Health Score' && point.y === 0 ? '-' : point.y) + '</b><br/>';
               },
               shared: true,
               useHTML: true,
          },
     
     
          legend: {
               enabled: true,
          },
     
          plotOptions: {
               column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
               },
          },
     
          series: seriesCompOverall,
     };

     // status tab
     const chartDataAuditPlansReport = {
          chart: {
          type: "column",
          },
     
          title: {
          text: "Audit Report Status",
          },
     
          xAxis: {
          categories: catState,
          },
     
          yAxis: {
          title: {
          text: "Count",
          },
          },
          
          tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          },
     
          legend: {
          enabled: true,
          },
          
          plotOptions: {
          column: {
          pointPadding: 0.2,
          borderWidth: 0,
          },
          },
          
          series: seriesData,
     };

     // fyComparison tab
     const chartDataFinancialYear = {
          chart: {
          type: "column",
          },
     
          title: {
          text: "Financial Year Wise Comparison",
          },
     
          xAxis: {
          categories: fyColumnsCat,
          },
     
          yAxis: [
          {
          // Primary yAxis
          title: {
               text: 'No. of Sites',
          },
          },
          {
          // Secondary yAxis
          title: {
               text: 'Percentage (%)',
          },
          min: 0,
          max: 100,
          opposite: true,
          },
          ],
          
          tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          },
     
          legend: {
          enabled: true,
          },
          
          plotOptions: {
          column: {
          pointPadding: 0.2,
          borderWidth: 0,
          },
          },
     
          series: fySeries,
     };
 
     const chartDataFinancialYearIssue = {
          
          chart: {
          type: "column",
          },
          
          title: {
          text: "Financial Year Wise No. of Issue Comparison",
          // align: "left",
          },
     
          xAxis: {
          categories: fyColumnsIssue
          },
     
          yAxis: [
          {
          // Primary yAxis
          title: {
               text: 'Count',
          },
          },
          {
          // Secondary yAxis
          title: {
               text: 'HS (%)',
          },
          min: 0,
          max: 100,
          opposite: true,
          },
          ],
     
          tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          },
     
          legend: {
          enabled: true,
          },
     
          plotOptions: {
          column: {
          pointPadding: 0.2,
          borderWidth: 0,
          },
          },
     
          series: fySeriesIssue 
     };

     // tabs api call functions
     const reportsAPI = () => {

          let healthScoreData = {
               'fy_id': financialYear,
               'user_id': userId,
          };

          setReportsFlag(true);
          setChartLoadingR(true);
          setHealthScore([]);
          AuthMethods['chartAndTable'].getHealthScore(healthScoreData, setChartLoadingR).then(data => {
               
               if (_.size(data['score']) > 0) {
                    setHealthScore([data['score']['initial'], data['score']['current']]);
               };
          });

          let observationData = {
               'fy_id': financialYear,
               'user_id': userId,
          };

          setCardLoadingR(true);
          setInitialObservation({});
          setPendingObservation({});
          AuthMethods['chartAndTable'].getObservations(observationData, setCardLoadingR).then(data => {
               
               if (_.size(data['data']) > 0) {
                    if (_.size(data['data']['initial_observations']) > 0) {
                         let initial = {
                              'high': data['data']['initial_observations']['high'],
                              'mid': data['data']['initial_observations']['mid'],
                              'low': data['data']['initial_observations']['low'],
                         };
                         setInitialObservation(initial);
                    }
                    if (_.size(data['data']['current_observations']) > 0) {
                         let pending = {
                              'high': data['data']['current_observations']['high'],
                              'mid': data['data']['current_observations']['mid'],
                              'low': data['data']['current_observations']['low'],
                         };
                         setPendingObservation(pending);
                    }
               }
          });

          let postData = {
               fy_id: financialYear,
               user_id: userId
          };

          setLoadingR(true);
          setStateInitialData([]);
          setStateCurrentData([]);
          AuthMethods['chartAndTable'].stateWiseData(postData, setLoadingR).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    
                    if (_.size(data['data'][0]['initial_data']) > 0) {
                         let initialData = data['data'][0]['initial_data'];
                         let arr = [];
                         for (let key in initialData) {

                              const valueToEncrypt = financialYear + ',' + key + ',' + initialData[key]['state_id'];

                              // Encryption
                              const encryptedValue = AES.encrypt(valueToEncrypt, 'secret-key').toString();

                              let columnObj = {
                                   title: <Link 
                                        to={`/dashboard-reports?value=${encodeURIComponent(encryptedValue)}`}
                                        style={{ color: '#55698C' }}
                                   >
                                        {key}
                                   </Link>,
                                   field: key,
                                   filtering: false,
                              };
                              arr.push(columnObj);
                         }
                         setStateInitialColumn([
                              {
                                   title: "ID",
                                   field: "id",
                                   filtering: false,
                                   hidden: true,
                                   hiddenByColumnsButton: true
                              },
                              {
                                   title: '',
                                   field: 'blank',
                                   filtering: false,
                              },
                              {
                                   title: 'Overview',
                                   field: 'overview',
                                   filtering: false,
                              },
                              ...arr
                         ]);

                         let dataArr = [];
                         if (_.size(data['data'][0]['initial_overview']) > 0) {
                              let firstRow = {
                                   'SITES': 'sites',
                                   'PLANNED': 'planned',
                                   'AUDITED': 'audited',
                                   'HIGH': 'high',
                                   'MID': 'mid',
                                   'LOW': 'low',
                                   'HS (%)': 'hs',
                              };

                              for (let row in firstRow) {
                                   let dataObj = {
                                        id: row,
                                        blank: row,
                                        overview: data['data'][0]['initial_overview'][firstRow[row]]
                                   };
                                   for (let key in initialData) {
                                        dataObj[key] = dataObj['id'] === 'HS (%)' ? (initialData[key][firstRow[row]] === 0 ? '-' : initialData[key][firstRow[row]]) : initialData[key][firstRow[row]]; 
                                   }
                                   dataArr.push(dataObj);
                              }
                         }
                         setStateInitialData(dataArr);
                    }

                    if (_.size(data['data'][1]['current_data']) > 0) {
                         let currentData = data['data'][1]['current_data'];
                         let arr = [];
                         for (let key in currentData) {
                              let columnObj = {
                                   title: key,
                                   field: key,
                                   filtering: false
                              };
                              arr.push(columnObj);
                         }
                         setStateCurrentColumn([
                              {
                                   title: "ID",
                                   field: "id",
                                   filtering: false,
                                   hidden: true,
                                   hiddenByColumnsButton: true
                              },
                              {
                                   title: '',
                                   field: 'blank',
                                   filtering: false,
                              },
                              {
                                   title: 'Overview',
                                   field: 'overview',
                                   filtering: false,
                              },
                              ...arr
                         ]);

                         let dataArr = [];
                         if (_.size(data['data'][1]['current_overview']) > 0) {
                              let firstRow = {
                                   'SITES': 'sites',
                                   'PLANNED': 'planned',
                                   'AUDITED': 'audited',
                                   'HIGH': 'high',
                                   'MID': 'mid',
                                   'LOW': 'low',
                                   'HS (%)': 'hs',
                              };

                              for (let row in firstRow) {
                                   let dataObj = {
                                        id: row,
                                        blank: row,
                                        overview: data['data'][1]['current_overview'][firstRow[row]]
                                   };
                                   for (let key in currentData) {
                                        dataObj[key] = dataObj['id'] === 'HS (%)' ? (currentData[key][firstRow[row]] === 0 ? '-' : currentData[key][firstRow[row]]) : currentData[key][firstRow[row]]; 
                                   }
                                   dataArr.push(dataObj);
                              }
                         }
                         setStateCurrentData(dataArr);
                    }
               }

          });

          let postData_1 = {
               fy_id: financialYear,
               user_id: userId,
          };

          setLoading_1(true);
          setComponentInitialData([]);
          setComponentCurrentData([]);
          AuthMethods['chartAndTable'].componentWiseDataOverAll(postData_1, setLoading_1).then(data => {

               if (Object.keys(data['data']).length > 0) {
                    if (Object.keys(data['data']['initial']).length > 0) {
                         let initialData = data['data']['initial'];
                         let dataArr = [];
                         let index = 1
                         for (let key in initialData) {

                              dataArr.push({
                                   id: index++,
                                   wtg: key,
                                   high: initialData[key]['High'],
                                   mid: initialData[key]['Mid'],
                                   low: initialData[key]['Low'],
                                   hs: initialData[key]['hs'] === 0 ? '-' : initialData[key]['hs'],
                              });
                         }

                         setComponentInitialData(dataArr);
                    }
                    if (Object.keys(data['data']['current']).length > 0) {
                         let currentData = data['data']['current'];

                         let dataArr = [];
                         let index = 1
                         for (let key in currentData) {

                              dataArr.push({
                                   id: index++,
                                   wtg: key,
                                   high: currentData[key]['High'],
                                   mid: currentData[key]['Mid'],
                                   low: currentData[key]['Low'],
                                   hs: currentData[key]['hs'] === 0 ? '-' : currentData[key]['hs'],
                              });
                         }
                         setComponentCurrentData(dataArr);
                    }
               }
          });
     };

     const graphsAPI = () => {

          let stateSiteData = {
          fy_id: financialYear,
          user_id: userId,
          };

          setGraphsFlag(true);
          setLoadingG(true);
          setSeriesInitial([]);
          setSeriesCurrent([]);
          AuthMethods['chartAndTable'].getStateSite(stateSiteData, setLoadingG).then(data => {
          // console.log(data);
          
          if (_.size(data['data']) > 0) {

          if (_.size(data['data']['initial']) > 0) {
               let points = {
               '' : '#FFFFFF',
               'High': '#E95363',
               'Mid' : '#F4A738',
               'Low': '#7B9BEA',
               };
     
               let seriesArr = [];
               let drillArr = [];
               
               for (let point in points) {
               let seriesObj = {
                    name: point,
                    color: points[point],
                    data: [],
               };
               
               // processing series data   
               for (let state in data['data']['initial']) {
                    let id = `${state}-${point}`;
     
                    let obj = {
                    name: state,
                    y: point === '' ? null : data['data']['initial'][state][point],
                    drilldown: id,
                    };
               
                    seriesObj['data'].push(obj);
     
                    // processing drilldown data
                    if (_.size(data['data']['initial'][state]['sites']) > 0) {
                    let drillObj = {
                    id: id,
                    name: point,
                    data: [],
                    };
     
                    let newObj = {
                    id: `${state}-hs`,
                    type: 'spline',
                    lineWidth: 0,
                    marker: {},
                    states: {
                         hover: {
                         lineWidthPlus: 0
                         }
                    },
                    name: 'Health Score',
                    yAxis: 1,
                    data: [],
                    };
     
                    for (let site in data['data']['initial'][state]['sites']) {
                    let insideArr = [site, data['data']['initial'][state]['sites'][site][point]];
                    drillObj['data'].push(insideArr);
                    
                    let hsArr = [site, data['data']['initial'][state]['sites'][site]['hs']];
                    newObj['data'].push(hsArr);
                    }
     
                    drillArr.push(drillObj);
                    drillArr.push(newObj);
                    // console.log(drillArr);
                    setDrillInitial(drillArr);
                    }
                    
               }
               // console.log(seriesObj);
               seriesArr.push(seriesObj);
               }
     
               // adding health score in series 
               let hsObj = {
               type: 'spline',
               lineWidth: 0,
               marker: {},
               states: {
                    hover: {
                    lineWidthPlus: 0
                    }
               },
               name: 'Health Score',
               yAxis: 1,
               color: '#065DF3',
               data: [],
               };
     
               for (let state in data['data']['initial']) {
               let newObj = {
                    name: state,
                    color: data['data']['initial'][state]['hs'] === 0 ? 'transparent' : '#065DF3',
                    y: data['data']['initial'][state]['hs'],
                    drilldown: `${state}-hs`,
               };
     
               let radius = {
                    radius: 0
               };
     
               if (data['data']['initial'][state]['hs'] === 0) {
                    hsObj['marker'] = radius;
               }
     
               hsObj['data'].push(newObj);
               }
     
               // console.log(hsObj);
               seriesArr.push(hsObj);
               // console.log(seriesArr);
               setSeriesInitial(seriesArr);
          }

          if (_.size(data['data']['current']) > 0) {
               let points = {
               '' : '#FFFFFF',
               'High': '#E95363',
               'Mid': '#F4A738',
               'Low': '#7B9BEA'
               };
     
               let seriesArr = [];
               let drillArr = [];
     
               for (let point in points) {
               let seriesObj = {
                    name: point,
                    color: points[point],
                    data: [],
               };
     
               // processing series data   
               for (let state in data['data']['current']) {
                    let id =`${state}-${point}`;
     
                    let obj = {
                    name: state,
                    y: point === '' ? null : data['data']['current'][state][point],
                    drilldown: id,
                    };
     
                    seriesObj['data'].push(obj);
     
                    // processing drilldown data
                    if (_.size(data['data']['current'][state]['sites']) > 0) {
                    let drillObj = {
                    id: id,
                    name: point,
                    data: [],
                    };
     
                    let newObj = {
                    id: `${state}-hs`,
                    type: 'spline',
                    lineWidth: 0,
                    marker: {},
                    states: {
                         hover: {
                         lineWidthPlus: 0
                         }
                    },
                    name: 'Health Score',
                    yAxis: 1,
                    data: [],
                    };
     
                    for (let site in data['data']['current'][state]['sites']) {
                    let insideArr = [site, data['data']['current'][state]['sites'][site][point]];
                    drillObj['data'].push(insideArr);
     
                    let hsArr = [site, data['data']['current'][state]['sites'][site]['hs']];
                    newObj['data'].push(hsArr);
                    }
     
                    drillArr.push(drillObj);
                    drillArr.push(newObj);
                    // console.log(drillArr);
                    setDrillCurrent(drillArr);
                    }
     
               }
               seriesArr.push(seriesObj);
               }
     
               // adding health score in series 
               let hsObj = {
               type: 'spline',
               lineWidth: 0,
               marker: {},
               states: {
                    hover: {
                    lineWidthPlus: 0
                    }
               },
               name: 'Health Score',
               yAxis: 1,
               color: '#065DF3',
               data: [],
               };
               
               for (let state in data['data']['current']) {
               let newObj = {
                    name: state,
                    color: data['data']['current'][state]['hs'] === 0 ? 'transparent' : '#065DF3',
                    y: data['data']['current'][state]['hs'],
                    drilldown: `${state}-hs`,
               };
     
               let radius = {
                    radius: 0
               };
     
               if (data['data']['current'][state]['hs'] === 0) {
                    hsObj['marker'] = radius;
               }
     
               hsObj['data'].push(newObj);
               }
     
               // console.log(hsObj);
               seriesArr.push(hsObj);
               // console.log(seriesArr);
               setSeriesCurrent(seriesArr);
          }
          }
     
          });
     
          let componentData = {
          fy_id: financialYear,
          user_id: userId,
          };
     
          setLoadingCompG(true);
          setSeriesCompInitial([]);
          setSeriesCompCurrent([]);
          AuthMethods['chartAndTable'].componentWiseDataOverAll(componentData, setLoadingCompG).then(data => {
          // console.log(data);
          if (_.size(data['data']) > 0) {

          // data processing for component initial status  
          if (_.size(data['data']['initial']) > 0) {
               let points = {
               'High': '#E95363',
               'Mid': '#F4A738',
               'Low': '#7B9BEA'
               };
     
               let compArr = [];
               for (let point in points) {
               let compObj = {
                    name: point,
                    color: points[point],
                    data: []
               };
     
               for (let comp in data['data']['initial']) {
                    let obj = {
                    name: comp,
                    y: data['data']['initial'][comp][point],
                    };
     
                    compObj['data'].push(obj);
               }
               // console.log(obj);
               compArr.push(compObj);
               }

               let hsObj = {
               type: 'spline',
               lineWidth: 0,
               marker: {},
               states: {
                    hover: {
                    lineWidthPlus: 0
                    }
               },
               name: 'Health Score',
               color: '#065DF3',
               yAxis: 1,
               data: [],
               };

               for (let comp in data['data']['initial']) {
               let newObj = {
                    name: comp,
                    color: data['data']['initial'][comp]['hs'] === 0 ? 'transparent' : '#065DF3',
                    y: data['data']['initial'][comp]['hs'],
               };

               let radius = {
                    radius: 0
               };

               if (data['data']['initial'][comp]['hs'] === 0) {
                    hsObj['marker'] = radius;
               }

               hsObj['data'].push(newObj);
               }
     
               // console.log(hsObj);
               compArr.push(hsObj);
               // console.log(compArr);
               setSeriesCompInitial(compArr);
          }

          // data processing for component current status  
          if (_.size(data['data']['current']) > 0) {
               let points = {
               'High': '#E95363',
               'Mid': '#F4A738',
               'Low': '#7B9BEA'
               };
     
               let compArr = [];
               for (let point in points) {
               let compObj = {
                    name: point,
                    color: points[point],
                    data: [],
               };
     
               for (let comp in data['data']['current']) {
                    let obj = {
                    name: comp,
                    y: data['data']['current'][comp][point],
                    };
     
                    compObj['data'].push(obj);
               }
               // console.log(compCurr);
               compArr.push(compObj);
               }

               let hsObj = {
               type: 'spline',
               lineWidth: 0,
               marker: {},
               states: {
                    hover: {
                    lineWidthPlus: 0
                    }
               },
               name: 'Health Score',
               color: '#065DF3',
               yAxis: 1,
               data: [],
               };

               for (let comp in data['data']['current']) {
               let newObj = {
                    name: comp,
                    color: data['data']['current'][comp]['hs'] === 0 ? 'transparent' : '#065DF3',
                    y: data['data']['current'][comp]['hs'],
               };

               let radius = {
                    radius: 0
               };

               if (data['data']['current'][comp]['hs'] === 0) {
                    hsObj['marker'] = radius;
               }

               hsObj['data'].push(newObj);

               }
               compArr.push(hsObj);
               setSeriesCompCurrent(compArr);
          }
     
          }
          });
     
     };

     const componentGraphsAPI = () => {
          
          let componentData = {
               fy_id: financialYear,
               user_id: userId,
               };
     
               setComponentGraphsFlag(true);
               setLoadingC(true);
               setSeriesCompOverall([]);
               AuthMethods["chartAndTable"].componentWiseDataOverAll(componentData, setLoadingC).then(data => {
               // console.log(data);
     
               if (_.size(data['data']) > 0) {
                    if (_.size(data['data']['current']) > 0) {
                    let points = {
                         'High': '#E95363',
                         'Mid': '#F4A738',
                         'Low': '#7B9BEA'
                    };
     
                    let compArr = [];
                    for (let point in points) {
                         let compObj = {
                         name: point,
                         color: points[point],
                         data: [],
                         };
     
                         for (let comp in data['data']['current']) {
                         let obj = {
                         name: comp,
                         y: data['data']['current'][comp][point],
                         };
     
                         compObj['data'].push(obj);
                         }
                         compArr.push(compObj);
                    }
     
                    let hsObj = {
                         type: 'spline',
                         lineWidth: 0,
                         marker: {},
                         states: {
                         hover: {
                         lineWidthPlus: 0
                         }
                         },
                         name: 'Health Score',
                         color: '#065DF3',
                         yAxis: 1,
                         data: [],
                    };
     
                    for (let comp in data['data']['current']) {
                         let newObj = {
                         name: comp,
                         color: data['data']['current'][comp]['hs'] === 0 ? 'transparent' : '#065DF3',
                         y: data['data']['current'][comp]['hs'],
                         };
     
                         let radius = {
                         radius: 0
                         };
     
                         if (data['data']['current'][comp]['hs'] === 0) {
                         hsObj['marker'] = radius;
                         }
     
                         hsObj['data'].push(newObj);
                    }
     
                    compArr.push(hsObj);
                    // console.log(compArr);
                    setSeriesCompOverall(compArr);
                    }
               }
          });
     };

     const statusAPI = () => {
          let postData = {
          fy_id : financialYear,
          user_id: userId,
          };
          
          setStatusFlag(true);
          setLoadingS(true);
          setPlannedData('-');
          setAuditedData('-');
          setCompletedData('-');
          setSeriesData([]);
          setCategoriesState([]);
          AuthMethods["chartAndTable"].auditStatusData(postData, setLoadingS).then((res) => {    
               if(res.status === 'success'){
                    if (Object.keys(res["data"]).length > 0) {
                    if (Object.keys(res["data"]["overall_audit_status"]).length > 0) {
          
                    setPlannedData(res["data"]["overall_audit_status"]["Planned"]);
                    setAuditedData(res["data"]["overall_audit_status"]["Audited"]);
                    setCompletedData(res["data"]["overall_audit_status"]["Completed"]);
          
                    }
                    if (Object.keys(res["data"]["statewise_audit_status"]).length > 0) {
                    let stateName = [];
                    let seriesData = [
                         {
                         name: "Planned",
                         data: [],
                         },
                         {
                         name: "Audited",
                         data: [],
                         },
                         {
                         name: "Completed",
                         data: [],
                         },
                    ];
                    Object.entries(res["data"]["statewise_audit_status"]).map(
                         (val) => {
                         // console.log(val)
                         stateName.push(val[0]);
                         seriesData[0].data.push(val[1]["planned"]);
                         seriesData[1].data.push(val[1]["audited"]);
                         seriesData[2].data.push(val[1]["completed"]);
                         }
                    );
                    setSeriesData(seriesData);
                    setCategoriesState(stateName);
                    }
                    }
               }
          });
     
          setTableLoadingS(true);
          setTCardData({});
          setComponentData([]);
          setStateData([]);
          AuthMethods['chartAndTable'].getTechnicalHealthData(postData, setTableLoadingS).then(data => {
          // console.log(data);
     
          if (_.size(data['data']) > 0) {
     
               if (_.size(data['data']['overall']) > 0) {
               setTCardData(data['data']['overall']);
               }
               
               if (_.size(data['data']['components']) > 0) {
     
               let compArr = [];
               for (let key in data['data']['components']) {
               let obj = {
                    id: key,
                    component: data['data']['components'][key]['name'],
                    planned: data['data']['components'][key]['planned'],
                    done: data['data']['components'][key]['done'],
                    completion: data['data']['components'][key]['completion'],
               };
     
               compArr.push(obj);
               }
               // console.log(compArr);
               setComponentData(compArr);
               }
     
               if (_.size(data['data']['state']) > 0) {
               let stateArr = [];
               for (let key in data['data']['state']) {
               let obj = {
                    id: key,
                    state: data['data']['state'][key]['name'],
                    planned: data['data']['state'][key]['planned'],
                    done: data['data']['state'][key]['done'],
                    completion: data['data']['state'][key]['completion'],
               };
     
               stateArr.push(obj);
               }
               // console.log(stateArr);
               setStateData(stateArr);
               }
          }
          });
           
     };

     const fyComparisonAPI = () => {

          setFyComparisonFlag(true);
          const postData = {
               user_id: userId,
          };
          AuthMethods["chartAndTable"].fyWiseAuditedData(postData, setLoadingFC).then((res) => {   
               if (Object.keys(res["data"]).length > 0) {
               let fy_year_columns_cat = [];
          
               let fy_year_columns = [
                    {
                    title: "ID",
                    field: "id",
                    filtering: false,
                    hidden: true,
                    hiddenByColumnsButton: true,
                    },
                    {
                    title: "Name",
                    field: "wtg",
                    filtering: false,
                    },
               ];
          
               let fy_series = [
                    {
                    type: "column",
                    name: "Total Sites",
                    data: [],
                    },
                    {
                    type: "column",
                    name: "Planned",
                    data: [],
                    },
                    {
                    type: "column",
                    name: "Audited",
                    data: [],
                    },
                    {
                    type: "spline",
                    name: "Audit Percentage",
                    data: [],
                    yAxis: 1,
                    },
                    {
                    type: "spline",
                    name: "Planned Percentage",
                    data: [],
                    yAxis: 1,
                    color: "#27AE60",
                    },
               ];
          
               let fy_table_data = [
                    {
                    id: "1",
                    wtg: "Total No. of Sites",
                    },
                    {
                    id: "2",
                    wtg: "Planned Sites",
                    },
                    {
                    id: "3",
                    wtg: "Audited Sites",
                    },
               ];
          
               Object.entries(res["data"]['fyYearWise']).map((val) => {
                    fy_year_columns_cat.push(val[0]);
                    fy_year_columns.push({
                    title: val[0],
                    field: val[0],
                    filtering: false,
                    });
          
                    fy_series[0].data.push(val[1]["sites"]);
                    fy_series[1].data.push(val[1]["planned"]);
                    fy_series[2].data.push(val[1]["audited"]);
                    fy_series[3].data.push(val[1]["Audit %"]);
                    fy_series[4].data.push(val[1]["Planned %"]);
          
                    fy_table_data[0][val[0]] = val[1]["sites"];
                    fy_table_data[1][val[0]] = val[1]["planned"];
                    fy_table_data[2][val[0]] = val[1]["audited"];
               });
               setFyColumnsCat(fy_year_columns_cat);
               setFySeries(fy_series);
               setFyTableData(fy_table_data);
               setFyColumns(fy_year_columns);
          
               let fy_columns_issue = []
               let fy_series_issue = [
                    {
                    type: "column",
                    name: "High",
                    data: [],
                    color: '#E95363',
                    },
                    {
                    type: "column",
                    name: "Mid",
                    data: [],
                    color: '#F4A738',
                    },
                    {
                    type: "column",
                    name: "Low",
                    data: [],
                    color: '#7B9BEA',
                    },
                    {
                    type: "spline",
                    name: "Total",
                    data: [],
                    },
                    {
                    type: "spline",
                    name: "Health Score",
                    data: [],
                    yAxis: 1,
                    },
               ]
               Object.entries(res["data"]['fyYearWiseIssue']).map((val) => {
                    // console.log(val)
                    fy_columns_issue.push(val[0])
                    fy_series_issue[0].data.push(val[1]['high']) 
                    fy_series_issue[1].data.push(val[1]['mid']) 
                    fy_series_issue[2].data.push(val[1]['low']) 
                    fy_series_issue[3].data.push(val[1]['Total']) 
                    fy_series_issue[4].data.push(val[1]['hs']) 
               });
               setFyColumnsIssue(fy_columns_issue)
               setFySeriesIssue(fy_series_issue)
               }
          });
     };

     const handleFyChange = (e) => {

          setFinancialYear(e.target.value);
          
          // empty reports tab
          setChartLoadingR(false);
          setCardLoadingR(false);
          setLoadingR(false);
          setLoading_1(false);
          setStateInitialColumn([]);
          setStateInitialData([]);
          setStateCurrentColumn([]);
          setStateCurrentData([]);
          setComponentInitialData([]);
          setComponentCurrentData([]);
          setHealthScore([]);
          setInitialObservation({
               'high': '',
               'mid': '',
               'low': ''
          });
          setPendingObservation({
               'high': '',
               'mid': '',
               'low': ''
          });
          setReportsFlag(false);

          // empty graphs tab
          setLoadingG(false);
          setLoadingCompG(false);
          setSeriesInitial([]);
          setDrillInitial([]);
          setSeriesCurrent([]);
          setDrillCurrent([]);
          setSeriesCompInitial([]);
          setSeriesCompCurrent([]);
          setGraphsFlag(false);

          // empty componentGraphs tab
          setLoadingC(false);
          setSeriesCompOverall([]);
          setComponentGraphsFlag(false);

          // empty status tab
          setLoadingS(false);
          setTableLoadingS(false);
          setSeriesData([]);
          setCategoriesState({});
          setComponentData([]);
          setStateData([]);
          setPlannedData('-');
          setAuditedData('-');
          setCompletedData('-');
          setTCardData({});
          setStatusFlag(false);
     };

     const handleChange = (event, tab) => {

          setValue(tab);

          if (financialYear !== '' && !reportsFlag && tab === 0) 
          {
               reportsAPI();
          }

          if (financialYear !== '' && !graphsFlag && tab === 1) 
          {
               graphsAPI();
          }

          if (financialYear !== '' && !componentGraphsFlag && tab === 2) {
               componentGraphsAPI();
          }

          if (financialYear !== '' && !statusFlag && tab === 3)
          {
               statusAPI();
          }

          if (fyColumnsCat.length === 0 && !fyComparisonFlag && tab === 5)
          {
               fyComparisonAPI();
          }
          
     };

     useEffect(() => {
          if (financialYear !== '' && _.size(fyFull) > 0) {
               // console.log(fyFull);
               let name = fyFull[financialYear];
               // console.log(name);
               setFyName(name);
               
               let event = '';
               handleChange(event, value);
          }
     }, [financialYear, fyFull]);
     
     useEffect(() => {

          AuthMethods['common'].getFinancialYear().then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = financialYearData(data['data']);
                    // console.log(arr);
                    setFYDropdown(arr[0]);
                    setFinancialYear(arr[1]);
                    setFyFull(arr[2]);
               }
          });

     }, []);

     return (

          <React.Fragment>

               <Box mb={3}>
                    <Typography
                         sx={{ 
                              color: theme => theme.palette.text.primary,
                              fontSize: '21px',
                              fontWeight: 'bold'
                              }}
                         >
                              Dashboard
                    </Typography>
               </Box>
               <Box sx={{ p: 1, width: 200 }} mb={2}>
                    <InputLabel
                         sx={{ 
                              color: theme => theme.palette.text.secondary,
                              fontSize: '13px',
                              fontWeight: 'bold',
                              pb: '3px'
                         }}
                    >
                         Financial Year
                    </InputLabel>
                    <Dropdown
                         labelkey="id"
                         labelname="name"
                         name="financialYear"
                         placeholder="Select Financial Year"
                         value={financialYear}
                         dropdown={fYDropdown}
                         onChange={e => {
                              handleFyChange(e);
                         }}
                         disabled={value === 5 ? true : false}
                    />
               </Box>

               <Box
                    sx={{
                         width: '100%',
                         boxShadow: '0px 0px 10px 1px #DBD8F1',
                         borderRadius: '7px',
                    }}
                    mb={2}
               >
                    <Tabs
                         value={value}
                         onChange={handleChange}
                         aria-label="icon label tabs example"
                         variant="scrollable"
                         scrollButtons={false}
                    >
                         <Tab icon={<BorderAllIcon />} label="Reports" {...a11yProps(0)} />
                         <Tab icon={<BarChartIcon />} label="Graphs" {...a11yProps(1)} />
                         <Tab icon={<AssessmentIcon />} label="Component Wise Graphs" {...a11yProps(2)} />
                         <Tab icon={<CheckCircleIcon />} label="Status" {...a11yProps(3)} />
                         <Tab icon={<ReportProblemIcon />} label="Overall Issues" {...a11yProps(4)} />
                         <Tab icon={<CompareIcon />} label="Financial Year Comparison" {...a11yProps(5)} />
                    </Tabs>
               </Box>

               <Box>
                    <TabPanel value={value} index={0}>
                         <ReportsTab 
                              loadingR={loadingR}
                              chartLoadingR={chartLoadingR}
                              cardLoadingR={cardLoadingR}
                              loading_1={loading_1}
                              financialYear={financialYear} 
                              initialScore={initialScore}
                              currentScore={currentScore}
                              stateInitialColumn={stateInitialColumn}
                              stateInitialData={stateInitialData}
                              stateCurrentColumn={stateCurrentColumn}
                              stateCurrentData={stateCurrentData}
                              componentInitialData={componentInitialData}
                              componentCurrentData={componentCurrentData}
                              initialObservation={initialObservation}
                              pendingObservation={pendingObservation}
                         />                    
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                         <GraphsTab 
                              loadingG={loadingG}
                              loadingCompG={loadingCompG}
                              financialYear={financialYear} 
                              stateInitial={stateInitial}
                              stateCurrent={stateCurrent}
                              componentInitial={componentInitial}
                              componentCurrent={componentCurrent}
                         />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                         <ComponentGraphTab 
                              loadingC={loadingC}
                              financialYear={financialYear} 
                              compOverall={compOverall}
                         />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                         <StatusTab 
                              loadingS={loadingS}
                              tableLoadingS={tableLoadingS}
                              financialYear={financialYear} 
                              fyName={fyName} 
                              planned={planned}
                              audited={audited}
                              completed={completed}
                              chartDataAuditPlansReport={chartDataAuditPlansReport}
                              tCardData={tCardData}
                              componentData={componentData}
                              stateData={stateData}
                         />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                         <IssuesTab financialYear={financialYear} fyName={fyName} />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                         <ComparisonTab 
                              loadingFC={loadingFC}
                              chartDataFinancialYear={chartDataFinancialYear}
                              fyColumns={fyColumns}
                              fyTableData={fyTableData}
                              chartDataFinancialYearIssue={chartDataFinancialYearIssue} 
                         />
                    </TabPanel>
               </Box>

          </React.Fragment>
     );
}