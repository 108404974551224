import axios from "../config";

const Category = {

     getAllCategory: (setLoading, data = {}) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/categories', data)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     addCategory: (categoryData, setCategoryTableDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/category/create', categoryData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setCategoryTableDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     getSingleCategory: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .get('/category/' + id)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     updateCategory: (id, categoryData, setCategoryTableDataChanged, setMessage, setLoading) => {
          if (Array.isArray(categoryData.creteria)) {
               categoryData.creteria = JSON.stringify(categoryData.creteria);
          }

          return new Promise((resolve, reject) => {
               axios
                    .put('/category/' + id, categoryData)
                    .then(({ data }) => {
                         console.log(data,categoryData);
                         if (data.status === 'success') {
                              resolve(data);
                              setCategoryTableDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error){
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     deleteCategory: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .delete('/category/' + id)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status ==='success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

};

export default Category;