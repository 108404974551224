import React, { useCallback, useState } from 'react';
import {useDropzone} from "react-dropzone";
import Typography from "@mui/material/Typography";
import {Box, Button, Card, ListItem} from "@mui/material";
import Div from '@jumbo/shared/Div/Div';
import CustomDialog from './CustomDialog';
import CustomButton from './CustomButton';
import CommonPreview from 'app/pages/AuditorSummary/CommonPreview';

const ImageDropzone = (props) => {

     let count = 30;

     const [openDialog, setOpenDialog] = useState(false);

     const onDrop = useCallback(acceptedFiles => {
          // console.log(acceptedFiles);
          acceptedFiles.forEach(file => {
               props.setImage(file);
               // props.setImageError('');
          });
          
     }, []);
     
     const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({
          onDrop,
          noClick: true,
          accept: "image/jpeg, image/png"
     });
     
     const files = acceptedFiles.map(file => (
          <ListItem key={file.path}>
               {file.path}
          </ListItem>
     ));

     const handlePreview = () => {
          setOpenDialog(true);
     };

    return (

          <React.Fragment>
          
               <Card sx={{ 
                    minHeight: '15.5vh',
                    boxShadow: '1px 1px 10px 0px #DBD8F1'
               }}>
                    <Box display="flex" flexDirection="column" alignItems="center" p="0px 5px 8px 5px">
                         <Div {...getRootProps()} className="dropzone" sx={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              mt: 1,
                              minHeight: 5,
                              borderRadius: 1,
                              border: '2px dashed #BBB',
                              bgcolor: theme => theme.palette.action.hover,
                              cursor: 'pointer',
                         }}
                         >
                              <input {...getInputProps()} />
                              <Button 
                                   onClick={() => {
                                        props.setImage(null);
                                        props.setDisable(true);
                                        open();
                                   }}
                                   sx={{
                                        width: '100%',
                                        padding: '6px',
                                        fontSize: '13px',
                                        m: 0,
                                   }}
                                   
                                   disabled={
                                        (props.reviewStatus ? 
                                             ((props.reviewStatus === 3) ? false : true)
                                        : props.auditorStatus ? 
                                             ((props.auditorStatus === 2) ? false : true)
                                        : props.auditeeStatus ?
                                             ((props.auditeeStatus === 4 || props.auditeeStatus === 6 || props.auditeeStatus === 8) ? false : true)
                                        : false)
                                   }
                              >
                                   Drag 'n' drop or click to upload image
                              </Button>
                         </Div>
                         <Typography sx={{ 
                              pt: '5px', 
                              fontSize: '13px',
                              fontWeight: 'bold',
                              color: theme => theme.palette.text.secondary
                         }}>
                              Uploaded Image 
                         </Typography>
                         <Box sx={{
                              width: '100%',
                              height: (files.length > 0) ? '100%' : '5vh',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                         }}>
                              {props.image && (
                                   <React.Fragment>
                                        <Box sx={{
                                             overflowWrap: 'anywhere'
                                        }}>
                                             <dfn title={files[0]['key']} style={{ fontStyle: 'normal' }}>
                                                  {files[0]['key'].slice(0, count) + (files[0]['key'].length > count ? '...' : '')}
                                             </dfn>
                                        </Box>
                                        <Box>
                                             <CustomButton
                                                  variant="contained"
                                                  color="grey"
                                                  icon="getPreviewIcon" 
                                                  onClick={handlePreview}
                                             >
                                                  Preview
                                             </CustomButton>
                                        </Box>
                                   </React.Fragment>
                              )}
                         </Box>
                    </Box>
               </Card>

               <CustomDialog openDialog={openDialog} maxWidth="lg">
                    <CommonPreview setOpenDialog={setOpenDialog} image={props.image} />
               </CustomDialog>

          </React.Fragment>
    );
};

export default ImageDropzone;