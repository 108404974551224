import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MultiSelect from "@custom/Components/MultiSelect";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Visibility } from "@mui/icons-material";
import CustomButton from "@custom/Components/CustomButton";
import AuthMethods from "app/services";
import { useSnackbar } from "notistack";
import ErrorMessage from "@custom/Components/ErrorMessage";
import { isValidEmail } from "@jumbo/utils";
import PageLoader from "@custom/Components/PageLoader";

const useStyles = makeStyles({

  required: {
    color: '#E73145',
  },

});

export default function AddEditUser(props) {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const userId = props.userId;

  const [selectedRole, setSelectedRole] = useState([]);
  const [rolesDropdown, setRolesDropdown] = useState([]);
  const [errorRole, setErrorRole] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    empId: '',
    designation: '',
    password: '',
    confirmPassword: '',
  });

  const [errorFormData, setErrorFormData] = useState({
    name: '',
    email: '',
    role: '',
    password: '',
    confirmPassword: '',
  });

  const handleShowPassword = () => setShowPassword((show) => !show);
  const handleShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleDialogClose = () => {
    props.setOpenDialog(false);
  };

  const handleChange = (e, required) => {
    const { name, value } = e.target;
    setFormData(prev => {
      return { ...prev, [name]: value };
    });
    if (required) {
      setErrorFormData(prev => {
        return { ...prev, [name]: '' }
      });
    }
  };

  const saveUserForm = () => {

    let finalRole = [];
    rolesDropdown.forEach(item => {
      if (selectedRole.includes(item.value)) {
        finalRole.push(item.id);
      }
    });
    // console.log("finalRole", finalRole);

    let userData = {
      name: formData['name'],
      email: formData['email'],
      employee_code: formData['empId'],
      designation: formData['designation'],
      role_id: finalRole,
      password: formData['password'],
      confirm_password: formData['confirmPassword'],
    }
    // console.log(userData);

    setLoading(true); 
    if (props.action === 'edit') {
      if (!formData['password'] && !formData['confirmPassword']) {
        userData = {
          name: formData['name'],
          email: formData['email'],
          employee_code: formData['empId'],
          designation: formData['designation'],
          role_id: finalRole,
        }
      }
      AuthMethods['users'].updateUser(userId, userData, props.setTableDataChanged, setMessage, setLoading);
    } else {
      AuthMethods['users'].addUser(userData, props.setTableDataChanged, setMessage, setLoading);
    }

  };

  const checkFormData = name => {

    if (name === 'email') {
      if (!formData[name]) {
        setErrorFormData(prev => {
          return {...prev, [name]: 'This field is required'}
        });
        return false;
      } else if (!isValidEmail(formData[name])) {
        setErrorFormData(prev => {
          return {...prev, [name]: 'Enter a valid email address'};
        });
        return false;
      }
    }

    if (name === 'errorRole') {
      if (selectedRole.length === 0) {
        setErrorRole('This field is required');
        return false;
      } else {
        setErrorRole('');
        return true;
      }
    }

    if (name === 'confirmPassword') {
      if (!formData[name]) {
        setErrorFormData(prev => {
          return { ...prev, [name]: 'This field is required' };
        });
        return false;
      } else if (formData['password'] !== formData[name]) {
        setErrorFormData(prev => {
          return { ...prev, [name]: 'Passwords do not match' };
        });
        return false;
      }
    }

    if (!formData[name]) {
      setErrorFormData(prev => {
        return { ...prev, [name]: 'This field is required' };
      });
      return false;
    }    

    return true;

  };

  const saveForm = (e) => {
    e.preventDefault();

    let isValid = true;

    if (props.action === 'add') {
      isValid = checkFormData('name') && isValid;
      isValid = checkFormData('email') && isValid;
      isValid = checkFormData('errorRole') && isValid;
      isValid = checkFormData('password') && isValid;
      isValid = checkFormData('confirmPassword') && isValid;  
    }

    if (props.action === 'edit') {
      isValid = checkFormData('name') && isValid;
      isValid = checkFormData('email') && isValid;
      isValid = checkFormData('errorRole') && isValid;
      if (formData['password'] || formData['confirmPassword']) {
        isValid = checkFormData('password') && isValid;
        isValid = checkFormData('confirmPassword') && isValid;
      }
    }
    
    // console.log(isValid);
    if (isValid) {
      saveUserForm();
    }

  };

  useEffect(() => {
    if (props.action === 'edit') {
      setLoading(true);
      AuthMethods['users'].getSingleUser(userId).then(data => {
        // console.log(data);
        setLoading(false);
        if (Object.entries(data['data']).length > 0) {
          setFormData({
            name: data['data']['name'],
            email: data['data']['email'],
            empId: data['data']['employee_code'],
            designation: data['data']['designation'],
            password: '',
            confirmPassword: ''
          });

          let arr = [];
          data['data']['role'].forEach(item => {
            arr.push(item.name);
          });
          setSelectedRole(arr);
        }
      });
    }

  }, [userId]);

  useEffect(() => {
    if (message !== '') {

      if (props.action === 'add') {

        if (message === 'Success') {
          props.setOpenDialog(false);
          enqueueSnackbar('User added successfully', {
            variant: 'success'
          });
        } else {
          enqueueSnackbar(message, {
            variant: 'error'
          });
        }
        
        setMessage('');
      } 
      else { 
        if (message === 'Success') { 
          props.setOpenDialog(false);
          enqueueSnackbar('User updated successfully', {
            variant: 'success'
          });
        } else {
          enqueueSnackbar(message, {
            variant: 'error'
          });
        }

        setMessage('');
      }
    }

  }, [message]);

  useEffect(() => {
    if (selectedRole.length > 0) {
      setErrorRole('');
    }
  }, [selectedRole]);

  useEffect(() => {
    setLoading(true);
    AuthMethods['roles'].getAllRoles(setLoading).then(data => {
      // console.log(data);
      let arr = [];
      if (data['data'].length > 0) {
        data['data'].forEach(item => {
          let obj = {
            id: item.id,
            value: item.name
          }
          arr.push(obj);
        });
        // console.log(arr);
        setRolesDropdown(arr);
      }
    });
  }, []);

  return (
    <React.Fragment>
      {loading &&
        <PageLoader title="Loading..."/>
      }
      <DialogTitle sx={{ 
        m: 0, 
        p: 2.3, 
        color: '#505F79', 
        fontSize: '19px', 
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme => theme.palette.primary.main,
        }}
      >
        {props.action === 'edit' ? 'Edit User' : 'Add New User'}
      </DialogTitle>
      <form>
        <DialogContent dividers>
          <Grid container spacing={2} mb={2}>

            <Grid item xs={12} sm={6}>
              <InputLabel
                sx={{ color: theme => theme.palette.text.secondary,
                  fontSize: '13px',
                  fontWeight: 'bold',
                  pb: '3px'
                }}
              >
                Name <span className={classes.required}>*</span>
              </InputLabel>
                <TextField
                  name="name"
                  type="text"
                  fullWidth
                  size="small"
                  value={formData['name']}
                  onChange={e => handleChange(e, true)}
                />
                <ErrorMessage>{errorFormData['name']}</ErrorMessage>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel
                sx={{ color: theme => theme.palette.text.secondary,
                  fontSize: '13px',
                  fontWeight: 'bold',
                  pb: '3px'
                }}
              >
                Email Address <span className={classes.required}>*</span>
              </InputLabel>
              <TextField
                name="email"
                type="email"
                autoComplete="off"
                fullWidth
                size="small"
                value={formData['email']}
                onChange={e => handleChange(e, true)}
              />
              <ErrorMessage>{errorFormData['email']}</ErrorMessage>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel
                sx={{ color: theme => theme.palette.text.secondary,
                  fontSize: '13px',
                  fontWeight: 'bold',
                  pb: '3px'
                }}
              >
                Employee Id
              </InputLabel>
              <TextField
                name="empId"
                type="text"
                fullWidth
                size="small"
                value={formData['empId']}
                onChange={e => handleChange(e, false)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel
                sx={{ color: theme => theme.palette.text.secondary,
                  fontSize: '13px',
                  fontWeight: 'bold',
                  pb: '3px'
                }}
              >
                Designation
              </InputLabel>
              <TextField
                name="designation"
                type="text"
                fullWidth
                size="small"
                value={formData['designation']}
                onChange={e => handleChange(e, false)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <InputLabel
                sx={{ color: theme => theme.palette.text.secondary,
                  fontSize: '13px',
                  fontWeight: 'bold',
                  pb: '3px'
                }}
              >
                Roles <span className={classes.required}>*</span>
              </InputLabel>
              <MultiSelect
                placeholder="Select Roles"
                selected={selectedRole}
                setSelected={setSelectedRole}
                dropdown={rolesDropdown}
                roles={true}
              />
              <ErrorMessage>{errorRole}</ErrorMessage>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel
                sx={{ color: theme => theme.palette.text.secondary,
                  fontSize: '13px',
                  fontWeight: 'bold',
                  pb: '3px'
                }}
              >
                {props.action === 'add' ? (
                  <React.Fragment>
                    Password <span className={classes.required}>*</span>
                  </React.Fragment>
                ) : 'Password'
                }
              </InputLabel>
              <FormControl variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  autoComplete="new-password"
                  value={formData['password']}
                  onChange={e => handleChange(e, true)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  size="small"
                />
            </FormControl>
            <ErrorMessage>{errorFormData['password']}</ErrorMessage>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel
                sx={{ color: theme => theme.palette.text.secondary,
                  fontSize: '13px',
                  fontWeight: 'bold',
                  pb: '3px'
                }}
              >
                {props.action === 'add' ? (
                  <React.Fragment>
                    Confirm Password <span className={classes.required}>*</span>
                  </React.Fragment>
                ) : 'Confirm Password' 
                }
              </InputLabel>
              <FormControl variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-confirm-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  value={formData['confirmPassword']}
                  onChange={e => handleChange(e, true)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  size="small"
                />
            </FormControl>
            <ErrorMessage>{errorFormData['confirmPassword']}</ErrorMessage>
            </Grid>            

          </Grid>
        </DialogContent>

        <DialogActions>
          <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                    Cancel
              </CustomButton>
              <CustomButton 
                variant="contained" 
                color="primary" 
                icon="" 
                onClick={saveForm}
              >
                {props.action === 'add' ? 'Add' : 'Update' }
              </CustomButton>
          </Box>
        </DialogActions>
      
      </form>
    </React.Fragment>
  );
}
