import axios from "../config";

const Roles = {
    
     getAllRoles: (setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .get('/roles')
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          })
     },

     getUsersByRole: (roleName, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/user/roles', roleName)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },
     
};

export default Roles;
