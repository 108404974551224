import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles((theme) =>
     createStyles({
          messageRow: {
               display: "flex"
          },
          messageRowRight: {
               display: "flex",
               justifyContent: "flex-end"
          },
          messageLeft: {
               position: "relative",
               minWidth: '136px',
               maxWidth: '90%',
               marginLeft: "20px",
               marginBottom: "10px",
               padding: "10px",
               backgroundColor: "#FFFFFF",
               textAlign: "left",
               font: "400 .9em 'Open Sans', sans-serif",
               border: "1px solid #86E0DB",
               borderRadius: "10px",
               "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "15px solid #FFFFFF",
                    borderLeft: "16px solid transparent",
                    borderRight: "16px solid transparent",
                    top: "0",
                    left: "-15px"
               },
               "&:before": {
                    content: "''",
                    position: "absolute",
                    width: "0",
                    height: "0",
                    borderTop: "16px solid #86E0DB",
                    borderLeft: "17px solid transparent",
                    borderRight: "10px solid transparent",
                    top: "-1px",
                    left: "-17px"
               }
          },
          messageRight: {
               position: "relative",
               minWidth: '136px',
               maxWidth: '90%',
               marginRight: "20px",
               marginBottom: "10px",
               padding: "10px",
               backgroundColor: "#86E0DB",
               textAlign: "left",
               font: "400 .9em 'Open Sans', sans-serif",
               border: "1px solid #86E0DB",
               borderRadius: "10px",
               "&:after": {
               content: "''",
               position: "absolute",
               width: "0",
               height: "0",
               borderTop: "15px solid #86E0DB",
               borderLeft: "15px solid transparent",
               borderRight: "15px solid transparent",
               top: "0",
               right: "-15px"
               },
               "&:before": {
               content: "''",
               position: "absolute",
               width: "0",
               height: "0",
               borderTop: "17px solid #86E0DB",
               borderLeft: "16px solid transparent",
               borderRight: "16px solid transparent",
               top: "-1px",
               right: "-17px"
               }
          },

          messageContent: {
               paddingBottom: '10px',
          },
          messageTimeStampRight: {
               position: "absolute",
               whiteSpace: 'nowrap',
               fontSize: ".85em",
               fontWeight: "300",
               bottom: "1px",
               right: "6px"
          },

          imageColor: {
               color: '#FFFFFF',
               backgroundColor: '#9470e8',
               width: theme.spacing(4),
               height: theme.spacing(4)
          },
          avatarNothing: {
               color: "transparent",
               backgroundColor: "transparent",
               width: theme.spacing(4),
               height: theme.spacing(4)
          },
          displayName: {
               fontSize: '13px',
               fontWeight: 'bold',
               marginLeft: "20px"
          }
     })
);

// avatar
export const MessageLeft = (props) => {
     const message = props.message ? props.message : "no message";
     const timestamp = props.timestamp ? props.timestamp : "";
     const photoURL = props.photoURL ? props.photoURL : "";
     const displayName = props.displayName ? props.displayName : "";
     const classes = useStyles();
     return (
     <React.Fragment>
          {props.noMessage ? (
               <Box sx={{
                    width: '100%',
                    height: '55vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
               }}>
                    <Typography>{props.noMessage}</Typography>
               </Box>
          ) : (
               <div className={classes.messageRow}>
                    <Avatar
                         alt={displayName}
                         className={classes.imageColor}
                         src={photoURL}
                    ></Avatar>
                    <div>
                         <div className={classes.displayName}>{displayName}</div>
                         <div className={classes.messageLeft}>
                              <div>
                                   <p className={classes.messageContent}>{message}</p>
                              </div>
                              <div className={classes.messageTimeStampRight}>{timestamp}</div>
                         </div>
                    </div>
               </div>
          )}
     </React.Fragment>
     );
};

// avatar
export const MessageRight = (props) => {
     const classes = useStyles();
     const message = props.message ? props.message : "no message";
     const timestamp = props.timestamp ? props.timestamp : "";
     return (
          <div className={classes.messageRowRight}>
               <div className={classes.messageRight}>
               <p className={classes.messageContent}>{message}</p>
               <div className={classes.messageTimeStampRight}>{timestamp}</div>
               </div>
          </div>
     );
};