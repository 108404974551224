import axios from "../config";

const AuditorChecklist = {

     getAuditorSiteDetails: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/get-auditor-site-details',
                         {'audit_plan_data_id' : id}
                    )
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     getAuditorChecklist: (auditChecklistData, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/get-auditor-checklist-details', auditChecklistData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     getAuditorChecklistDetails: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/get-auditor-checklist-sample-data', {
                         'audit_sample_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     saveAuditorChecklistDetails: (checklistData, setTableDataChanged, setCardDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/save-auditor-checklist-sample-data', checklistData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setTableDataChanged(1);
                              setCardDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage('Error');
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     submitAuditorChecklist: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/submit-auditor-checklist', {
                         'audit_plan_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

};

export default AuditorChecklist;