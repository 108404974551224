import React, { useEffect, useState } from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "@custom/Components/CustomButton";
import ErrorMessage from "@custom/Components/ErrorMessage";
import Dropdown from '@custom/Components/Dropdown';
import FileDropzone from '@custom/Components/FileDropzone';
import AuthMethods from 'app/services';
import { isNull } from 'lodash';
import { useSnackbar } from 'notistack';
import PageLoader from '@custom/Components/PageLoader';

const useStyles = makeStyles({
     required: {
          color: '#E73145'
     },
});

export default function AddEquipment(props) {

     const classes = useStyles();
     const { enqueueSnackbar } = useSnackbar();

     const [state, setState] = useState('');
     const [stateDropdown, setStateDropdown] = useState([]);
     const [stateError, setStateError] = useState('');
     const [site, setSite] = useState('');
     const [siteDropdown, setSiteDropdown] = useState([]);
     const [siteError, setSiteError] = useState('');
     const [file, setFile] = useState(null);
     const [fileError, setFileError] = useState('Accepted file format: CSV');
     const [loading, setLoading] = useState(false);
     const [message, setMessage] = useState('');

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const saveEquipment = () => {

          let data = new FormData();
          
          data.append('site_id', site);
          data.append('file', file);

          setLoading(true);
          AuthMethods['equipment'].uploadExcel(data, setMessage, setLoading);
     }

     const uploadEquipment = (e) => {
          e.preventDefault();

          let isValid = true;

          if (!state) {
               setStateError('This field is required');
               isValid = false;
          }

          if (!site) {
               setSiteError('This field is required');
               isValid = false;
          }

          if (isNull(file)) {
               setFileError('This field is required');
               isValid = false;
          }

          if (isValid) {
               saveEquipment();
          }
     };

     useEffect(() => {

          if (message !== '') {
               if (message === 'Success') {
                    props.setOpenDialog(false);
                    enqueueSnackbar('Equipment added successfully', {
                         variant: 'success'
                    });
               } else {
                    enqueueSnackbar(message, {
                         variant: 'error'
                    });
               }
               setMessage('');
          }

     }, [message]);

     useEffect(() => {
          if (fileError === '') {
               setFileError('Accepted file format: CSV');
          }
     }, [fileError]);

     useEffect(() => {
          if (state) {
               let siteData = {
                    'state_id': state,
               };
               AuthMethods['site'].getSitesByStateId(siteData).then(data => {
                    if (data['data'].length > 0) {
                         let arr = [];
                         data['data'].forEach(item => {
                              let obj = {
                                   id: item.id,
                                   name: item.name
                              }
                              arr.push(obj);
                         });
                         setSiteDropdown(arr);
                    }
               });
          }

     }, [state]);

     useEffect(() => {

          setLoading(true);

          AuthMethods['state'].getAllStates(setLoading).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = [];
                    data['data'].forEach(item => {
                         let obj = {
                              id: item.id, 
                              name: item.name
                         }
                         arr.push(obj);
                    });
                    setStateDropdown(arr);
               }
          });

     }, []);

     return (
          <React.Fragment>

          {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Import Site Equipment
               </DialogTitle>
               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2} mb={2}>

                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        State <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select State"
                                        name="state"
                                        value={state}
                                        dropdown={stateDropdown}
                                        onChange={e => {
                                             setState(e.target.value);
                                             setStateError('');
                                             setSite('');
                                        }}
                                        disabled={false}
                                   />
                                   <ErrorMessage>{stateError}</ErrorMessage>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Site <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select Site"
                                        name="site"
                                        value={site}
                                        dropdown={siteDropdown}
                                        onChange={e => {
                                             setSite(e.target.value);
                                             setSiteError('');
                                        }}
                                        disabled={false}
                                   />
                                   <ErrorMessage>{siteError}</ErrorMessage>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '5px'
                                        }}
                                   >
                                        Upload CSV File <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Stack>
                                        <FileDropzone
                                             file={file}
                                             setFile={setFile}
                                             setFileError={setFileError}
                                        />
                                   </Stack>
                                   <ErrorMessage>{fileError}</ErrorMessage>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Download Format
                                   </InputLabel>
                                   <Typography sx={{ height: '1vh' }}>
                                        <a href='/images/dashboard/import_equipment.csv'>Equipment File</a>
                                   </Typography>
                              </Grid>
                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton 
                                   variant="contained" 
                                   color="primary" 
                                   icon="" 
                                   onClick={uploadEquipment}
                              >
                                   Upload
                              </CustomButton>
                         </Box>
                    </DialogActions>
               </form>
          </React.Fragment>
     );
}