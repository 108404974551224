import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

export default function Dropdown(props) {
     // console.log(props);

     return (
          <FormControl fullWidth>
               <Select
                    name={props.name}
                    value={props.value}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={props.onChange}
                    disabled={props.disabled}
                    size="small"
                    {...props}
               >
                    <MenuItem value="">
                         {props.placeholder}
                    </MenuItem>
                    {props.dropdown.map((item, index) => {
                         return (
                              <MenuItem key={index} value={item.id === '' ? item.name : item.id}>{item.name}</MenuItem>
                         );
                    })}
               </Select>
          </FormControl>  
     );
}