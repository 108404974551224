import React, { useEffect } from 'react';
import useJumboApp from "@jumbo/hooks/useJumboApp";
import {config} from "../../../app/config/main";
import Error404 from 'app/pages/extra-pages/Error404/Error404';
import { Navigate, useLocation } from 'react-router-dom';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import _, { isNull } from 'lodash';

const Page = ({component, layout, ...restProps}) => {

    const PageComponent = component;

    const {activeLayout, setActiveLayout} = useJumboApp();
    const {authUser} = useJumboAuth();
    let location = useLocation();

    let accessObj = {
        '/dashboard': ['admin', 'approver_1', 'approver_2', 'reviewer_1', 'reviewer_2', 'cluster_in_charge', 'management'],
        '/dashboard-checklist': ['admin', 'approver_1', 'approver_2', 'reviewer_1', 'reviewer_2', 'cluster_in_charge', 'management'],
        '/dashboard-reports': ['admin', 'approver_1', 'approver_2', 'reviewer_1', 'reviewer_2', 'cluster_in_charge', 'management'],
        '/admin/users': ['admin'],
        '/admin/state': ['admin'],
        '/admin/site': ['admin'],
        '/admin/equipment': ['admin'],
        '/admin/checklist': ['admin'],
        '/audit-plan': ['admin', 'approver_1', 'approver_2'],
        '/auditor-summary': ['admin', 'auditor'],
        '/auditor-checklist': ['admin', 'auditor'],
        '/auditee-summary': ['admin', 'auditee'],
        '/auditee-checklist': ['admin', 'auditee'],
        '/reviewer-summary': ['admin', 'reviewer_1', 'reviewer_2'],
        '/auditor-review-checklist': ['admin', 'reviewer_1', 'reviewer_2'],
        '/review-checklist': ['admin', 'reviewer_1', 'reviewer_2'],
        '/previous-year-audit-data': ['admin', 'approver_1', 'approver_2', 'management'],
    };

    useEffect(() => {
        if (layout !== activeLayout) {
            setActiveLayout(layout);
        }
    }, [layout]);

    if (!isNull(authUser)) {

        let roles = localStorage.getItem('roles');
        if (!isNull(roles)) {
            // console.log(roles);
            let rolesArr = roles.split(',');

            if (rolesArr.length === 0) {
                return (
                    <Navigate to="/login" />
                )
            }

            if (rolesArr.includes('1')) {
                return (
                    <PageComponent {...restProps} />
                );
            }
    
            // if (location.pathname !== '/dashboard') {
            //     return (
            //         <Error404 />
            //     )
            // }

            let newArr = _.intersection(accessObj[location.pathname], rolesArr);
            // console.log(newArr);

            if (rolesArr.includes('auditee') && newArr.length === 0) {
                return <Navigate to="/auditee-summary" />
            }
            if (rolesArr.includes('auditor') && newArr.length === 0) {
                return <Navigate to="/auditor-summary" />
            }
            
            if (newArr.length > 0) {
                return (
                    <PageComponent {...restProps} />
                );
            } 
            
            if (!accessObj[location.pathname] && location.pathname !== '/login') {
                return <Error404 noAccess={false} />;
            } else {
                return <Error404 noAccess={true} />;
            }
            
            // if (newArr.length === 0) {
            //     return <Error404 />;
            // }

        }

        return (
            <PageComponent {...restProps} />
        );
          
    }

    return null;

};

Page.defaultProps = {
    layout: config.defaultLayout
};

export default Page;