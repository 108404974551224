import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Checkbox, Chip, Grid, InputLabel, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Dropdown from '@custom/Components/Dropdown';
import CustomButton from '@custom/Components/CustomButton';
import MultiSelect from '@custom/Components/MultiSelect';
import { useJumboTheme } from '@jumbo/hooks';
import { makeStyles } from '@mui/styles';
import ErrorMessage from '@custom/Components/ErrorMessage';
import SolarTable from '@custom/Components/SolarTable';
import _, { isNull, isUndefined } from 'lodash';
import AuthMethods from 'app/services';
import CustomDialog from '@custom/Components/CustomDialog';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CustomTooltip from '@custom/Components/CustomTooltip';
import ARChecklistDetails from './ARChecklistDetails';
import { encryptDecrypt, getDays, getSeverityIds } from '@custom/Functions/helperFunction';
import CommonCharts from '../AuditorSummary/CommonCharts';
import ReviewerSiteCard from './ReviewerSiteCard';

const useStyles = makeStyles({
     required: {
       color: '#E73145',
     },
});

const severityDropdown  = [
     {id: 0, value: 'Ok'},
     {id: 3, value: 'High'},
     {id: 2, value: 'Medium'},
     {id: 1, value: 'Low'},
];

const AuditorReviewChecklist = () => {

     const classes = useStyles();
     const {theme} = useJumboTheme();
     const location = useLocation();
     const MySwal = withReactContent(Swal);
     const navigate = useNavigate();
     const titleArr = ['Severity - Ok', 'Severity - Low', 'Severity - Medium', 'Severity - High'];
     const colorArr = ['primary', 'blue', 'warning', 'error'];
     const iconArr = ['getCheckCircleOutlineIcon', 'getBrightnessLowIcon', 'getBrightnessMediumIcon', 'getBrightnessHighIcon'];

     const [loading, setLoading] = useState(false);
     const [checklistCategory, setChecklistCategory] = useState('');
     const [checklistCategoryDropdown, setChecklistCategoryDropdown] = useState([]);
     const [showTable, setShowTable] = useState(false);
     const [columns, setColumns] = useState([]);
     const [tableData, setTableData] = useState([]);
     const [openDialog, setOpenDialog] = useState(false);
     const [checklistError, setChecklistError] = useState('');
     const [auditSampleDataId, setAuditSampleDataId] = useState('');
     const [selectedSeverity, setSelectedSeverity] = useState(['Ok', 'High', 'Medium', 'Low']);
     const [severityIds, setSeverityIds] = useState([]);
     const [severityError, setSeverityError] = useState('');
     const [equipmentChecklistName, setEquipmentChecklistName] = useState([]);
     const [reviewStatus, setReviewStatus] = useState('');
     const [tableDataChanged, setTableDataChanged] = useState(0);
     const [auditPlanDataId, setAuditPlanDataId] = useState('');
     const [cardDataChanged, setCardDataChanged] = useState(false);
     const [chartDataChanged, setChartDataChanged] = useState(0);
     const [checked, setChecked] = useState(false);
     const [auditorSubDate, setAuditorSubDate] = useState(null);

     const handleDetails = (equipment, id, rowData) => {
          setOpenDialog(true);
          setAuditSampleDataId(id);
          setEquipmentChecklistName([equipment, rowData['checklist']]);
     };
     
     const submitChecklist = () => {
          MySwal.fire({
               title: 'Are you sure to submit this checklist?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Yes, submit!',
               cancelButtonText: 'No, cancel!',
               reverseButtons: true,
          }).then(result => {
               if (result.value) {
                    setLoading(true);
                    AuthMethods['reviewer'].submitARChecklist(parseInt(auditPlanDataId))
                         .then(data => {
                              // console.log(data);
                              Swal.fire('Submited!', 'Checklist has been submitted', 'success');
                              setLoading(false);
                              navigate('/reviewer-summary');
                         })
                         .catch(error => {
                              Swal.fire('Error occured', '');
                              setLoading(false);
                         });
               } else if (
                    result.dismiss === Swal.DismissReason.cancel
               ) {
                    Swal.fire('Cancelled!', 'Checklist has not been submitted', 'error');
               }
          });
     };

     const handleView = () => {
          
          let isValid = true;

          if (!checklistCategory) {
               setChecklistError('This field is required');
               isValid = false;
          }

          if (selectedSeverity.length === 0) {
               setSeverityError('This field is required');
               isValid = false;
          }

          if (isValid) {
               setShowTable(true);
               setChartDataChanged(1);
          }

          if (checklistCategory !== '' && severityIds.length > 0) {

               let reviewerChecklistData = {
                    'audit_plan_data_id': parseInt(auditPlanDataId),
                    'audit_checklist_category_id': checklistCategory,
                    'severity': severityIds,
                    'rejected': checked,
               };

               setLoading(true);
               setTableData([]);
               AuthMethods['reviewer'].getARChecklist(reviewerChecklistData, setLoading).then(data => {
                    // console.log(data);

                    if (data['audited_data'].length > 0) {
                         let columnsArr = [...new Set(data['audited_data'].map(item => item['Equipment_name']))];
                         // console.log(columnsArr);

                         let arr = [];
                         columnsArr.forEach(item => {
                              let columnsObj = {
                                   title: item,
                                   field: item,
                                   filtering: false,
                                   render: rowData => {
                                        // console.log(rowData[item]);
                                        let status = '';

                                        if (reviewStatus > 3) {
                                             let date = !isNull(rowData['date']) ? rowData['date'].split('-') : '';
                                             let auditeeSubDate = date ? (date[1] + '/' + date[2] + '/' + date[0]) : '';
     
                                             let diff = 0;
                                             if (auditorSubDate !== '') {
                                                  diff = getDays(auditorSubDate, auditeeSubDate);
                                             }
     
                                             if (!isNull(rowData[item + ' - severity'])) {
                                                  if (rowData[item + ' - severity'] === 1) {
                                                       if (diff > 30) {
                                                            status = 'Delayed!'
                                                       }
                                                  }
                                                  if (rowData[item + ' - severity'] === 2) {
                                                       if (diff > 15) {
                                                            status = 'Delayed!'
                                                       }
                                                  }
                                                  if (rowData[item + ' - severity'] === 3) {
                                                       if (diff > 7) {
                                                            status = 'Delayed!'
                                                       }
                                                  }
                                             }
                                        }

                                        return (
                                             isUndefined(rowData[item]) ? (
                                                  <Typography></Typography>
                                             ) : (
                                                  <CustomTooltip
                                                       title={titleArr[!isNull(rowData[item + ' - severity']) ? rowData[item + ' - severity'] : 0]}
                                                  >
                                                       <CustomButton
                                                            variant="outlined"
                                                            color={colorArr[!isNull(rowData[item + ' - severity']) ? rowData[item + ' - severity'] : 0]}
                                                            icon={iconArr[!isNull(rowData[item + ' - severity']) ? rowData[item + ' - severity'] : 0]}
                                                            onClick={e => {
                                                                 handleDetails(item, rowData[item], rowData);
                                                            }}
                                                       >
                                                            Details
                                                       </CustomButton>
                                                       {status !== '' ? (
                                                            <Box sx={{ textAlign: 'center', pt: '2px' }}>
                                                                 <Chip label={status} color="error" size="small" />
                                                            </Box>
                                                       ) : (
                                                            <Box sx={{ height: '20px' }} />
                                                       )}
                                                  </CustomTooltip>
                                             )
                                        );
                                   }
                              }
                              arr.push(columnsObj);
                         });

                         setColumns([
                              {
                                   title: "ID", 
                                   field: "id",
                                   filtering: false, 
                                   hidden: true, 
                                   hiddenByColumnsButton: true 
                              },
                              { 
                                   title: 'Checklist Items / Samples', 
                                   field: 'checklist', 
                                   filtering: false, 
                              },
                              ...arr
                         ]);

                         let checklistObj = {};

                         data['audited_data'].forEach(item => {
                              let icrInverter = item['Equipment_name'];
                              if (isUndefined(checklistObj[item['audit_checklist_item_id']])) {
                                   checklistObj[item['audit_checklist_item_id']] = {};
                              }
                              if (isUndefined(checklistObj[item['audit_checklist_item_id']][icrInverter])) {
                                   checklistObj[item['audit_checklist_item_id']][icrInverter] = {};
                              }
                              checklistObj[item['audit_checklist_item_id']][icrInverter] = item;
                         });

                         // console.log(checklistObj);

                         let dataArr = [];
                         for(let ck in checklistObj) {
                              let dataObj = {
                                   id: parseInt(ck)
                              };
                              for (let eq in checklistObj[ck]) {
                                   dataObj[eq] = checklistObj[ck][eq]['id'];
                                   dataObj[eq + ' - severity'] = checklistObj[ck][eq]['severity'];
                                   dataObj['checklist'] = checklistObj[ck][eq]['Checklist_Item_Name'];
                                   dataObj['date'] = checklistObj[ck][eq]['submitted_date_auditee'];
                              }
                              dataArr.push(dataObj);
                         }
                         // console.log(dataArr);
                         setTableData(dataArr);
                    }
               });
          }
     };

     useEffect(() => {
          if (tableDataChanged === 1) {
               handleView();
               setTableDataChanged(0);
          }
     }, [tableDataChanged]);

     useEffect(() => {

          if (selectedSeverity.length > 0) {
               let ids = getSeverityIds(selectedSeverity);
               setSeverityIds(ids);
               setSeverityError('');
          }

     }, [selectedSeverity]);

     
     useEffect(() => {
          if (auditPlanDataId !== '') {
               AuthMethods['reviewer'].getARChecklistCategory(parseInt(auditPlanDataId)).then(data => {
                    // console.log(data);
                    if (data['audited_data'].length > 0) {
                         let arr = [];
                         data['audited_data'].forEach(item => {
                              let obj = {
                                   id: item['id'],
                                   name: _.size(item['category']) > 0 ? item['category']['name'] : '',
                              };
                              arr.push(obj);
                         });
                         setChecklistCategoryDropdown(arr);
                    }
               });
          }

     }, [auditPlanDataId]);

     useEffect(() => {

          // console.log(location.search);
          if (location.search.length > 0) {
               let id = encryptDecrypt(location.search);
               setAuditPlanDataId(id);
          }

     }, [location.search]);

     return (

          <React.Fragment>

               <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" mb={3}>
                    <Box>
                         <Typography
                              sx={{ 
                                   color: theme => theme.palette.text.primary,
                                   fontSize: '21px',
                                   fontWeight: 'bold'
                              }}
                              >
                                   Auditor Review Checklist
                         </Typography>
                    </Box>
                    <Box>
                         <Breadcrumbs 
                              aria-label="breadcrumb" 
                              color={theme.palette.grey.main}
                              sx={{
                                   fontSize: '15px',
                                   fontWeight: 'bold',
                              }}
                         >
                              <Link 
                                   to={"/reviewer-summary"} 
                                   style={{
                                        textDecoration: 'none',
                                   }}
                              >
                                   Reviewer Summary
                              </Link>
                              <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>Checklist</Typography>
                         </Breadcrumbs>
                    </Box>
               </Box>

               <Grid container spacing={2} mb={3}>
                    <Grid item xs={12} sm={3}>
                         <ReviewerSiteCard
                              auditPlanDataId={auditPlanDataId}
                              setReviewStatus={setReviewStatus}
                              cardDataChanged={cardDataChanged}
                              setAuditorSubDate={setAuditorSubDate}
                         />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                         <CommonCharts
                              auditPlanDataId={auditPlanDataId}
                              checklistCategory={checklistCategory}
                              chartDataChanged={chartDataChanged}
                              setChartDataChanged={setChartDataChanged}   
                         />
                    </Grid>
               </Grid>

               <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    <Box sx={{ p: 1, width: 320 }}>
                         <InputLabel
                              sx={{ 
                                   color: theme => theme.palette.text.secondary,
                                   fontSize: '13px',
                                   fontWeight: 'bold',
                                   pb: '3px'
                              }}
                         >
                              Checklist Category <span className={classes.required}>*</span>
                         </InputLabel>
                         <Dropdown
                              labelkey="id"
                              labelname="name"
                              name="checklistCategory"
                              placeholder="Select Checklist Category"
                              value={checklistCategory}
                              dropdown={checklistCategoryDropdown}
                              onChange={e => {
                                   setChecklistCategory(e.target.value);
                                   setChecklistError('');
                              }}
                              disabled={false}
                         />
                         <ErrorMessage>{checklistError}</ErrorMessage>
                    </Box>

                    <Box sx={{ p: 1, width: 320 }}>
                         <InputLabel
                              sx={{ 
                                   color: theme => theme.palette.text.secondary,
                                   fontSize: '13px',
                                   fontWeight: 'bold',
                                   pb: '3px'
                              }}
                         >
                              Severity <span className={classes.required}>*</span>
                         </InputLabel>
                         <MultiSelect
                              placeholder="Select Severity"
                              selected={selectedSeverity}
                              setSelected={setSelectedSeverity}
                              dropdown={severityDropdown}
                         />
                         <ErrorMessage>{severityError}</ErrorMessage>
                    </Box>

                    {(reviewStatus === 5 || reviewStatus === 6 || reviewStatus === 7 || reviewStatus === 8 || reviewStatus === 9) && (
                         <Box sx={{ 
                                   p: '16px 8px 16px 0',
                                   display: 'flex',
                                   alignItems: 'center',
                              }}
                         >
                              <Checkbox
                                   checked={checked}
                                   onChange={e => {
                                        setChecked(e.target.checked);
                                   }}
                                   inputProps={{ 'aria-label': 'controlled' }}
                              />
                              <InputLabel
                                   sx={{ 
                                        color: theme => theme.palette.text.secondary,
                                        fontSize: '15px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                   }}
                              >
                                   Show Rejected
                              </InputLabel>
                         </Box>
                    )}

                    <Box sx={{ p: 1, pb: 2.4 }}>
                         <CustomButton
                              variant="contained"
                              color="primary"
                              icon=""
                              onClick={handleView}
                         >
                              View
                         </CustomButton>
                    </Box>
               </Box>


               {showTable && (
                    <Box mt={1} mb={2}>
                         <SolarTable
                              title="Checklist - Equipment Table"
                              columns={columns}
                              data={tableData}
                              filtering={false}
                              loading={loading}
                              columnsButton={false}
                              spacing={true}
                              actions={[]}
                              detailPanel={[]}
                         />
                    </Box>
               )}

               {(showTable && reviewStatus !== '') ? (
                    <Box sx={{
                         display: 'flex',
                         justifyContent: 'flex-end'
                    }}>
                         <CustomButton
                              variant="contained"
                              color="primary"
                              icon=""
                              onClick={submitChecklist}
                              disabled={(reviewStatus === 3) ? false : true}
                         >
                              Review
                         </CustomButton>
                    </Box>
               ) : null}


               <CustomDialog openDialog={openDialog}>
                    <ARChecklistDetails
                         setOpenDialog={setOpenDialog} 
                         auditSampleDataId={auditSampleDataId}
                         equipmentChecklistName={equipmentChecklistName}
                         reviewStatus={reviewStatus}
                         setTableDataChanged={setTableDataChanged}
                         setCardDataChanged={setCardDataChanged}
                    />
               </CustomDialog>

          </React.Fragment>     
     );
}

export default AuditorReviewChecklist;