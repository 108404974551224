import React, { useEffect, useState } from 'react';
import SolarTable from "@custom/Components/SolarTable";
import { Box, DialogActions, DialogContent, DialogTitle, InputLabel, Typography } from "@mui/material";
import CustomButton from "@custom/Components/CustomButton";
import Dropdown from '@custom/Components/Dropdown';
import AuthMethods from 'app/services';
import _, { isNull, isUndefined } from 'lodash';
import EquipmentSelectionHeader from './EquipmentSelectionHeader';
import { makeStyles } from '@mui/styles';
import ErrorMessage from '@custom/Components/ErrorMessage';

const useStyles = makeStyles({
     required: {
          color: '#E73145'
     },
});

export default function EquipmentSelection(props) {

     const classes = useStyles();

     const [loading, setLoading] = useState(false);
     const [checklistCategory, setChecklistCategory] = useState('');
     const [checklistCategoryDropdown, setChecklistCategoryDropdown] = useState([]);
     const [totalSamples, setTotalSamples] = useState({});
     const [samplesValue, setSamplesValue] = useState('');
     const [showTable, setShowTable] = useState(false);
     const [tableData, setTableData] = useState([]);
     const [checkedRows, setCheckedRows] = useState([]);
     const [tableDataChanged, setTableDataChanged] = useState(false);
     const [tableError, setTableError] = useState('');
     const [checklistError, setChecklistError] = useState('');

     const columns = [
          { 
               title: "ID", 
               field: "id",
               filtering: false, 
               hidden: true, 
               hiddenByColumnsButton: true 
          },
          { 
               title: "Type",
               field: "type",
               filtering: false, 
          },     
          { 
               title: "Equipment Id",
               field: "equipmentName",
               filtering: false, 
          },
     ];

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const handleEqTable = () => {
          let isValid = true;

          if (!checklistCategory) {
               setChecklistError('This field is required');
               isValid = false;
          }

          if (isValid) {
               setShowTable(true);
               setSamplesValue(totalSamples[checklistCategory]);
          }

          if (checklistCategory !== '') {
               let equipmentData = {
                    audit_plan_data_id: props.auditPlanDataId,
                    audit_category_id: checklistCategory,
                    site_id: props.siteId,
               };

               setLoading(true);
               setTableData([]);
               AuthMethods['auditorEquipment'].getEquipmentSelection(equipmentData, setLoading).then(data => {
                    // console.log(data);
                    let bothArr = [];
                    if (data['equipments'].length > 0) {
                         data['equipments'].forEach((item, index) => {
                              if (!isNull(item['equipment_id'])) {
                                   let selectedObj = {
                                        index: index,
                                        id: item['id'],
                                        type: item['type'],
                                        equipmentName: item['name'],
                                        tableData: {
                                             checked: true
                                        }
                                   };
                                   // console.log(selectedObj);
                                   bothArr.push(selectedObj);
                              }
                         });
                         setCheckedRows([...bothArr]);
                         let length = bothArr.length;

                         data['equipments'].forEach((item, index) => {
                              if (isNull(item['equipment_id'])) {
                                   let unselectedObj = {
                                        index: index + length,
                                        id: item['id'],
                                        type: item['type'],
                                        equipmentName: item['name'],
                                        tableData: {
                                             checked: false
                                        }
                                   };
                                   bothArr.push(unselectedObj);
                              }
                         });
                    }
                    // console.log(bothArr);
                    setTableData(bothArr);
               });
          }
     };

     const handleCheckedRows = (rowData) => {
          // console.log(rowData);
          // console.log(tableData);

          let idArr = rowData.map(item => item.id);
          let newTableData = tableData.map(item => {
               // console.log(item);
               if (idArr.includes(item.id)) {
                    item['tableData']['checked'] = true;
               } else {
                    item['tableData']['checked'] = false;
               }
               return item;
          });
          // console.log(newTableData);
          setTableData(newTableData);

          setCheckedRows(rowData);
          setTableError('');
     };

     useEffect(() => {

          if (tableDataChanged) {
               handleEqTable();
               setTableDataChanged(prev => !prev);
          }
          
     }, [tableDataChanged]);

     useEffect(() => {
          AuthMethods['checklist'].getAuditorChecklistCategory(props.auditPlanDataId).then(data => {
               // console.log(data);
               if (data['audited_data'].length > 0) {
                    let arr = [];
                    let samplesObj = {};
                    data['audited_data'].forEach(item => {
                         let obj = {
                              id: item['id'],
                              name: _.size(item['category']) > 0 ? item['category']['name'] : '',
                         };
                         arr.push(obj);

                         if (isUndefined(samplesObj[item['id']])) {
                              samplesObj[item['id']] = '';
                         }
                         samplesObj[item['id']] = item['total_samples'];
                         
                    });
                    setChecklistCategoryDropdown(arr);
                    setTotalSamples(samplesObj);
               }
          });

     }, []);

     return (

          <React.Fragment>

               <DialogTitle sx={{ 
                    m: 0, 
                    p: '7px',
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Equipment Selection
               </DialogTitle>

               <form>
                    <DialogContent dividers sx={{ p: '1px 24px', height: '80vh' }}>

                         <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="flex-end">
                              <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                                   <Box sx={{ p: 1, width: 280 }}>
                                        <InputLabel
                                             sx={{ 
                                                  color: theme => theme.palette.text.secondary,
                                                  fontSize: '13px',
                                                  fontWeight: 'bold',
                                                  pb: '3px'
                                             }}
                                        >
                                             Checklist Category <span className={classes.required}>*</span>
                                        </InputLabel>
                                        <Dropdown
                                             labelkey="id"
                                             labelname="name"
                                             name="checklistCategory"
                                             placeholder="Select Checklist Category"
                                             value={checklistCategory}
                                             dropdown={checklistCategoryDropdown}
                                             onChange={e => {
                                                  setChecklistCategory(e.target.value);
                                                  setChecklistError('');
                                                  setTableError('');
                                             }}
                                             disabled={false}
                                        />
                                        <ErrorMessage>{checklistError}</ErrorMessage>
                                   </Box>
                                   <Box sx={{ p: 1, pb: 2.4 }}>
                                        <CustomButton variant="contained" color="primary" icon="" onClick={handleEqTable}>
                                             View
                                        </CustomButton>
                                   </Box>
                                   {showTable && (
                                        <Box sx={{ p: 1, pb: 2.4 }}>
                                             <Typography sx={{ 
                                                  color: theme => theme.palette.primary.main,
                                                  fontSize: '15px',
                                                  fontWeight: 'bold',
                                                  paddingBottom: '8px',
                                             }}>
                                                  Total Samples: {samplesValue}
                                             </Typography>
                                        </Box>
                                   )}
                              </Box>

                              <EquipmentSelectionHeader 
                                   auditPlanDataId={props.auditPlanDataId}
                                   checklistCategory={checklistCategory}
                                   totalSamples={totalSamples}
                                   checkedRows={checkedRows}
                                   setTableError={setTableError}
                                   setTableDataChanged={setTableDataChanged}
                                   setLoading={setLoading}
                                   setOpenDialog={props.setOpenDialog}
                                   setAuditSummaryChanged={props.setAuditSummaryChanged}
                              />

                         </Box>

                         {showTable ? (    
                              <SolarTable
                                   title="Equipment Selection Table"
                                   columns={columns}
                                   data={tableData}
                                   filtering={false}
                                   loading={loading}
                                   selection={true}
                                   onSelectionChange={rowData => handleCheckedRows(rowData)}
                                   pageSize={15}
                                   pageSizeOptions={true}
                                   tableError={tableError}
                                   showError={true}
                                   actions={[]}
                                   detailPanel={[]}
                                   setHeight={true}
                              />
                         ) : (
                              <Box>
                                   <Typography sx={{ height: '42px' }}></Typography>
                                   <SolarTable
                                        title="Equipment Selection Table"
                                        columns={columns}
                                        data={[]}
                                        filtering={false}
                                        pageSize={15}
                                        pageSizeOptions={true}
                                        actions={[]}
                                        detailPanel={[]}
                                   />
                              </Box>
                         )}

                    </DialogContent>

                    <DialogActions sx={{ p: '6px' }}>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'center' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>

          </React.Fragment>

     );
}