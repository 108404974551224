import React, { useEffect, useState } from 'react';
import { Card, Grid } from '@mui/material';
import ChartLoader from '@custom/Charts/ChartLoader';
import ColumnHighcharts from '@custom/Charts/ColumnHighcharts';
import AuthMethods from 'app/services';
import _ from 'lodash';

export default function CommonCharts(props) {

     const [loading, setLoading] = useState(false);
     const [healthChartData, setHealthChartData] = useState([]);
     const [severityChartData, setSeverityChartData] = useState([]);

     const healthChart = {

          chart: {
               type: 'column'
          },
          title: {
               text: 'Category Wise - Health Score Details'
          },
          xAxis: {
               type: 'category',
          },
          yAxis: {
               min: 0,
               title: {
                    text: 'Health Score'
               }
          },
          tooltip: {
               pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b>'
          },
          legend: {
               enabled: false,
          },
          plotOptions: {
               column: {
                    colorByPoint: true
               },
               series: {
                    borderWidth: 0,
                    dataLabels: {
                         enabled: true,
                         format: '{point.y:.1f}%'
                    }
               }
          },
          series: healthChartData,
     };

     const severityChart = {

          chart: {
               type: 'column'
          },
          title: {
               text: 'Category Wise - Severity Details'
          },
          xAxis: {
               type: 'category'
          },
          yAxis: {
               min: 0,
               title: {
                    text: 'Severity Count'
               }
          },
          tooltip: {
               // headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
               pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>'
          },
          legend: {
               enabled: false,
          },
          plotOptions: {
               column: {
                    colorByPoint: true
               },
               series: {
                    borderWidth: 0,
                    dataLabels: {
                         enabled: true,
                         format: '{point.y}'
                    }
               },
          },
          series: severityChartData,
     };

     useEffect(() => {

          if (props.chartDataChanged === 1) {
               setLoading(true);
          
               let chartData = {
                    'audit_plan_data_id': parseInt(props.auditPlanDataId),
                    'audit_checklist_category_id': props.checklistCategory,
               };
     
               AuthMethods['common'].getHealthScoreChart(chartData, setLoading).then(data => {
                    // console.log(data);
                    if (_.size(data['data']) > 0) {
                         
                         setHealthChartData(
                              [{
                                   data: [
                                        {
                                             name: 'Current',
                                             y: parseFloat(data['data']['current']),
                                        }, 
                                        {
                                             name: 'Initial',
                                             y: parseFloat(data['data']['initial']),
                                        }
                                   ]
                              }]
                         );
                    }
               });
     
               AuthMethods['common'].getSeverityChart(chartData, setLoading).then(data => {
                    // console.log(data);
                    if ((data['data']).length > 0) {
                         if (_.size(data['data'][0]) > 0) {
     
                              setSeverityChartData(
                                   [{
                                        data: [
                                             {
                                                  name: 'High',
                                                  y: data['data'][0]['high'],
                                                  color: '#E95363',
                                             }, 
                                             {
                                                  name: 'Medium',
                                                  y: data['data'][0]['mid'],
                                                  color: '#F4A738',
                                             },
                                             {
                                                  name: 'Low',
                                                  y: data['data'][0]['low'],
                                                  color: '#7b9bea',
                                             }
                                        ]
                                   }]
                              );
                         }
                    }
               });

               props.setChartDataChanged(0);
          }

     }, [props.chartDataChanged]);

     useEffect(() => {
          if (props.auditPlanDataId !== '') {
               setLoading(true);
               
               AuthMethods['common'].getSiteHealthScore(parseInt(props.auditPlanDataId)).then(data => {
                    if (_.size(data['audited_data']) > 0) {
                         setHealthChartData(
                              [{
                                   data: [
                                        {
                                             name: 'Current',
                                             y: parseFloat(data['audited_data']['current']),
                                        }, 
                                        {
                                             name: 'Initial',
                                             y: parseFloat(data['audited_data']['initial']),
                                        }
                                   ]
                              }]
                         );
                         setLoading(false);
                    }
               });
          }
     }, [props.auditPlanDataId]);

     return (

          <Grid container spacing={2} height="100%">
               <Grid item xs={12} sm={6}>
                    <Card sx={{ p: 1, boxShadow: "0px 0px 10px 1px #DBD8F1", height: '100%', minHeight: '420px' }}>
                         {loading ? (<ChartLoader title="Loading..." />) : (
                         <ColumnHighcharts chartData={healthChart} />
                         )}
                    </Card>
               </Grid>
               <Grid item xs={12} sm={6}>
                    <Card sx={{ p: 1, boxShadow: "0px 0px 10px 1px #DBD8F1", height: '100%', minHeight: '420px' }}>
                         {loading ? (<ChartLoader title="Loading..." />) : (
                         <ColumnHighcharts chartData={severityChart} />
                         )}
                    </Card>
               </Grid>
          </Grid>

     );
}