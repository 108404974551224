import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const PageLoader = ({

  title = '',
  position = 'fixed',
  mode = 'light',
  titleColor = 'whitesmoke',
  ...props

}) => {
  const useStyles = makeStyles({

     root: {
          position: position ? position : 'fixed',
          left: 0,
          top: 0,
          zIndex: 999999,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: mode === 'light' ? '#17171773' : '#ffffffa3',
     },

  });

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CircularProgress />
      <h3 style={{ color: titleColor }}>{title}</h3>
    </Box>
  );
};

export default PageLoader;
