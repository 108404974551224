import React, { useCallback } from 'react';
import {useDropzone} from "react-dropzone";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import {Box, Button, Card, ListItem} from "@mui/material";
import Div from "@jumbo/shared/Div";
import DeleteIcon from '@mui/icons-material/Delete';

const FileDropzone = (props) => {

    const onDrop = useCallback(acceptedFiles => {
        // console.log(acceptedFiles);
        acceptedFiles.forEach(file => {
            props.setFile(file);
            props.setFileError('');
        });

    }, []);
    
    const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({
        onDrop,
        noClick: true,
        accept: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
    });

    const files = acceptedFiles.map(file => (
        <ListItem key={file.path} sx={{width: 'auto', mr: 1}}>
            {file.path}
        </ListItem>
    ));

    const removeFile = () => {
        props.setFile(null);
    };

    return (
        <Card sx={{ 
            minHeight: '15vh',
            boxShadow: '1px 1px 10px 0px #DBD8F1'
        }}>
            <Box display="flex" flexDirection="column" alignItems="center" p="1px 6px">
                <Div {...getRootProps()} className="dropzone" sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 1,
                    minHeight: 5,
                    borderRadius: 1,
                    border: '2px dashed #BBB',
                    bgcolor: theme => theme.palette.action.hover,
                    cursor: 'pointer'
                }}
                >
                    <input {...getInputProps()} />
                    <Button onClick={() => {
                        props.setFile(null);
                        open();
                    }}
                        sx={{
                            padding: '6px',
                            fontSize: '13px'

                        }}
                    >
                        Drag 'n' drop file here, or click to select file
                    </Button>
                </Div>
                <Typography sx={{ 
                    pt: '5px', 
                    fontSize: '13px',
                    fontWeight: 'bold',
                    color: theme => theme.palette.text.secondary
                }}>
                    Uploaded File
                </Typography>
                <Box sx={{
                    width: '100%',
                    height: '6vh',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    {props.file && (
                        <React.Fragment>
                            <Box>
                                <List sx={{ display: 'flex', m: 0 }}>
                                    {files}
                                </List>
                            </Box>
                            <Box>
                                <Button
                                    onClick={() => {
                                        removeFile();
                                    }}
                                >
                                    <DeleteIcon fontSize='small' />
                                </Button>
                            </Box>
                        </React.Fragment>
                    )}
                </Box>
            </Box>
        </Card>
    );
};

export default FileDropzone;
