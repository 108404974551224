import baseAxios from "axios";

const axios = baseAxios.create({
    // baseURL: "http://localhost:4000/",
    baseURL: "https://api.solaraha.renew.com/",
    headers: {
        'Content-Type': 'application/json'
    }
});

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default axios;