import React, { forwardRef, memo } from 'react';
import MaterialTable, { MTableBodyRow, MTableToolbar } from "@material-table/core";
import { AddBox, ArrowUpward, Check, ChevronLeft, ChevronRight, Clear, Delete, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from '@mui/icons-material';
import { ExportCsv } from '@material-table/exporters';
// import ExportPdf from '@material-table/exporters/pdf';
import { makeStyles } from '@mui/styles';
import { Box, CircularProgress, Typography } from '@mui/material';
import Div from '@jumbo/shared/Div/Div';
import { isUndefined } from 'lodash';

const useStyles = makeStyles({

     tableText: {
          paddingLeft: '5px',
          fontSize: '14px',
          color: '#22334A',
          '@media (max-width: 400px)': {
               display: 'none',
          },
     },

     errorMessage: {
          color: '#E73145 !important',
          fontSize: '13px',
          paddingBottom: '1px',
          minHeight: '15px'
     }

});

export default memo(function SolarTable(props) {

     // console.log(props);
     const classes = useStyles();

     const icons = {
          Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
          Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
          DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} color="secondary" />),
          Edit: forwardRef((props, ref) => <Edit color="primary" {...props} ref={ref} />),
          Export: forwardRef((props, ref) =>
          <React.Fragment>
               <SaveAlt {...props} ref={ref} color="primary" />
               <span className={classes.tableText}>Export</span>
          </React.Fragment> 
          ),
          Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
          FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
          LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
          NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
          PreviousPage: forwardRef((props, ref) => (
            <ChevronLeft {...props} ref={ref} />
          )),
          ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
          SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
          ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
          ViewColumn: forwardRef((props, ref) => 
               <React.Fragment>
                    <ViewColumn {...props} ref={ref} color="primary" />
                    <span className={classes.tableText}>Customize Table</span>
               </React.Fragment>
          ),
          Delete: forwardRef((props, ref) => <Delete color="primary" {...props} ref={ref} />),
     };

     return (

          <MaterialTable
               style={{
                    padding: props.setHeight ? '6px' : '10px',
                    borderRadius: '10px',
                    boxShadow: '1px 1px 15px 3px #DBD8F1',
               }}
               title={
                    <Typography
                         sx={{ color: theme => theme.palette.text.primary,
                         fontSize: '16px',
                         fontWeight: 'bold'
                         }}
                    >
                         {props.title}
                    </Typography>
               }
               columns={props.columns}
               data={props.data}
               onSelectionChange = {props.onSelectionChange}
               icons={icons}
               isLoading={props.loading}
               options={{
                    headerStyle: {
                         backgroundColor: props.insideDetailPanel ? '#DBD8F1' : '#C1EAB5',
                         height: '46px',
                         fontWeight: 'bold',
                         fontSize: '14px',
                         whiteSpace: (props.spacing) ? 'nowrap' : '',
                         padding: props.selection === true ? '' : '0 10px 0 10px',
                    },
                    cellStyle: {
                         textAlign: 'inherit',
                         padding: (props.selection && props.sendMail) ? '6px 0 6px 25px' : '3px 0 3px 10px',
                    },
                    search: !isUndefined(props.search) ? props.search : props.insideDetailPanel ? false : true,
                    searchFieldStyle: {
                         backgroundColor: 'whitesmoke',
                         borderRadius: '40px',
                         padding: '4px',
                    },
                    filterCellStyle: {
                         padding: '3px',
                    },
                    actionsColumnIndex: -1,
                    columnsButton: isUndefined(props.columnsButton) ? true : props.columnsButton,
                    exportAllData: true,
                    exportMenu: props.export ? (
                         [
                              {
                                   label: "Export CSV",
                                   exportFunc: (cols, datas) => {
                                        if (props.exportFunc) {
                                             props.exportFunc();
                                        } else {
                                             ExportCsv(cols, datas, "tableData");
                                        }
                                   },
                              },
                              // {
                              //   label: "Export PDF",
                              //   exportFunc: (cols, datas) => ExportPdf(cols, datas, "pdfFileName"),
                              // },
                         ]) : [],
                    filtering: props.filtering,
                    selection: props.selection ? props.selection : false,
                    // showTextRowsSelected: props.selection ? props.selection : true,
                    selectionProps: props.selectionProps ? props.selectionProps : {},
                    paging: !isUndefined(props.paging) ? props.paging : true,
                    emptyRowsWhenPaging: false,
                    pageSize: props.pageSize ? props.pageSize : 10,
                    pageSizeOptions: props.pageSizeOptions ? [15, 30, 45] : [10, 20, 30],
                    maxBodyHeight: props.setHeight ? 340: '',
               }}
               components={{
                    Toolbar: toolbarProps => (
                         // console.log(toolbarProps),
                         props.showError ? (
                              <Box 
                                   sx={{
                                        '& .MuiToolbar-root.MuiToolbar-regular': {
                                             backgroundColor: theme => toolbarProps.selectedCount === 0 ? theme.palette.secondary.main : theme.palette.background.primary,
                                             minHeight: '49px',
                                             marginBottom: props.tableError ? '0px' : '5px',
                                             padding: '5px',
                                             '@media (max-width: 540px)': {
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                             },
                                        },
                                        '& .MuiTypography-root' : {
                                             color: theme => theme.palette.text.primary,
                                             fontWeight: 'bold',
                                             pl: 1,
                                        }
                                   }}
                              >
                                   <MTableToolbar {...toolbarProps} />
                                   <Typography className={classes.errorMessage}>
                                        {props.tableError}
                                   </Typography>
                              </Box>
                         ) : (
                              <Box 
                                   sx={{
                                        '& .MuiToolbar-root.MuiToolbar-regular': {
                                             backgroundColor: theme => toolbarProps.selectedCount === 0 ? theme.palette.secondary.main : theme.palette.background.primary,
                                             minHeight: '49px',
                                             marginBottom: props.tableError ? '0px' : '5px',
                                             padding: '5px',
                                             '@media (max-width: 540px)': {
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                             },
                                        },
                                        '& .MuiTypography-root' : {
                                             color: theme => theme.palette.text.primary,
                                             fontWeight: 'bold',
                                             pl: 1,
                                        }
                                   }}
                              >
                                   <MTableToolbar {...toolbarProps} />
                              </Box>
                         )
                    ),
                    OverlayLoading: props => (
                         <Div
                              sx={{
                                   width: '100%',
                                   height: '100%',
                                   display: 'flex',
                                   justifyContent: 'center',
                                   alignItems: 'center'
                              }}
                         >
                              <CircularProgress/>
                         </Div>
                    ),
               }}
               actions={props.actions.length !== 0 ? [...props.actions] : []}
               detailPanel={props.detailPanel.length !== 0 ? [...props.detailPanel] : []}
          />
     );
});
