import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import CustomDialog from '@custom/Components/CustomDialog';
import AddCategory from './AddCategory';
import AddChecklist from './AddChecklist';

export default function ChecklistHeader(props) {
     
     const setCategoryTableDataChanged = props.setCategoryTableDataChanged;
     const checklistTableDataChanged = props.checklistTableDataChanged;
     const setChecklistTableDataChanged = props.setChecklistTableDataChanged;

     const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
     const [openChecklistDialog, setOpenChecklistDialog] = useState(false);
     const [action, setAction] = useState('');
     
     const addCategory = () => {
          setAction('add');
          setOpenCategoryDialog(true);
     };

     const addChecklist = () => {
          setAction('add');
          setOpenChecklistDialog(true);
     };

     return (

          <React.Fragment>
               <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }} mb={2}>
                    <Box pl={1}>
                         <Typography
                              sx={{ 
                                   color: theme => theme.palette.text.primary,
                                   fontSize: '21px',
                                   fontWeight: 'bold'
                                   }}
                              >
                                   Checklist
                         </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                         <Box p={1}>
                              <CustomButton variant="contained" color="primary" icon="getCategoryIcon" onClick={addCategory}>
                                   Add Category
                              </CustomButton>
                         </Box>
                         <Box p={1}>
                              <CustomButton variant="contained" color="primary" icon="getBeenhereIcon" onClick={addChecklist}>
                                   Add Checklist Item
                              </CustomButton>
                         </Box>
                    </Box>
               </Box>

               <CustomDialog openDialog={openCategoryDialog}>
                    <AddCategory setOpenDialog={setOpenCategoryDialog} action={action} setCategoryTableDataChanged={setCategoryTableDataChanged} />
               </CustomDialog>
               <CustomDialog openDialog={openChecklistDialog}>
                    <AddChecklist setOpenDialog={setOpenChecklistDialog} action={action} checklistTableDataChanged={checklistTableDataChanged} setChecklistTableDataChanged={setChecklistTableDataChanged} />
               </CustomDialog>

          </React.Fragment>
     );
}
