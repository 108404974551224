import React from 'react';
import { FormHelperText } from '@mui/material';

export default function ErrorMessage(props) {
     return (
          <FormHelperText
               id="outlined-weight-helper-text" 
               sx={{
                    color: theme => theme.palette.error.main,
                    ml: .3,
                    height: '1vh'
               }}
          >
               {props.children}
          </FormHelperText>
     );
}