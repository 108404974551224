import React, { useEffect, useState } from 'react';
import SolarTable from '@custom/Components/SolarTable';
import { Box, Typography } from '@mui/material';
import { Delete, Edit, Key } from '@mui/icons-material';
import CustomButton from '@custom/Components/CustomButton';
import CustomDialog from '@custom/Components/CustomDialog';
import AddEditSite from './AddEditSite';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AuthMethods from 'app/services';
import { isNull } from 'lodash';
import TableMultiSelect from '@custom/Components/TableMultiSelect';
import AddOem from './AddOem';
import DeleteOem from './DeleteOem';

const statusDropdown = [
     'Active',
     'Inactive',
];

const columns = [
     { 
          title: "ID", 
          field: "id", 
          filtering: false,
          hidden: true, 
          hiddenByColumnsButton: true 
     },
     { 
          title: 'Site Name', 
          field: 'siteName',
          filtering: false
     },
     { 
          title: 'State Name', 
          field: 'stateName',
          filtering: false 
     },
     { 
          title: 'Auditee', 
          field: 'auditee',
          filtering: false 
     },
     { 
          title: 'Capacity AC (MW)', 
          field: 'acCapacity',
          filtering: false 
     },
     { 
          title: 'DC Capacity (MW)', 
          field: 'dcCapacity',
          filtering: false 
     },
     { 
          title: 'Date of Commissioning', 
          field: 'commissionDate',
          filtering: false 
     },
     { 
          title: 'Inverter OEM', 
          field: 'inverterOem',
          filtering: false,
          hidden: true, 
          hiddenByColumnsButton: false 
     },
     { 
          title: 'SPV', 
          field: 'spv',
          filtering: false,
          hidden: true, 
          hiddenByColumnsButton: false 
     },
     { 
          title: "Status", 
          field: "status",
          filtering: true,
          customFilterAndSearch: (data, rowData) => {
            return data.includes(rowData.status);
          },
          filterComponent: ({ columnDef, onFilterChanged }) => (
            <TableMultiSelect 
              dropdown={statusDropdown} 
              columnDef={columnDef} 
              onFilterChanged={onFilterChanged} 
            />
          )
     },
];

export default function Site() {

     const MySwal = withReactContent(Swal)

     const [openDialog, setOpenDialog] = useState(false);
     const [openOemDialog, setOpenOemDialog] = useState(false);
     const [openDeleteOemDialog, setOpenDeleteOemDialog] = useState(false);


     const [action, setAction] = useState('');
     const [tableData, setTableData] = useState([]);
     const [loading, setLoading] = useState(true);
     const [tableDataChanged, setTableDataChanged] = useState(false);
     const [siteId, setSiteId] = useState('');
     const [message, setMessage] = useState('');
     
     const handleDialogOpen = () => {
          setAction('add');
          setOpenDialog(true);
     };

     const handleOemDialogOpen = () => {
          setAction('add');
          setOpenOemDialog(true);
     };
     const handleDeleteOemDialogOpen = () => {
          setAction('add');
          setOpenDeleteOemDialog(true);
     };

     const handleEditState = (rowData) => {
          setAction('edit');
          setOpenDialog(true);
          setSiteId(rowData.id);
     };

     const handleDeleteSite = (rowData) => {
          MySwal.fire({
               title: 'Are you sure to delete this site?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Yes, delete!',
               cancelButtonText: 'No, cancel!',
               reverseButtons: true,
          }).then(result => {
               if (result.value) {
                    setLoading(true);
                    AuthMethods['site'].deleteSite(rowData.id)
                         .then(data => {
                              let tempData = [...tableData];
                              tempData = tempData.filter(item =>
                                   item.id !== rowData.tableData.id
                              );
                              setTableData(tempData);                                 
                              Swal.fire('Deleted!', 'Site has been deleted', 'success');
                              setLoading(false);
                         })
                         .catch(error => {
                              Swal.fire('Error occured while deleting', '');
                              setLoading(false);
                         });
               } else if (
                    result.dismiss === Swal.DismissReason.cancel
               ) {
                    Swal.fire('Cancelled!', 'Site has not been deleted', 'error');
               }
          });
     };

     
     const InactivateSite = (rowData) => {
          let siteData = {
               status: false
          };
          MySwal.fire({
          title: 'Are you sure to inactivate this site?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, inactivate!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
     }).then(result => {
          if (result.value) {
               setLoading(true);
               AuthMethods['site'].updateSite(rowData.id, siteData, setTableDataChanged, setMessage, setLoading);
               Swal.fire('Inactivated!', 'Site has been inactivated', 'success');
          } else if (
               result.dismiss === Swal.DismissReason.cancel
          ) {
               Swal.fire('Cancelled!', 'Site has not been inactivated', 'error');
          }
          });
     };
 
     const ActivateSite = (rowData) => {
          let siteData = {
               status: true
          };
          MySwal.fire({
          title: 'Are you sure to activate this site?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, activate!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
     }).then(result => {
          if (result.value) {
               setLoading(true);
               AuthMethods['site'].updateSite(rowData.id, siteData, setTableDataChanged, setMessage, setLoading);
               Swal.fire('Activated!', 'Site has been activated', 'success');
          } else if (
               result.dismiss === Swal.DismissReason.cancel
          ) {
               Swal.fire('Cancelled!', 'Site has not been activated', 'error');
          }
          });
     };

     useEffect(() => {
          
          AuthMethods['site'].getAllSites().then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let temp = data['data'].map((item, index) => {
                         return {
                              index: index,
                              id: item.id,
                              siteName: item.name,
                              stateName: !isNull(item['state']) ? item['state']['name'] : '',
                              auditee: !isNull(item['auditee']) ? item['auditee']['name'] : '',
                              acCapacity: item['ac_capacity'],
                              dcCapacity: item['dc_capacity'],
                              commissionDate: item['commission_date'],
                              inverterOem: !isNull(item['inverter_oem']) ? item['inverter_oem']['name'] : '',
                              spv: item.spv,
                              status: item.status === true ? 'Active' : 'Inactive',
                         }
                    });
                    setTableData(temp);
                    setLoading(false);
               }
          });

     }, [tableDataChanged]);

     return (
          <React.Fragment>

               <Box display="flex" justifyContent="space-between" mb={3}>
                    <Box>
                         <Typography
                              sx={{ 
                                   color: theme => theme.palette.text.primary,
                                   fontSize: '21px',
                                   fontWeight: 'bold'
                                   }}
                              >
                                   Site
                         </Typography>
                    </Box>
                    <Box>
                         <CustomButton variant="contained" color="primary" icon="getPostAddIcon" onClick={handleOemDialogOpen}>
                              Add Inverter OEM
                         </CustomButton>
                    </Box>
                    <Box>
                         <CustomButton variant="outlined" color="primary" icon="getPostAddIcon" onClick={handleDeleteOemDialogOpen}>
                              Delete Inverter OEM
                         </CustomButton>
                    </Box>
                    <Box>
                         <CustomButton variant="contained" color="primary" icon="getPostAddIcon" onClick={handleDialogOpen}>
                              Add Site
                         </CustomButton>
                    </Box>
               </Box>

               <SolarTable
                    title="Site Table"
                    columns={columns}
                    data={tableData}
                    filtering={true}
                    loading={loading}
                    actions={
                         [
                              {
                                   icon: () => {
                                     return <Edit 
                                       fontSize="small" 
                                       sx={{ color: theme => theme.palette.info.main }}
                                     />;
                                   },
                                   tooltip: "Edit Site",
                                   onClick: (e, rowData) => handleEditState(rowData),
                              },
                              {
                                   icon: () => {
                                     return <Delete 
                                       fontSize="small" 
                                       sx={{ color: theme => theme.palette.error.main }} 
                                     />;
                                   },
                                   tooltip: "Delete Site",
                                   onClick: (e, rowData) => handleDeleteSite(rowData)
                              },
                              rowData => ({
                                   icon: () => {
                                     return <Key 
                                       fontSize="small" 
                                       sx={rowData['status'] === 'Active' ? (
                                         { color: theme => theme.palette.primary.main }
                                         ) : (
                                           { color: theme => theme.palette.error.main }
                                         )}
                                     />;
                                   },
                                   tooltip: rowData['status'] === 'Active' ? 'Inactivate Site' : 'Activate Site', 
                                   onClick: (event, rowData) => {
                                     if (rowData['status'] === 'Active') {
                                       return InactivateSite(rowData);
                                     } else {
                                       ActivateSite(rowData);
                                     }
                                   }
                              }),
                         ]
                    }
                    detailPanel={[]}
               />

               <CustomDialog openDialog={openDialog} maxWidth="sm">
                    <AddEditSite setOpenDialog={setOpenDialog} action={action} setTableDataChanged={setTableDataChanged} siteId={siteId} />
               </CustomDialog>
               
               <CustomDialog openDialog={openOemDialog} maxWidth="sm">
                    <AddOem setOpenDialog={setOpenOemDialog} action={action} setTableDataChanged={setTableDataChanged} siteId={siteId} />
               </CustomDialog>
               
               <CustomDialog openDialog={openDeleteOemDialog} maxWidth="sm">
                    <DeleteOem setOpenDialog={setOpenDeleteOemDialog} action={action} setTableDataChanged={setTableDataChanged} siteId={siteId} />
               </CustomDialog>

          </React.Fragment>    
     );
}
