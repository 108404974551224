import React, { memo } from 'react';
import { Card, Grid } from '@mui/material';
import ChartLoader from '@custom/Charts/ChartLoader';
import ColumnHighcharts from '@custom/Charts/ColumnHighcharts';

const CompOverall = (props) => {

     return (
          <Grid container spacing={2} mb={3}>
               <Grid item xs={12} sm={12}>
                    <Card
                         sx={{
                              p: 1,
                              boxShadow: "0px 0px 10px 1px #DBD8F1",
                              height: "100%",
                              minHeight: "420px",
                         }}
                         >
                         {props.loadingC ? (
                              <ChartLoader title="Loading..." />
                         ) : (
                              <ColumnHighcharts chartData={props.compOverall} />
                              )}
                    </Card>
               </Grid>
          </Grid>
     );
}

export default memo(CompOverall);