import React from 'react';
import DashboardTabs from './DashboardTabs/DashboardTabs';

export default function Dashboard() {

     return (

          <React.Fragment>
               <DashboardTabs />
          </React.Fragment>
                    
     );
}