import React from 'react';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";

highchartsMore(Highcharts);
solidGauge(Highcharts);


export default function GaugeHighcharts(props) {
     

     const options = {
          credits: {
               enabled: false
          },
          chart: {
               events: {
                    render: function() {
                      this.reflow();
                    },
                    redraw: function(event) {
                      // when api is not working
                      if (this.series.length === 0) {
                        this.showLoading('Cannot Load Data Currently');
                      } else if (this.series[0].data.length === 0) {
                        // no data 
                        this.showLoading('No Data');
                      } else {
                        this.hideLoading();
                      }
                    },
               },
               ...props.chartData.chart,
          },
          title: {
               style: {
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: '#666A7B',
               },
               ...props.chartData.title
          },
          pane: props.chartData.pane,
          exporting: props.chartData.exporting,
          plotOptions: props.chartData.plotOptions,
          yAxis: props.chartData.yAxis,
          series: props.chartData.series,
     };
     
     return (

          <HighchartsReact 
               containerProps={{ style: { height: "100%" } }}
               highcharts={Highcharts} 
               options={options}
          />
          
     );
}