import React, { useEffect, useState } from "react";
import { Box, Checkbox, InputLabel, Typography } from "@mui/material";
import AuthMethods from "app/services";
import { makeStyles } from "@mui/styles";
import Dropdown from "@custom/Components/Dropdown";
import ErrorMessage from "@custom/Components/ErrorMessage";
import CustomButton from "@custom/Components/CustomButton";
import MultiSelect from "@custom/Components/MultiSelect";
import { getSeverityIds, siteIds } from "@custom/Functions/helperFunction";
import SolarTable from "@custom/Components/SolarTable";
import CustomDialog from "@custom/Components/CustomDialog";
import CommonPreview from "app/pages/AuditorSummary/CommonPreview";
import { isNull, isUndefined } from "lodash";
import { LoadingButton } from "@mui/lab";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const useStyles = makeStyles({
  required: {
    color: "#E73145",
  },
});

const severityDropdown  = [
  {id: 3, value: 'High'},
  {id: 2, value: 'Medium'},
  {id: 1, value: 'Low'},
];

export default function IssuesTab(props) {

  const classes = useStyles();
  const userId = localStorage.getItem('userId');

  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [dropLoading, setDropLoading] = useState(true);
  const [state, setState] = useState("");
  const [stateDropdown, setStateDropdown] = useState([]);
  const [siteDropdown, setSiteDropdown] = useState([]);
  const [stateError, setStateError] = useState("");
  const [siteError, setSiteError] = useState("");
  const [selectedSite, setSelectedSite] = useState([]);
  const [siteId, setSiteId] = useState([]);
  const [selectedSeverity, setSelectedSeverity] = useState(['High', 'Medium', 'Low']);
  const [severityIds, setSeverityIds] = useState([]);
  const [selectedComp, setSelectedComp] = useState([]);
  const [compDropdown, setCompDropdown] = useState([]);
  const [compIds, setCompIds] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [image, setImage] = useState(null);
  const [checked, setChecked] = useState(false);
  const [stateName, setStateName] = useState('');

  const columns = [
    { 
      title: "ID", 
      field: "id",
      filtering: false, 
      hidden: true, 
      hiddenByColumnsButton: true 
    },

    { 
      title: "State",
      field: "stateName",
      filtering: false, 
    },

    { 
      title: "Site", 
      field: "siteName",
      filtering: false,
    },

    { 
      title: "Component", 
      field: "component",
      filtering: false, 
    },

    { 
      title: "Component Item", 
      field: "compItem",
      filtering: false, 
      headerStyle: {
        whiteSpace: 'nowrap',
      },
    },

    { 
      title: "Equipment Sample Name", 
      field: "equipment",
      filtering: false, 
    },

    { 
      title: "Severity", 
      field: "severity",
      filtering: false, 
    },

    { 
      title: "Auditor Name", 
      field: "auditorN",
      filtering: false, 
    },

    { 
      title: "Auditor Remarks", 
      field: "auditorR",
      filtering: false, 
      headerStyle: {
        whiteSpace: 'nowrap',
      },
    },

    { 
      title: "Auditor Image", 
      field: "auditorI",
      filtering: false, 
      render: rowData => {
        // console.log(rowData);
        return (
          <React.Fragment>
            {isNull(rowData['auditorI']) ? (
              <Typography sx={{ fontSize: '13px' }}>No Image</Typography>
            ) : (
              <CustomButton
                iconButton={true}
                tooltip="Preview Image"
                variant="contained"
                color="info"
                icon="getPreviewIcon"
                onClick={e => {
                  handlePreview(rowData['auditorI'])
                }}
              />
            )}
          </React.Fragment>
        );
      }
    },

    { 
      title: "Auditee Name", 
      field: "auditeeN",
      filtering: false, 
    },
    
    { 
      title: "Auditee Remarks", 
      field: "auditeeR",
      filtering: false, 
      headerStyle: {
        whiteSpace: 'nowrap',
      },
    },

    { 
      title: "Auditee Rectification Date", 
      field: "auditeeDate",
      filtering: false, 
    },

    { 
      title: "Auditee Image", 
      field: "auditeeI",
      filtering: false, 
      render: rowData => {
        return (
          <React.Fragment>
            {isNull(rowData['auditeeI']) ? (
              <Typography sx={{ fontSize: '13px' }}>No Image</Typography>
            ) : (
              <CustomButton
                iconButton={true}
                tooltip="Preview Image"
                variant="contained"
                color="info"
                icon="getPreviewIcon"
                onClick={e => {
                  handlePreview(rowData['auditeeI'])
                }}          
              />
            )}
          </React.Fragment>
        );
      }
    },

    { 
      title: "Reviewer1 Remarks", 
      field: "reviewer1R",
      filtering: false, 
      headerStyle: {
        whiteSpace: 'nowrap',
      },
    },

    { 
      title: "Reviewer2 Remarks", 
      field: "reviewer2R",
      filtering: false, 
      headerStyle: {
        whiteSpace: 'nowrap',
      },
    },

    { 
      title: "Item Status", 
      field: "status",
      filtering: false, 
    },
  ];

  const download = () => {

    let downloadData = {
      'user_id': userId,
      'fy_id': props.financialYear,
      'state_id': state,
      'site_id': siteId,
      'severity': severityIds,
      'component': compIds,
    };

    if (checked) {
      downloadData = {
        'user_id': userId,
        'fy_id': props.financialYear,
        'state_id': state,
        'site_id': siteId,
        'severity': severityIds,
        'component': compIds,
        'images': true,
      };
    }

    let fileName = stateName.replace(/ /g, '') + '_' + props.fyName;
    // console.log(fileName);
    
    setDownloadLoading(true);
    AuthMethods['chartAndTable'].downloadIssuesData(downloadData, fileName, setDownloadLoading);

  };

  const handlePreview = (i) => {
    setImage(i);
    setOpenDialog(true);
  };

  const issuesTable = () => {
    setShowTable(true);
    
    let issuesData = {
      'user_id': userId,
      'fy_id': props.financialYear,
      'state_id': state,
      'site_id': siteId,
      'severity': severityIds,
      'component': compIds,
    };
    
    setLoading(true);
    setTableData([]);
    AuthMethods['chartAndTable'].getIssues(issuesData, setLoading).then(data => {
      // console.log(data);
      if (data['data'].length > 0) {
        let arr = [];
        data['data'].forEach((item, index) => {
          setStateName(item['state']);
          let obj = {
            id: index,
            stateName: item['state'],
            siteName: item['site'],
            component: item['component_name'],
            compItem: item['component_item'],
            equipment: item['equipment_name'],
            severity: item['severity'],
            auditorN: item['auditor_name'],
            auditorR: !isNull(item['auditor_remarks']) ? item['auditor_remarks'] : '-',
            auditorI: item['auditor_image'],
            auditeeN: item['auditee_name'],
            auditeeR: !isNull(item['auditee_remarks']) ? item['auditee_remarks'] : '-',
            auditeeDate: !isNull(item['submitted_date_auditee']) ? item['submitted_date_auditee'] : '-',
            auditeeI: item['auditee_image'],
            reviewer1R: !isNull(item['reviewer1_remarks']) ? item['reviewer1_remarks'] : '-',
            reviewer2R: !isNull(item['reviewer2_remarks']) ? item['reviewer2_remarks'] : '-',
            status: item['status'],
          }
          arr.push(obj);
        });
        setTableData(arr);
      } else {
        setTableData([]);
      }
    });

  };

  const handleView = (e) => {
    e.preventDefault();

    let isValid = true;

    if (!state) {
      setStateError('This field is required');
      isValid = false;
    }

    if (selectedSite.length === 0) {
      setSiteError('This field is required');
      isValid = false;
    }

    if (isValid) {
      issuesTable();
    }

  };

  useEffect(() => {
    if (selectedComp.length > 0) {
      // console.log(selectedComp);
      // console.log(compDropdown);
      let obj = {};
      compDropdown.forEach(item => {
        if (selectedComp.includes(item['value'])) {
          if (isUndefined(obj[item['value']])) {
            obj[item['value']] = ''
          }
          obj[item['value']] = item['id'];
        }
      });
      // console.log(obj);
      let ids = selectedComp.map(item => {
        return obj[item];
      });
      // console.log(ids);
      setCompIds(ids);
    }

  }, [selectedComp]);
  
  useEffect(() => {
    if (selectedSeverity.length > 0) {
      let ids = getSeverityIds(selectedSeverity);
      setSeverityIds(ids);
    }

  }, [selectedSeverity]);

  useEffect(() => {
    if (compDropdown.length > 0) {
      let all = compDropdown.map(item => {
        return item['value'];
      });
      
      setSelectedComp(all);
    }

  }, [compDropdown]);

  useEffect(() => {

    if (props.financialYear !== '') {
      let compData = {
        'fy_id': props.financialYear
      }

      AuthMethods['category'].getAllCategory(setDropLoading, compData).then(data => {
        // console.log(data);
        if (data['data'].length > 0) {
          let temp = data['data'].map(item => {
            return {
              id: item['id'],
              value: item['short_name'],
            };
          });

          setCompDropdown(temp);
        }
      });
    }

  }, [props.financialYear]);

  useEffect(() => {
    if (selectedSite.length > 0) {
        let sites = siteIds(siteDropdown, selectedSite);
        // console.log(siteIds);
        setSiteId(sites);
        setSiteError('');
    }
  }, [selectedSite]);

  useEffect(() => {
    if (state) {
      let siteData = {
        'state_id': state,
        'user_id': userId,
      };

      AuthMethods['site'].getSitesByStateId(siteData).then(data => {
        // console.log(data);
        if (data['data'].length > 0) {
          let obj = data['data'].map(item => {
            return {
              id: item.id,
              value: item.name,
            };
          });
          setSiteDropdown(obj);
        }
      });

    }

  }, [state]);

  useEffect(() => {
    
    let stateData = {
      user_id: userId,
    };

    AuthMethods['state'].getAllStates(setDropLoading, stateData).then(data => {
      // console.log(data);
      if (data['data'].length > 0) {
        let obj = data['data'].map(item => {
          return {
            id: item.id,
            name: item.name
          };
        });
        
        setStateDropdown(obj);
      }
    });

  }, []);

  return (

    <React.Fragment>

      <Box display="flex" flexWrap="wrap" alignItems="flex-end" mb={2}>
        
        <Box sx={{ p: 1, width: 200 }}>
          <InputLabel
            sx={{ color: theme => theme.palette.text.secondary,
            fontSize: '13px',
            fontWeight: 'bold',
            pb: '3px'
            }}
          >
            State <span className={classes.required}>*</span>
          </InputLabel>
          <Dropdown
            labelkey="id"
            labelname="name"
            placeholder="Select State"
            name="state"
            value={state}
            dropdown={stateDropdown}
            onChange={e => {
              setState(e.target.value);
              setSelectedSite([]);
              setSiteDropdown([]);
              setStateError('');
            }}
            disabled={false}
          />
            <ErrorMessage>{stateError}</ErrorMessage>
        </Box>

        <Box sx={{ p: 1, width: 200 }}>
          <InputLabel
            sx={{ color: theme => theme.palette.text.secondary,
            fontSize: '13px',
            fontWeight: 'bold',
            pb: '3px'
            }}
          >
            Site <span className={classes.required}>*</span>
          </InputLabel>
          <MultiSelect
            placeholder="Select Site"
            selected={selectedSite}
            setSelected={setSelectedSite}
            dropdown={siteDropdown}
          />
            <ErrorMessage>{siteError}</ErrorMessage>
        </Box>

        <Box sx={{ p: 1, pb: 2.4, width: 200 }}>
          <InputLabel
            sx={{ color: theme => theme.palette.text.secondary,
            fontSize: '13px',
            fontWeight: 'bold',
            pb: '3px'
            }}
          >
            Component
          </InputLabel>
          <MultiSelect
            placeholder="Select Components"
            selected={selectedComp}
            setSelected={setSelectedComp}
            dropdown={compDropdown}
          />
        </Box>

        <Box sx={{ p: 1, pb: 2.4, width: 200 }}>
          <InputLabel
            sx={{ 
              color: theme => theme.palette.text.secondary,
              fontSize: '13px',
              fontWeight: 'bold',
              pb: '3px'
            }}
          >
            Severity
          </InputLabel>
          <MultiSelect
            placeholder="Select Severity"
            selected={selectedSeverity}
            setSelected={setSelectedSeverity}
            dropdown={severityDropdown}
          />
        </Box>

        <Box sx={{ p: 1, pb: 2.4 }}>
          <CustomButton
            variant="contained"
            color="primary"
            icon=""
            onClick={handleView}
          >
            View
          </CustomButton>
        </Box>

        <Box sx={{ 
          pb: '11px',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          }}
        >
          <Checkbox
            checked={checked}
            onChange={e => {
              setChecked(e.target.checked);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            color="blue"
            disabled={(showTable && tableData.length > 0) ? false : true}
          />
          <InputLabel
            sx={{ 
              color: theme => theme.palette.text.secondary,
              fontSize: '15px',
              fontWeight: 'bold',
              pb: '1px',
              pr: '2px',
            }}
          >
            Check for images
          </InputLabel>
          <Box p={1}>

            {!downloadLoading ? (
              <CustomButton
                variant="contained"
                color="blue"
                icon="getDownloadIcon"
                disabled={(showTable && tableData.length > 0) ? false : true}
                onClick={download}
              >
                Download
              </CustomButton>
            ) : (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<CheckCircleOutlineIcon />}
                variant="contained"
              >
                Loading
            </LoadingButton>
            )}

          </Box>

        </Box>

      </Box>

      {showTable && (
        <SolarTable
          title="Issues Table"
          columns={columns}
          data={tableData}
          filtering={false}
          loading={loading}
          actions={[]}
          detailPanel={[]}
        />
      )}

      <CustomDialog openDialog={openDialog} maxWidth="lg">
        <CommonPreview setOpenDialog={setOpenDialog} image={image} />
      </CustomDialog>

    </React.Fragment>
  );
}
