import axios from "../config";

const State = {
     
     getAllStates: (setLoading, stateData = {}) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/states', stateData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     addState: (stateData, setTableDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/state/create', stateData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setTableDataChanged(prev => !prev);
                              setMessage("Success");
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage("Error");
                         setLoading(false);
                    });
          });
     },

     getStateById: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .get('/state/' + id)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     updateState: (id, stateData, setTableDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .put('/state/' + id, stateData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setTableDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     deleteState: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .delete('/state/' + id)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

}

export default State;