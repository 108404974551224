import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { useJumboTheme } from '@jumbo/hooks';

const InitialCurrentCard = (props) => {

     const {theme} = useJumboTheme();

     return (
          
          <Box
               sx={{ 
                    width: '100%',
                    height: '100%',
                    p: 1,
                    [theme.breakpoints.down('sm')] : {
                         mb: 1,
                    },
               }}
          >
               <Card>
                    <Box
                         sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              justifyContent: 'space-between',
                              background: props.color,
                              p: 1,
                         }} 
                    >
                         <Box sx={{
                              p: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'space-evenly',
                         }}>
                              <Box>
                                   
                                   <Typography
                                        sx={{
                                             color: theme => theme.palette.secondary.main,
                                             fontSize: '17px',
                                             fontWeight: 'bold',
                                        }}
                                   >
                                        High - {props.high}
                                   </Typography>
                              </Box>
                              <Box>
                              </Box>
                         </Box>
                         <Box sx={{
                              p: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'space-evenly',
                         }}>
                              <Box>
                                   <Typography
                                        sx={{
                                             color: theme => theme.palette.secondary.main,
                                             fontSize: '17px',
                                             fontWeight: 'bold',
                                        }}
                                   >
                                        Medium - {props.mid}
                                   </Typography>
                              </Box>
                         </Box>
                         <Box sx={{
                              p: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'space-evenly',
                         }}>
                              <Box>
                                   <Typography
                                        sx={{
                                             color: theme => theme.palette.secondary.main,
                                             fontSize: '17px',
                                             fontWeight: 'bold',
                                        }}
                                   >
                                        Low - {props.low}
                                   </Typography>
                              </Box>
                         </Box>
                    </Box>
                    <Box
                         sx={{
                              p: 1.5,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              background: theme => theme.palette.background.default,
                         }}
                    >
                         <Typography
                              sx={{
                                   color: theme => theme.palette.grey.main,
                                   fontSize: '17px',
                                   fontWeight: 'bold',
                              }}>
                                   {props.name}
                         </Typography>
                    </Box>
               </Card>
          </Box>

     );
}

export default InitialCurrentCard;