import Auth from "./auth";
import Users from "./admin/users";
import Roles from "./admin/roles";
import State from "./admin/state";
import Site from "./admin/site";
import Equipment from "./admin/equipment";
import InverterOem from "./admin/inverterOem";
import Category from "./admin/category";
import Checklist from "./admin/checklist";
import AuditPlan from "./auditPlan";
import Common from "./common";
import AuditorEquipment from "./auditor/auditorEquipment";
import AuditorChecklist from "./auditor/auditorChecklist";
import Auditee from "./auditee";
import Reviewer from "./reviewer";
import ChartAndTable from "./dashboard/chartAndTable";

const AuthMethods = {
    auth: Auth,
    users: Users,
    roles: Roles,
    state: State,
    site: Site,
    equipment: Equipment,
    inverterOem: InverterOem,
    category: Category,
    checklist: Checklist,
    common: Common,
    auditPlan: AuditPlan,
    auditorEquipment: AuditorEquipment,
    auditorChecklist: AuditorChecklist,
    auditee: Auditee,
    reviewer: Reviewer,
    chartAndTable: ChartAndTable,
};

export default AuthMethods;