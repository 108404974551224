import React, { useEffect, useState } from 'react';
import SolarTable from '@custom/Components/SolarTable';
import _ from 'lodash';

const siteColumns = [
     { title: 'ID', field: 'id', hidden: true, hiddenByColumnsButton: true },
     { title: 'Site', field: 'site' },
     { title: 'DC Capacity', field: 'dcCapacity' },
     { title: 'Date of Audit', field: 'date' },
     { title: 'Auditor', field: 'auditor' },
     { title: 'Auditee', field: 'auditee' },
     { title: 'Health Score (%)', field: 'hs' },
     { title: 'Major Observations', field: 'observation' },
];

const SitesData = (props) => {

     const data = props.data;
     const rowData = props.rowData['rowData'];

     const [siteData, setSiteData] = useState([]);

     useEffect(() => {
          
          if (_.size(rowData) > 0) {
               for (let state in data) {
                    if (data[state].length > 0) {
                         let arr = [];
                         data[state].forEach((item, index) => {
                              if (state === rowData['state']) {
                                   // console.log(item);
                                   let obj = {
                                        id: index + 1,
                                        site: item['site'],
                                        dcCapacity: item['dc_capacity'],
                                        date: item['date_of_audit'],
                                        auditor: item['auditor'],
                                        auditee: item['auditee'],
                                        hs: item['health_score'],
                                        observation: item['major_observations'],
                                   };
                                   arr.push(obj);
                                   // console.log(arr);
                                   setSiteData(arr);
                              }
                         });
                    }
               }
          }
     }, [rowData]);

     return (

          <SolarTable
               title={_.size(rowData) > 0 ? rowData['state'] + ' - Site Table' : 'Site Table'}
               columns={siteColumns}
               data={siteData}
               loading={props.loading}
               search={false}
               columnsButton={false}
               paging={false}
               actions={[]}
               detailPanel={[]}
               insideDetailPanel={true}
          />  

     )
};

export default SitesData;