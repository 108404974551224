import React from "react";
import { Card, Grid } from "@mui/material";
import SolarTable from "@custom/Components/SolarTable";
import ColumnHighcharts from "@custom/Charts/ColumnHighcharts";
import ChartLoader from "@custom/Charts/ChartLoader";

export default function ComparisonTab(props) {

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Card sx={{ p: 1, boxShadow: "0px 0px 10px 1px #DBD8F1", height: '100%', minHeight: '420px' }}>
            {props.loadingFC ? (<ChartLoader title="Loading..." />) : (
              <ColumnHighcharts chartData={props.chartDataFinancialYear} />
            )}
          </Card>
        </Grid>

        <Grid item xs={12} sm={12}>
          <SolarTable
            title="Financial Year Comparison"
            columns={props.fyColumns}
            data={props.fyTableData}
            filtering={false}
            loading={props.loadingFC}
            search={false}
            columnsButton={false}
            paging={false}
            actions={[]}
            detailPanel={[]}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Card sx={{ p: 1, boxShadow: "0px 0px 10px 1px #DBD8F1", height: '100%', minHeight: '420px' }}>
            {props.loadingFC ? (<ChartLoader title="Loading..." />) : (
              <ColumnHighcharts chartData={props.chartDataFinancialYearIssue} />
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
