import React, { useEffect, useState } from 'react';
import { Box, InputLabel, Typography } from '@mui/material';
import Dropdown from '@custom/Components/Dropdown';
import CustomButton from '@custom/Components/CustomButton';
import SolarTable from '@custom/Components/SolarTable';
import AuthMethods from 'app/services';
import _ from 'lodash';
import { financialYearData, statusName } from '@custom/Functions/helperFunction';
import { Link } from 'react-router-dom';
import { AES } from 'crypto-js';

const AuditeeSummary = () => {

     const userId = localStorage.getItem('userId');

     const [loading, setLoading] = useState(false);
     const [fYDropdown, setFYDropdown] = useState([]);
     const [financialYear, setFinancialYear] = useState('');
     const [showTable, setShowTable] = useState(false);
     const [tableData, setTableData] = useState([]);

     const handleAuditTable = () => {
          setShowTable(true);
     };

     const columns = [
          { 
               title: "ID", 
               field: "id",
               filtering: false, 
               hidden: true, 
               hiddenByColumnsButton: true 
          },
          { 
               title: "State",
               field: "stateName",
               filtering: false, 
          },     
          { 
               title: "Site",
               field: "siteName",
               filtering: false, 
          },
          { 
               title: "Start Date",
               field: "startDate",
               filtering: false, 
          },
          { 
               title: "End Date", 
               field: "endDate",
               filtering: false,
          },
          { 
               title: "Auditor 1", 
               field: "auditor1",
               filtering: false, 
          },
          { 
               title: "Auditor 2", 
               field: "auditor2",
               filtering: false, 
          },
          {
               title: "Status",
               field: "status",
               filtering: false,
          },
          {
               title: "Checklist",
               field: "chechlist",
               filtering: false,
               render: rowData => {
                    const valueToEncrypt = rowData.auditPlanDataId.toString();

                    // Encryption
                    const encryptedValue = AES.encrypt(valueToEncrypt, 'secret-key').toString();
     
                    return (
                         <Box p={1}>
                              <Link
                                   to={`/auditee-checklist?value=${encodeURIComponent(encryptedValue)}`} 
                                   style={{
                                        textDecoration: 'none',
                                   }}
                              >
                                   <CustomButton
                                        variant="contained"
                                        color="blue"
                                        icon=""
                                   >
                                        Checklist
                                   </CustomButton>
                              </Link>
                         </Box>
                    )
               }
          },
     ];

     useEffect(() => {
          if (financialYear !== '') {
               setLoading(true);
               let auditeeData = {
                    year: financialYear,
                    user_id: userId
               };
               AuthMethods['auditee'].getAuditeeSummary(auditeeData, setLoading).then(data => {
                    // console.log(data);
                    if (data['audited_data'].length > 0) {
                         let arr = [];
                         data['audited_data'].forEach((item, index) => {
                              let obj = {
                                   id: index,
                                   auditPlanDataId: item['id'],
                                   stateName: _.size(item['state']) > 0 ? item['state']['name'] : '',
                                   siteName: _.size(item['site']) > 0 ? item['site']['name'] : '',
                                   startDate: item['start_date'],
                                   endDate: item['end_date'],
                                   auditor1: _.size(item['auditor_1']) > 0 ? item['auditor_1']['name'] : '',
                                   auditor2: _.size(item['auditor_2']) > 0 ? item['auditor_2']['name'] : 'NA',
                                   status: statusName[item['status']],
                              };
                              arr.push(obj);
                         });
                         setTableData(arr);
                    }
               });
          }

     }, [financialYear]);
     
     useEffect(() => {

          AuthMethods['common'].getFinancialYear().then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = financialYearData(data['data']);
                    // console.log(arr);
                    setFYDropdown(arr[0]);
                    setFinancialYear(arr[1]);
               }
          });

     }, []);

     return (

          <React.Fragment>

               <Box mb={3}>
                    <Typography
                         sx={{ 
                         color: theme => theme.palette.text.primary,
                         fontSize: '21px',
                         fontWeight: 'bold'
                         }}
                         >
                              Auditee Summary
                    </Typography>
               </Box>

               <Box display="flex" flexWrap="wrap" alignItems="flex-end" mb={3}>
                    <Box sx={{ p: 1, width: 200 }}>
                         <InputLabel
                              sx={{ 
                                   color: theme => theme.palette.text.secondary,
                                   fontSize: '13px',
                                   fontWeight: 'bold',
                                   pb: '3px'
                              }}
                         >
                              Financial Year
                         </InputLabel>
                         <Dropdown
                              labelkey="id"
                              labelname="name"
                              name="financialYear"
                              placeholder="Select Financial Year"
                              value={financialYear}
                              dropdown={fYDropdown}
                              onChange={e => {
                                   setFinancialYear(e.target.value);
                                   setShowTable(false);
                                   setTableData([]);
                              }}
                              disabled={false}
                         />
                    </Box>
                    <Box sx={{ p: 1, width: 200 }}>
                         <CustomButton variant="contained" color="primary" icon="" onClick={handleAuditTable}>
                              View
                         </CustomButton>
                    </Box>            
               </Box>

               {showTable && (
                    <SolarTable
                         title="Auditee Summary Table"
                         columns={columns}
                         data={tableData}
                         filtering={false}
                         loading={loading}
                         actions={[]}
                         detailPanel={[]}
                    />
               )}

          </React.Fragment>
     );
}

export default AuditeeSummary;