import React, { useEffect, useState } from 'react';
import RcGantt, { GanttProps, enUS } from 'rc-gantt'
import AuthMethods from 'app/services';
import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import PageLoader from '@custom/Components/PageLoader';
import { isEmpty } from 'lodash';

const GanttChart = (props) => {

  const userId = localStorage.getItem('userId');

  const [ganttData, setGanttData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(()=> {
    setLoading(true);
    let data = {
      "fy_id" : props.fyId,
      "user_id": userId,
    };
    AuthMethods['auditPlan'].getGanttData(data).then(resp => {
      // console.log(resp);
      if(resp.length !== 0 || !isEmpty(resp.data)) {
        let arr = resp.map(item => {
          return {
            site: item.site ? item.site : '',
            auditor_1: item.auditor_1 ? item.auditor_1 : '',
            startDate: item.start_date,
            endDate: item.end_date,
          }
        })
        setGanttData(arr);
      }
      setLoading(false);
    });

  }, []);

  const handleDialogClose = () => {
    props.setOpenDialog(false);
  };

  return (
  
    <React.Fragment>
      {loading && <PageLoader title="Loading..." />}

      <DialogTitle sx={{ 
        m: 0, 
        p: 2.3, 
        color: '#505F79', 
        fontSize: '19px', 
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme => theme.palette.primary.main,
        }}
      >
        {props.fy ? 'Audit Plan Timeline' + ' (' + props.fy + ') ' : 'Audit Plan Timeline'}
        
      </DialogTitle>
   
      <DialogContent dividers 
        sx={{ p: 0 }}
      >
        <div style={{ width: '100%', height: 500 }}>
          <RcGantt
            data={ganttData}
            locale={enUS}
            columns={[
              {
                name: 'site',
                label: 'Site',
                width: 150,
              },
              {
                name: 'auditor_1',
                label: 'Auditor-1',
                width: 150,
              },
            ]}
            // disabled='true'
            alwaysShowTaskBar={false}
            renderBar={(barInfo, { width, height }) => (
              <Box 
                sx={{ 
                  width,
                  height: '16px', 
                  backgroundColor: theme => theme.palette.purple.dark 
                }}
              >
                {barInfo.label}
              </Box>
            )}
            unit={'week'}
            onUpdate={async () => {
              return true
            }}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'center' }}>
          <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
            Cancel
          </CustomButton>
        </Box>
      </DialogActions>
                  
    </React.Fragment>
  );
};

export default GanttChart;
