import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import SolarTable from '@custom/Components/SolarTable';
import { Delete, Edit } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomDialog from '@custom/Components/CustomDialog';
import AddCategory from './AddCategory';
import ChecklistHeader from './ChecklistHeader';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AuthMethods from 'app/services';
import ChecklistTable from './ChecklistTable';
import _ from 'lodash';

const columns = [
     { title: 'ID', field: 'id', hidden: true, hiddenByColumnsButton: true },
     { title: 'Category', field: 'category' },
     { title: 'Short Name', field: 'shortName' },
     { title: 'Criteria (X<=50, 50<X<100, X>=100) ', field: 'criteria' },
];

export default function Checklist() {

     const MySwal = withReactContent(Swal);

     const [tableData, setTableData] = useState([]);     
     const [openDialog, setOpenDialog] = useState(false);
     const [action, setAction] = useState('');
     const [loading, setLoading] = useState(false);
     const [categoryId, setCategoryId] = useState('');
     const [categoryTableDataChanged, setCategoryTableDataChanged] = useState(false);
     const [checklistTableDataChanged, setChecklistTableDataChanged] = useState(false);

     const editCategory = (rowData) => {
          setAction('edit');
          setOpenDialog(true);
          setCategoryId(rowData.id);
     };

     const handleDeleteCategory = (rowData) => {
          MySwal.fire({
               title: 'Are you sure to delete this category?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Yes, delete!',
               cancelButtonText: 'No, cancel!',
               reverseButtons: true,
          }).then(result => {
               if (result.value) {
                    setLoading(true);
                    AuthMethods['category'].deleteCategory(rowData.id)
                         .then(data => {

                              let tempData = [...tableData];
                              tempData = tempData.filter(item => 
                                   item.id !== rowData.tableData.id
                              );
                              setTableData(tempData);

                              Swal.fire('Deleted!', 'Category has been deleted', 'success');
                              setLoading(false);
                         })
                         .catch(error => {
                              Swal.fire('Error occured while deleting', '');
                              setLoading(false);
                         });
               } else if (
                    result.dismiss === Swal.DismissReason.cancel
               ) {
                    Swal.fire('Cancelled!', 'Category has not been deleted', 'error');
               }
          });
     };

     useEffect(() => {
          setLoading(true);
          AuthMethods['category'].getAllCategory(setLoading).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let temp = data['data'].map(item => {
                         let value = [];
                         if (_.size(item['creteria']) > 0) {
                              // console.log(item['creteria']);
                              if (Array.isArray(item['creteria'])) {
                                   value = item['creteria'][0] + ', ' + item['creteria'][1] + ', ' + item['creteria'][2];
                              } else {
                                   for (let key in item['creteria']) {
                                        value.push(key + ':' + ' ' + item['creteria'][key][0] + ', ' + item['creteria'][key][1] + ', ' + item['creteria'][key][2] + ' ');
                                   }
                              }
                         }
                         return {
                              id: item['id'],
                              category: item['name'],
                              shortName: item['short_name'],
                              criteria: Array.isArray(value) ? value.map((item, index) => <Typography key={index}>{item}</Typography>) : value,
                         }
                    });
                    setTableData(temp);
               }
          });
     }, [categoryTableDataChanged]);

     return (
          <React.Fragment>
               <Box>
                    
                    <ChecklistHeader
                         setCategoryTableDataChanged={setCategoryTableDataChanged}
                         checklistTableDataChanged={checklistTableDataChanged}
                         setChecklistTableDataChanged={setChecklistTableDataChanged}
                    />

                    <SolarTable
                         title="Category Table"
                         columns={columns}
                         data={tableData}
                         loading={loading}
                         actions={
                              [
                                   {
                                        icon: () => {
                                             return <Edit 
                                                  fontSize="small" 
                                                  sx={{ color: theme => theme.palette.info.main }}
                                             />;
                                        },
                                        tooltip: "Edit Category",
                                        onClick: (event, rowData) => {
                                             editCategory(rowData);
                                        }
                                   },
                                   {
                                        icon: () => {
                                             return <Delete 
                                                  fontSize="small" 
                                                  sx={{ color: theme => theme.palette.error.main }} 
                                             />;
                                        },
                                        tooltip: "Delete Category",
                                        onClick: (event, rowData) => {
                                             handleDeleteCategory(rowData);
                                        }
                                   },
                              ]
                         }
                         detailPanel={[
                              {
                                   icon: () => {
                                        return <KeyboardArrowRightIcon />
                                   },
                                   tooltip: 'Show Checklist',
                                   render: rowData => {
                                        return (
                                             <ChecklistTable
                                                  rowData={rowData}
                                                  checklistTableDataChanged={checklistTableDataChanged}
                                                  setChecklistTableDataChanged={setChecklistTableDataChanged}
                                             />
                                        )
                                   },
                              },
                         ]}
                    />
               </Box>

               <CustomDialog openDialog={openDialog}>
                    <AddCategory setOpenDialog={setOpenDialog} action={action} categoryId={categoryId} setCategoryTableDataChanged={setCategoryTableDataChanged} />
               </CustomDialog>

          </React.Fragment>
     );
}
