import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import Dropdown from '@custom/Components/Dropdown';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import AuthMethods from 'app/services';
import PageLoader from '@custom/Components/PageLoader';
import { useSnackbar } from 'notistack';
import ErrorMessage from '@custom/Components/ErrorMessage';
import MultiSelect from '@custom/Components/MultiSelect';

const useStyles = makeStyles({
     required: {
          color: '#E73145'
     },
});

export default function DeleteOem(props) {

     const classes = useStyles();
     const { enqueueSnackbar } = useSnackbar();
   
     const [inverterOemDropdown, setInverterOemDropdown] = useState([]);   
     const [loading, setLoading] = useState(false);
     const [message, setMessage] = useState('');

     const [selectedOem, setSelectedOem] = useState([]);

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const saveSiteForm = () => {
        setLoading(true);
        let payload = {
            "names": selectedOem
        }

        AuthMethods['inverterOem'].deleteOem(payload, setMessage, setLoading);
     };

     const saveForm = (e) => {
          e.preventDefault();

          let isValid = true;

          if (isValid) {
               saveSiteForm();
          }
     };

     useEffect(() => {
          setLoading(true);

          AuthMethods['inverterOem'].getAllInverterOem(setLoading).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = [];
                    data['data'].forEach(item => {
                         let obj = {
                              id: item.id,
                              value: item.name
                         }
                         arr.push(obj);
                    });
                    setInverterOemDropdown(arr);
               }
          });
     }, []);

     useEffect(() => {
        if (message !== '') {
                  if (message === 'Success') {
                       props.setOpenDialog(false);
                       enqueueSnackbar('OEM(s) deleted successfully', {
                            variant: 'success'
                       });
                  } else {
                       enqueueSnackbar(message, {
                            variant: 'error'
                       });
                  }
                  setMessage('');
        }

   }, [message]);

   return (

          <React.Fragment>

               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Delete OEM(s)
               </DialogTitle>
          
               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2} mb={2}>
                              
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Select OEM(s) to delete
                                   </InputLabel>
                                   <MultiSelect
                                        placeholder="Select OEM"
                                        selected={selectedOem}
                                        setSelected={setSelectedOem}
                                        dropdown={inverterOemDropdown}
                                    />
                              </Grid>
                            
                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton disabled={selectedOem.length === 0 ? true : false} variant="contained" color="primary" icon="" onClick={saveForm}>
                                   Delete
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>
          </React.Fragment>
     );
}
