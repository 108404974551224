import React, { useEffect, useState } from 'react';
import SolarTable from "@custom/Components/SolarTable";
import { Box, InputLabel, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import CustomButton from "@custom/Components/CustomButton";
import Dropdown from '@custom/Components/Dropdown';
import MultiSelect from '@custom/Components/MultiSelect';
import { makeStyles } from '@mui/styles';
import ErrorMessage from '@custom/Components/ErrorMessage';
import CustomDialog from '@custom/Components/CustomDialog';
import AddEquipment from './AddEquipment';
import AuthMethods from 'app/services';
import { isUndefined } from 'lodash';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { siteIds } from '@custom/Functions/helperFunction';

const useStyles = makeStyles({
     required: {
          color: '#E73145'
     },
});

const columns = [
     { 
          title: "ID", 
          field: "id",
          filtering: false, 
          hidden: true, 
          hiddenByColumnsButton: true 
     },
 
     { 
          title: "Site Name",
          field: "siteName",
          filtering: false, 
     },
 
     { 
          title: "Equipment Type", 
          field: "type",
          filtering: false,
     },

     { 
          title: "Equipment", 
          field: "eqName",
          filtering: false, 
     },
];

export default function Equipment() {

     const classes = useStyles();
     const MySwal = withReactContent(Swal);

     const [state, setState] = useState('');
     const [stateDropdown, setStateDropdown] = useState([]);
     const [stateError, setStateError] = useState('');
     const [selectedSite, setSelectedSite] = useState([]);
     const [siteDropdown, setSiteDropdown] = useState([]);
     const [siteError, setSiteError] = useState('');
     const [showTable, setShowTable] = useState(false);
     const [openDialog, setOpenDialog] = useState(false);
     const [loading, setLoading] = useState(false);
     const [tableData, setTableData] = useState([]);
     const [siteId, setSiteId] = useState([]);

     const saveEquipment = () => {
          setLoading(true);
          setShowTable(true);
          setTableData([]);
          AuthMethods['equipment'].getEquipmentBySite(siteId, setLoading).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let temp = data['data'].map((item, index) => {
                         return {
                              index: index,
                              id: item.id,
                              siteName: item.site_name,
                              type: item.type,
                              eqName: item.name
                         }
                    });
                    setTableData(temp);
               }
          });
     }

     const handleViewTable = (e) => {
          e.preventDefault();

          let isValid = true;
          
          if (!state) {
               setStateError('This field is required');
               isValid = false;
          }

          if (selectedSite.length === 0) {
               setSiteError('This field is required');
               isValid = false;
          }

          if (isValid) {
               saveEquipment();
          }
     };

     const handleDialogOpen = () => {
          setOpenDialog(true);
     };

     useEffect(() => {
          if (selectedSite.length > 0) {

               let sites = siteIds(siteDropdown, selectedSite);
               // console.log(siteIds);
               setSiteId(sites);
               setSiteError('');
          }

     }, [selectedSite]);
     
     useEffect(() => {
          if (state) {
               let siteData = {
                    'state_id': state,
               };
               AuthMethods['site'].getSitesByStateId(siteData).then(data => {
                    // console.log(data);
                    if (data['data'].length > 0) {
                         let arr = [];
                         data['data'].forEach(item => {
                              let obj = {
                                   id: item.id,
                                   value: item.name
                              }
                              arr.push(obj);
                         });
                         setSiteDropdown(arr);
                    }
               });
          }

     }, [state]);

     useEffect(() => {
          setLoading(true);
          AuthMethods['state'].getAllStates(setLoading).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    
                    let arr = [];
                    data['data'].forEach(item => {
                         let obj = {
                              id: item.id,
                              name: item.name
                         }
                         arr.push(obj);
                    });

                    // console.log(arr);
                    setStateDropdown(arr);
               }
          });
     }, []);

     const handleDelete = (rowData) => {
          MySwal.fire({
               title: 'Are you sure to delete equipment(s)?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Yes, delete!',
               cancelButtonText: 'No, cancel!',
               reverseButtons: true,
          }).then(result => {
               if (result.value) {
                    setLoading(true);
                    const idsToDelete = rowData.map(item => item.id);

                    AuthMethods['equipment'].deleteEquipment(idsToDelete)
                    .then(data => {
                    let tempData = [...tableData];
                    tempData = tempData.filter(item => 
                         !idsToDelete.includes(item.id)
                    );
                    setTableData(tempData);

                    Swal.fire('Deleted!', 'Equipment(s) has/have been deleted', 'success');
                    setLoading(false);
                    })
                    .catch(error => {
                    Swal.fire('Error!', 'Error occurred while deleting', '');
                    setLoading(false);
                    });
               } else if (
                   result.dismiss === Swal.DismissReason.cancel
               ) {
                   Swal.fire('Cancelled!', 'Equipment has not been deleted', 'error');
               }
          });
     };

     function customExportCsv(columnIndices, columnNames, data, fileName) {
     // Filter and rename columns
     const filteredData = data.map(row => {
          let newRow = {};
          columnIndices.forEach((index, i) => {
          const columnName = columnNames[i];
          const dataKey = Object.keys(row)[index];
          newRow[columnName] = row[dataKey];
          });
          return newRow;
     });
     // Convert data to CSV string
     let csvContent = "data:text/csv;charset=utf-8," 
          + columnNames.join(",") + "\n" 
          + filteredData.map(e => Object.values(e).join(",")).join("\n");
     
     var encodedUri = encodeURI(csvContent);
     var link = document.createElement("a");
     link.setAttribute("href", encodedUri);
     link.setAttribute("download", fileName + ".csv");
     document.body.appendChild(link); 
     
     link.click(); 
     document.body.removeChild(link); 
     }

     return (
          <React.Fragment>
               <Box display="flex" justifyContent="space-between" mb={3}>
                    <Box>
                         <Typography
                              sx={{ 
                              color: theme => theme.palette.text.primary,
                              fontSize: '21px',
                              fontWeight: 'bold'
                              }}
                              >
                                   Equipment
                         </Typography>
                    </Box>
                    <Box>
                         <CustomButton
                              variant="contained"
                              color="primary"
                              icon="getAddToQueueIcon"
                              onClick={handleDialogOpen}
                              >
                                   Import Equipment
                         </CustomButton>
                    </Box>
               </Box>

               <Box display="flex" flexWrap="wrap" alignItems="flex-end" mb={3}>
                    <Box sx={{ p: 1, width: 200 }}>
                         <InputLabel
                              sx={{ 
                                   color: theme => theme.palette.text.secondary,
                                   fontSize: '13px',
                                   fontWeight: 'bold',
                                   pb: '3px'
                              }}
                         >
                              State <span className={classes.required}>*</span>
                         </InputLabel>
                         <Dropdown
                              labelkey="id"
                              labelname="name"
                              name="state"
                              placeholder="Select State"
                              value={state}
                              dropdown={stateDropdown}
                              onChange={e => {
                                   setState(e.target.value);
                                   setSelectedSite([]);
                                   setSiteDropdown([]);
                                   setStateError('');
                              }}
                              disabled={false}
                         />
                         <ErrorMessage>{stateError}</ErrorMessage>
                    </Box>
                    <Box sx={{ p: 1, width: 200 }}>
                         <InputLabel
                              sx={{ 
                                   color: theme => theme.palette.text.secondary,
                                   fontSize: '13px',
                                   fontWeight: 'bold',
                                   pb: '3px'
                              }}
                         >
                              Site <span className={classes.required}>*</span>
                         </InputLabel>
                         <MultiSelect
                              placeholder="Select Sites"
                              selected={selectedSite}
                              setSelected={setSelectedSite}
                              dropdown={siteDropdown}
                         />
                         <ErrorMessage>{siteError}</ErrorMessage>
                    </Box>
                    <Box sx={{ p: 1, pb: 2.4, width: 200 }}>
                         <CustomButton variant="contained" color="primary" icon="" onClick={handleViewTable}>
                              View
                         </CustomButton>
                    </Box>
                    
               </Box>

               {showTable && (
                    <SolarTable
                         title="Equipment Table"
                         columns={columns}
                         data={tableData}
                         filtering={false}
                         loading={loading}
                         export={true}
                         selection={true}
                         exportFunc={() => customExportCsv([3, 4], ["type", "name"], tableData, "Equipment_Table")}
                         actions={[
                              {
                                   icon: () => {
                                        return <Delete 
                                             fontSize="small" 
                                             sx={{ color: theme => theme.palette.error.main }} 
                                        />;
                                   },
                                   tooltip: "Delete Equipment",
                                   onClick: (event, rowData) => {
                                        handleDelete(rowData)
                                        console.log(rowData)
                                   }
                              },
                              
                         ]}
                         detailPanel={[]}
                    />
               )}

               <CustomDialog openDialog={openDialog}>
                    <AddEquipment setOpenDialog={setOpenDialog} />
               </CustomDialog>

          </React.Fragment>
     );
}
