import axios from "../config";

const AuditorEquipment = {

     getAuditorSummary: (summaryData, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/auditor-summary', summaryData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     getEquipmentSelection: (equipmentData, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/get-equipments-selection', equipmentData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     saveSelectedEquipment: (selectedData, setTableDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/save-plan-equipments', selectedData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setTableDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage('Error');
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     submitEquipment: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditor/update-audit-plan-status', 
                         { 'audit_plan_data_id': id }
                    )
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

};

export default AuditorEquipment;
