import React, { memo } from "react";
import { Dialog } from "@mui/material";

export default memo(function CustomDialog(props) {

  return (
  
    <Dialog
      aria-labelledby="customized-dialog-title" 
      open={props.openDialog}
      fullWidth
      maxWidth={props.maxWidth ? props.maxWidth : "xs"}
      PaperProps={{
        style: {
          borderRadius: '14px',
          boxShadow: '0px 0px 4px 1px #DBD8F1',
          maxHeight: '92vh'
        }
      }}
    >
      {props.children}
    </Dialog>

  );
});
