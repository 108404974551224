import React, { useEffect, useState } from 'react';
import { Box, Card, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField, Typography } from '@mui/material';
import ErrorMessage from '@custom/Components/ErrorMessage';
import CustomButton from '@custom/Components/CustomButton';
import { makeStyles } from '@mui/styles';
import ImageDropzone from '@custom/Components/ImageDropzone';
import AuthMethods from 'app/services';
import PageLoader from '@custom/Components/PageLoader';
import _, { isNull, isUndefined } from 'lodash';
import CustomDialog from '@custom/Components/CustomDialog';
import { useSnackbar } from 'notistack';
import BasicDatePicker from '@custom/Components/BasicDatePicker';
import dayjs from 'dayjs';
import { getSeverityName } from '@custom/Functions/helperFunction';
import CommonPreview from '../AuditorSummary/CommonPreview';

const useStyles = makeStyles({
     required: {
          color: '#E73145',
     },
});

const allStatus = {
     0: '-',
     1: 'Approved',
     2: 'Rejected'
};

export default function AuditeeChecklistDetails(props) {

     const classes = useStyles();
     const { enqueueSnackbar } = useSnackbar();

     const [loading, setLoading] = useState(false);
     const [auditeeRemarks, setAuditeeRemarks] = useState('');
     const [auditeeReError, setAuditeeReError] = useState('');
     const [auditeeImError, setAuditeeImError] = useState('');

     const [auditeeImage, setAuditeeImage] = useState(null);
     const [auditeeUpImage, setAuditeeUpImage] = useState(null);
     const [date, setDate] = useState('');
     const [dateError, setDateError] = useState('');
     const [openDialog, setOpenDialog] = useState(false);
     const [openAuditorDialog, setOpenAuditorDialog] = useState(false);
     const [disable, setDisable] = useState(false);
     const [message, setMessage] = useState('');
     const [cardData, setCardData] = useState({});

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const handleAuditorPreview = () => {
          setOpenAuditorDialog(true);
     };

     const handlePreview = () => {
          setOpenDialog(true);
     };

     const saveForm = () => {

          setLoading(true);
          
          let finalDate = date['$d'].getFullYear() + '-' + ((date['$d'].getMonth() + 1) > 9 ? (date['$d'].getMonth() + 1) : '0' + (date['$d'].getMonth() + 1)) + '-' + (date['$d'].getDate() > 9 ? date['$d'].getDate() : '0' + date['$d'].getDate());

          const formData = new FormData();

          formData.append('audit_sample_data_id', props.auditSampleDataId);
          formData.append('submitted_date', finalDate);

          if (auditeeRemarks) {
               formData.append('remarks', auditeeRemarks);
          }
          if (!isNull(auditeeImage)) {
               formData.append('upload_file', auditeeImage);
          }

          // console.log([...formData]);

          AuthMethods['auditee'].saveAuditeeChecklistDetails(formData, props.setTableDataChanged, props.setCardDataChanged, setMessage, setLoading);
     };

     const handleSave = (e) => {
          e.preventDefault();

          let isValid = true;

          if (!date) {
               setDateError('This field is required');
               isValid = false;
          }
          
          if (cardData['severity'] >= 2) {
               if (!auditeeRemarks) {
                    setAuditeeReError('This field is required');
                    isValid = false;
               }
               if (!auditeeImage) {
                    setAuditeeImError('This field is required');
                    isValid = false;
               }
          }

          if (isValid) {
               saveForm();
          }
     };

     useEffect(() => {

          if (message !== '') {
               if (message === 'Success') {
                    props.setOpenDialog(false);
                    enqueueSnackbar('Details saved successfully', {
                         variant: 'success'
                    });
               } else {
                    enqueueSnackbar(message, {
                         variant: 'error'
                    });
               }
               setMessage('');
          }
          
     }, [message]);

     useEffect(() => {
          setLoading(true);
          if (props.auditSampleDataId !== '' && props.auditeeStatus !== '') {
               AuthMethods['auditee'].getAuditeeChecklistDetails(props.auditSampleDataId).then(data => {
                    // console.log(data);

                    setLoading(false);
                    if (_.size(data['data']) > 0) {

                         let date = !isNull(data['data']['submitted_date_auditee']) ? data['data']['submitted_date_auditee'].split('-') : '';
                         let finalDate = date ? dayjs(date[1] + '/' + date[2] + '/' + date[0]) : '';

                         setCardData(data['data']);
                         setDate(finalDate);
                         setAuditeeRemarks(!isNull(data['data']['auditee_remarks']) ? data['data']['auditee_remarks'] : '');
                         setAuditeeUpImage(data['data']['auditee_image_url']);
                    }
               });
          }
     }, [props.auditSampleDataId, props.auditeeStatus]);

     return (

          <React.Fragment>

               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Checklist Details
               </DialogTitle>
          
               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2}>
                         <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px',
                                             whiteSpace: 'break-spaces',
                                        }}
                                   >
                                        Equipment - 
                                   </InputLabel>
                                   <Typography sx={{
                                        color: theme => theme.palette.blue.dark,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                   }}>
                                        {props.equipmentChecklistName[0]}
                                   </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px',
                                             whiteSpace: 'break-spaces',
                                        }}
                                   >
                                        Checklist Item -
                                   </InputLabel>
                                   <Typography sx={{
                                        color: theme => theme.palette.blue.dark,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                   }}>
                                        {props.equipmentChecklistName[1]}
                                   </Typography>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                   <Card
                                        sx={{
                                             p: 1,
                                             boxShadow: '0px 0px 8px 3px #DBD8F1',
                                        }}
                                   >
                                        <Grid container spacing={2}>
                                             <Grid item xs={12} sm={12}>
                                                  <Typography 
                                                       sx={{
                                                            color: theme => theme.palette.grey.main,
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                       }}
                                                  >
                                                       Auditor Submission
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={6}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Severity
                                                  </InputLabel>
                                                  <Typography 
                                                       sx={{
                                                            fontSize: '13px',
                                                       }}
                                                  >
                                                       {getSeverityName(cardData['severity'])}
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={6}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                       >
                                                       Image
                                                  </InputLabel>
                                                  {isNull(cardData['auditor_image_url']) ? (
                                                       <Typography sx={{ fontSize: '13px' }}>No Image</Typography>
                                                  ) : (
                                                       <CustomButton
                                                            variant="contained"
                                                            color="grey"
                                                            icon="getPreviewIcon" 
                                                            onClick={handleAuditorPreview}
                                                       >
                                                            Preview
                                                       </CustomButton>
                                                  )}
                                             </Grid>
                                             <Grid item xs={12} sm={12}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                       fontSize: '13px',
                                                       fontWeight: 'bold',
                                                       pb: '3px'
                                                       }}
                                                  >
                                                       Auditor Remarks 
                                                  </InputLabel>
                                                  <Typography sx={{
                                                       fontSize: '13px',
                                                  }}>
                                                       {!isNull(cardData['auditor_remarks']) ? cardData['auditor_remarks'] : '-'}
                                                  </Typography>
                                             </Grid>
                                        </Grid>
                                   </Card>
                              </Grid>

                              {(props.auditeeStatus === 5 || props.auditeeStatus === 6 || props.auditeeStatus === 7 || props.auditeeStatus === 8 || props.auditeeStatus === 9) && (
                                   <React.Fragment>

                                        {!isNull(props.reviewer1Date) && (
                                             <Grid item xs={12} sm={12}>
                                                  <Card
                                                       sx={{
                                                            p: 1,
                                                            boxShadow: '0px 0px 8px 3px #DBD8F1',
                                                       }}
                                                  >
                                                       <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12}>
                                                                 <Typography 
                                                                      sx={{
                                                                           color: theme => theme.palette.grey.main,
                                                                           fontSize: '15px',
                                                                           fontWeight: 'bold',
                                                                      }}
                                                                 >
                                                                      Reviewer1 Submission
                                                                 </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                 <InputLabel
                                                                      sx={{ color: theme => theme.palette.text.secondary,
                                                                           fontSize: '13px',
                                                                           fontWeight: 'bold',
                                                                           pb: '3px'
                                                                      }}
                                                                 >
                                                                      Issue Status
                                                                 </InputLabel>
                                                                 <Typography 
                                                                      sx={{
                                                                           fontSize: '13px',
                                                                      }}
                                                                 >
                                                                      {allStatus[cardData['reviewer1_status']]}
                                                                 </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                 <InputLabel
                                                                      sx={{ color: theme => theme.palette.text.secondary,
                                                                      fontSize: '13px',
                                                                      fontWeight: 'bold',
                                                                      pb: '3px'
                                                                      }}
                                                                 >
                                                                      Remarks 
                                                                 </InputLabel>
                                                                 <Typography sx={{
                                                                      fontSize: '13px',
                                                                 }}>
                                                                      {!isNull(cardData['reviewer1_remarks']) ? cardData['reviewer1_remarks'] : '-'}
                                                                 </Typography>
                                                            </Grid>
                                                       </Grid>
                                                  </Card>
                                             </Grid>
                                        )}

                                        {!isNull(props.reviewer2Date) && (
                                             <Grid item xs={12} sm={12}>
                                                  <Card
                                                       sx={{
                                                            p: 1,
                                                            boxShadow: '0px 0px 8px 3px #DBD8F1',
                                                       }}
                                                  >
                                                       <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12}>
                                                                 <Typography 
                                                                      sx={{
                                                                           color: theme => theme.palette.grey.main,
                                                                           fontSize: '15px',
                                                                           fontWeight: 'bold',
                                                                      }}
                                                                 >
                                                                      Reviewer2 Submission
                                                                 </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                 <InputLabel
                                                                      sx={{ color: theme => theme.palette.text.secondary,
                                                                           fontSize: '13px',
                                                                           fontWeight: 'bold',
                                                                           pb: '3px'
                                                                      }}
                                                                 >
                                                                      Issue Status
                                                                 </InputLabel>
                                                                 <Typography 
                                                                      sx={{
                                                                           fontSize: '13px',
                                                                      }}
                                                                 >
                                                                      {allStatus[cardData['is_closed']]}
                                                                 </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                 <InputLabel
                                                                      sx={{ color: theme => theme.palette.text.secondary,
                                                                      fontSize: '13px',
                                                                      fontWeight: 'bold',
                                                                      pb: '3px'
                                                                      }}
                                                                 >
                                                                      Remarks 
                                                                 </InputLabel>
                                                                 <Typography sx={{
                                                                      fontSize: '13px',
                                                                 }}>
                                                                      {!isNull(cardData['reviewer2_remarks']) ? cardData['reviewer2_remarks'] : '-'}
                                                                 </Typography>
                                                            </Grid>
                                                       </Grid>
                                                  </Card>
                                             </Grid>
                                        )}
                                        
                                   </React.Fragment>
                              )}


                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Date of Issue Rectified <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <BasicDatePicker
                                        name="date"
                                        value={date}
                                        onChange={newDate => {
                                             setDate(newDate);
                                             setDateError('');
                                        }}
                                        disabled={(props.auditeeStatus === 4 || props.auditeeStatus === 6 || props.auditeeStatus === 8) ? false : true}
                                   />
                                   <ErrorMessage>{dateError}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Remarks {cardData['severity'] >= 2 && (<span className={classes.required}>*</span>)}
                                   </InputLabel>
                                   <TextField
                                        name="auditeeRemarks"
                                        type="text"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        size="small"
                                        value={auditeeRemarks}
                                        onChange={e => {
                                             setAuditeeRemarks(e.target.value);
                                             setAuditeeReError('');
                                        }}
                                        disabled={(props.auditeeStatus === 4 || props.auditeeStatus === 6 || props.auditeeStatus === 8) ? false : true}
                                   />
                                   <ErrorMessage>{auditeeReError}</ErrorMessage>
                              </Grid>
                              {!isNull(auditeeUpImage) ? (
                                   <React.Fragment>
                                        <Grid item xs={12} sm={12}>
                                             <InputLabel
                                                  sx={{ color: theme => theme.palette.text.secondary,
                                                  fontSize: '13px',
                                                  fontWeight: 'bold',
                                                  pb: '3px'
                                                  }}
                                             >
                                                  Uploaded Image
                                             </InputLabel>
                                             <CustomButton
                                                       variant="contained"
                                                       color="grey"
                                                       icon="getPreviewIcon" 
                                                       onClick={handlePreview}
                                                       disabled={disable ? true : false}
                                                  >
                                                       Preview
                                             </CustomButton>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                        <InputLabel
                                             sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px'
                                             }}
                                        >
                                             Upload New Image
                                        </InputLabel>
                                        <ImageDropzone
                                             image={auditeeImage}
                                             setImage={setAuditeeImage} 
                                             setDisable={setDisable}
                                             auditeeStatus={props.auditeeStatus}
                                        />
                                   </Grid>
                              </React.Fragment>
                              ) : (
                                   <Grid item xs={12} sm={12}>
                                        <InputLabel
                                             sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px'
                                             }}
                                        >
                                             Upload Image {cardData['severity'] >= 2 && (<span className={classes.required}>*</span>)}
                                        </InputLabel>
                                        <ImageDropzone
                                             image={auditeeImage}
                                             setImage={setAuditeeImage} 
                                             setDisable={setDisable}
                                             auditeeStatus={props.auditeeStatus}
                                        />
                                        <ErrorMessage>{auditeeImError}</ErrorMessage>
                                   </Grid>
                              )}
                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton 
                                   variant="contained" 
                                   color="primary" icon="" 
                                   onClick={handleSave} 
                                   disabled={(props.auditeeStatus === 4 || props.auditeeStatus === 6 || props.auditeeStatus === 8) ? false : true}
                              >
                                   Save
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>

               <CustomDialog openDialog={openAuditorDialog} maxWidth="lg">
                    <CommonPreview setOpenDialog={setOpenAuditorDialog} image={cardData['auditor_image_url']} />
               </CustomDialog>

               <CustomDialog openDialog={openDialog} maxWidth="lg">
                    <CommonPreview setOpenDialog={setOpenDialog} image={auditeeUpImage} />
               </CustomDialog>

          </React.Fragment>
     );
}