import React from 'react';
import {Link, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";

const Error404 = (props) => {

    // console.log(props.noAccess);

    return (
        <React.Fragment>
            {props.noAccess ? (
                <Div sx={{
                    flex: 1,
                    flexWrap: 'wrap',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: theme => theme.spacing(4),
                }}>
                    {/* <Div sx={{display: 'inline-flex', mb: 3}}>
                        <img src={'/images/apps/undraw_page_not_found.svg'} alt="404" width={380}/>
                    </Div> */}
                    <Typography align={"center"} component={"h2"} variant={"h1"} color={"text.secondary"} mb={3}>
                        User does not have access to this page!
                    </Typography>
                    <Link href="/dashboard" underline="none">
                        <Button variant="contained">Go to dashboard</Button>
                    </Link>
                </Div>
            ) : (
                <Div sx={{
                    flex: 1,
                    flexWrap: 'wrap',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: theme => theme.spacing(4),
                }}>
                    <Div sx={{display: 'inline-flex', mb: 3}}>
                        <img src={'/images/apps/undraw_page_not_found.svg'} alt="404" width={380}/>
                    </Div>
                    <Typography align={"center"} component={"h2"} variant={"h1"} color={"text.secondary"} mb={3}>
                        Oops, an error has occurred. Page not found!
                    </Typography>
                    <Link href="/dashboard" underline="none">
                        <Button variant="contained">Go to dashboard</Button>
                    </Link>
                </Div>
            )}

        </React.Fragment>
    );
};

export default Error404;
