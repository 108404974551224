import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import SolarTable from '@custom/Components/SolarTable';
import GaugeHighcharts from '@custom/Charts/GaugeHighcharts';
import { useJumboTheme } from '@jumbo/hooks';
import ChartLoader from '@custom/Charts/ChartLoader';
import InitialCurrentCard from './InitialCurrentCard';

const columns = [
     {
          title: "ID",
          field: "id",
          filtering: false,
          hidden: true,
          hiddenByColumnsButton: true,
     },
     {
          title: "",
          field: "wtg",
          filtering: false,
     },

    
     {
          title: "HIGH",
          field: "high",
          filtering: false,
     },
     {
          title: "MID",
          field: "mid",
          filtering: false,
     },
     {
          title: "LOW",
          field: "low",
          filtering: false,
     },
     {
          title: "HS (%)",
          field: "hs",
          filtering: false,
     }
];

export default function ReportsTab(props) {

     const {theme} = useJumboTheme();
   
     return (

          <Grid container spacing={2}>
               
               <Grid item xs={12} sm={6}>
                    <Grid container spacing={1} 
                         sx={{ 
                              background: 'white', 
                              boxShadow: '0px 0px 6px 0px #DBD8F1',
                              borderRadius: '10px',
                         }}
                    >
                         <Grid item xs={12} sm={12}>
                              <Box>
                                   <Typography
                                        sx={{
                                             textAlign: 'center',
                                             fontSize: '20px',
                                             fontWeight: 'bold',
                                        }}
                                   >
                                        Health Score
                                   </Typography>
                              </Box>
                         </Grid>
                         <Grid item xs={12} sm={6}>
                              <Box
                                   sx={{ 
                                        height: '240px',
                                        p: '6px',
                                        [theme.breakpoints.down('sm')] : {
                                             mb: 1,
                                        },
                                   }}
                              >
                                   {props.chartLoadingR ? (<ChartLoader title="Loading..." />) : (
                                        <GaugeHighcharts chartData={props.initialScore} />
                                   )}
                              </Box>
                         </Grid>
                         <Grid item xs={12} sm={6}>
                              <Box
                                   sx={{ 
                                        height: '240px',
                                        p: '6px',
                                        [theme.breakpoints.down('sm')] : {
                                             mb: 1,
                                        },
                                   }}
                              >
                                   {props.chartLoadingR ? (<ChartLoader title="Loading..." />) : (
                                        <GaugeHighcharts chartData={props.currentScore} />                                   
                                   )}
                              </Box>
                         </Grid>
                    </Grid>
               </Grid>

               <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}
                         sx={{ 
                              background: 'white', 
                              boxShadow: '0px 0px 6px 0px #DBD8F1',
                              borderRadius: '10px',
                              height: '286px',
                         }}
                    >
                         {props.cardLoadingR ? (
                              <Box sx={{
                                   width: '100%',
                                   height: '100%',
                                   display: 'flex',
                                   flexDirection: 'column',
                                   justifyContent: 'center',
                                   alignItems: 'center',
                              }}>
                                   <CircularProgress />
                                   <Typography sx={{ color: theme => theme.palette.grey.main }}>Loading...</Typography>
                              </Box>
                         ) : (
                              <React.Fragment>
                                   <Grid item xs={12} sm={12} md={12}>
                                        <InitialCurrentCard 
                                             high={props.initialObservation['high']}
                                             mid={props.initialObservation['mid']}
                                             low={props.initialObservation['low']}
                                             name="INITIAL OBSERVATIONS"
                                             color="#6276F0"
                                        />
                                   </Grid>

                                   <Grid item xs={12} sm={12} md={12}>
                                        <InitialCurrentCard 
                                             high={props.pendingObservation['high']}
                                             mid={props.pendingObservation['mid']}
                                             low={props.pendingObservation['low']}
                                             name="PENDING OBSERVATIONS"
                                             color="#7A6CD5"
                                        />
                                   </Grid>
                              </React.Fragment>
                         )}
                    </Grid>
               </Grid>

               <Grid item xs={12} sm={6}>
                    <SolarTable
                         title="State Wise Intial Status"
                         columns={props.stateInitialColumn}
                         data={props.stateInitialData}
                         filtering={false}
                         loading={props.loadingR}
                         search={false}
                         columnsButton={false}
                         paging={false}
                         actions={[]}
                         detailPanel={[]}
                    />
               </Grid>
               <Grid item xs={12} sm={6}>
                    <SolarTable
                         title="State Wise Current Status"
                         columns={props.stateCurrentColumn}
                         data={props.stateCurrentData}
                         filtering={false}
                         loading={props.loadingR}
                         search={false}
                         columnsButton={false}
                         paging={false}
                         actions={[]}
                         detailPanel={[]}
                    />
               </Grid>

               <Grid item xs={12} sm={6}>
                    <SolarTable
                         title="Component Wise Intial Status"
                         columns={columns}
                         data={props.componentInitialData}
                         filtering={false}
                         loading={props.loading_1}
                         search={false}
                         columnsButton={false}
                         paging={false}
                         actions={[]}
                         detailPanel={[]}
                    />
               </Grid>
               <Grid item xs={12} sm={6}>
                    <SolarTable
                         title="Component Wise Current Status"
                         columns={columns}
                         data={props.componentCurrentData}
                         filtering={false}
                         loading={props.loading_1}
                         search={false}
                         columnsButton={false}
                         paging={false}
                         actions={[]}
                         detailPanel={[]}
                    />
               </Grid>
          </Grid>
     );
}