import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField } from '@mui/material';
import Dropdown from '@custom/Components/Dropdown';
import CustomButton from '@custom/Components/CustomButton';
import AuthMethods from 'app/services';
import PageLoader from '@custom/Components/PageLoader';
import ErrorMessage from '@custom/Components/ErrorMessage';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { isNull } from 'lodash';

const useStyles = makeStyles({
     required: {
          color: '#E73145',
     },
     textfieldClass: {
          '& .MuiOutlinedInput-input': {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
          }},
     },
});

export default function AddChecklist(props) {

     const classes = useStyles();
     const { enqueueSnackbar } = useSnackbar();
     const checklistId = props.checklistId;

     const [formData, setFormData] = useState({
          categoryName: '',
          checklistName: '',
          weightage: ''
     });
     const [errorFormData, setErrorFormData] = useState({
          categoryName: '',
          checklistName: '',
          weightage: ''
     });
     const [categoryNameDropdown, setCategoryNameDropdown] = useState([]);
     const [message, setMessage] = useState('');
     const [loading, setLoading] = useState(false);

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const saveChecklistForm = () => {

          let checklistData = {
               category: formData['categoryName'],
               name: formData['checklistName'],
               weightage: formData['weightage']
          }

          setLoading(true);

          if (props.action === 'add') {
               AuthMethods['checklist'].addChecklist(checklistData, props.setChecklistTableDataChanged, setMessage, setLoading);
          } else {
               AuthMethods['checklist'].updateChecklist(checklistId, checklistData, props.setChecklistTableDataChanged, setMessage, setLoading);
          }

     };

     const checkFormData = (name) => {
          if (!formData[name]) {
               setErrorFormData(prev => {
                    return { ...prev, [name]: 'This field is required' }
               });
               return false;
          }
          if (name === 'weightage') {
               if (parseInt(formData[name]) > 3 || parseInt(formData[name]) < 1) {
                 setErrorFormData(prev => {
                      return { ...prev, [name]: 'Value should be between 1 to 3' }
                 });
                 return false;
               }
          }

          return true;
     };

     const saveForm = (e) => {
          e.preventDefault();

          let isValid = true;

          isValid = checkFormData('categoryName') && isValid;
          isValid = checkFormData('checklistName') && isValid;
          isValid = checkFormData('weightage') && isValid;

          if (isValid) {
               saveChecklistForm();
          }
     };


     const handleChange = (e, required) => {
          const { name, value } = e.target;

          setFormData(prev => {
               return { ...prev, [name]: value };
          });

          if (required) {
               setErrorFormData(prev => {
                    return { ...prev, [name]: '' };
               });
          }
     };

     useEffect(() => {

          if (message !== '') {
               if (props.action === 'add') {
                    if (message === 'Success') {
                         props.setOpenDialog(false);
                         enqueueSnackbar('Checklist item added successfully', {
                              variant: 'success'
                         });
                    } else {
                         enqueueSnackbar(message, {
                              variant: 'error'
                         });
                    }
                    setMessage('');
               } else {
                    if (message === 'Success') {
                         props.setOpenDialog(false);
                         enqueueSnackbar('Checklist item updated successfully', {
                              variant: 'success'
                         });
                    } else {
                         enqueueSnackbar(message, {
                              variant: 'error'
                         });
                    }
                    setMessage('');
               }
          }
     }, [message]);

     useEffect(() => {
          if (props.action === 'edit') {
               setLoading(true);
               AuthMethods['checklist'].getSingleChecklist(checklistId).then(data => {
                    // console.log(data);
                    setLoading(false);
                    if (Object.entries(data['data']).length > 0) {
                         setFormData({
                              categoryName: !isNull(data['data']['category']) ? data['data']['category']['id'] : '',
                              checklistName: data['data']['name'],
                              weightage: data['data']['weightage']
                         });
                    }
                    
               });
          }

     }, [checklistId]);

     useEffect(() => {

          setLoading(true);

          AuthMethods['category'].getAllCategory(setLoading).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = [];
                    data['data'].forEach(item => {
                         let obj = {
                              id: item.id,
                              name: item.name
                         }
                         arr.push(obj);
                    });

                    setCategoryNameDropdown(arr);
               }
          });

     }, []);

     return (
          <React.Fragment>
               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    {props.action === 'edit' ? 'Edit Checklist Item' : 'Add New Checklist Item'}
               </DialogTitle>

               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2} mb={2}>
                                   <Grid item xs={12} sm={12}>
                                        <InputLabel
                                             sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px'
                                             }}
                                        >
                                             Category Name <span className={classes.required}>*</span>
                                        </InputLabel>
                                        <Dropdown
                                             labelkey="id"
                                             labelname="name"
                                             name="categoryName"
                                             placeholder="Select Category Name"
                                             value={formData['categoryName']}
                                             dropdown={categoryNameDropdown}
                                             onChange={e => handleChange(e, true)}
                                             disabled={false}
                                        />
                                        <ErrorMessage>{errorFormData['categoryName']}</ErrorMessage>
                                   </Grid>
                              {/* )} */}
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Checklist Name <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        name="checklistName"
                                        type="text"
                                        placeholder="Enter Checklist Name"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        size="small"
                                        value={formData['checklistName']}
                                        onChange={e => handleChange(e, true)}
                                   />
                                   <ErrorMessage>{errorFormData['checklistName']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Weightage <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        className={classes.textfieldClass}
                                        name="weightage"
                                        type="number"
                                        fullWidth
                                        size="small"
                                        value={formData['weightage']}
                                        onChange={e => handleChange(e, true)}
                                        placeholder={"Accepted values: 1 or 2 or 3"}
                                   />
                                   <ErrorMessage>{errorFormData['weightage']}</ErrorMessage>
                              </Grid>
                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton variant="contained" color="primary" icon="" onClick={saveForm}>
                                   {props.action === 'add' ? 'Add' : 'Update'}
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>

          </React.Fragment>
     );
}
