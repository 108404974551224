import React, { useEffect, useState } from 'react';
import { Box, Card, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, Typography } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import AuthMethods from 'app/services';
import PageLoader from '@custom/Components/PageLoader';
import _, { isNull } from 'lodash';
import CustomDialog from '@custom/Components/CustomDialog';
import { getSeverityName } from '@custom/Functions/helperFunction';
import CommonPreview from 'app/pages/AuditorSummary/CommonPreview';

const allStatus = {
     0: '-',
     1: 'Approved',
     2: 'Rejected'
};

export default function DChecklistDetails(props) {

     const [loading, setLoading] = useState(false);
     const [openDialog, setOpenDialog] = useState(false);
     const [openAuditeeDialog, setOpenAuditeeDialog] = useState(false);
     const [cardData, setCardData] = useState({});

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const handlePreview = () => {
          setOpenDialog(true);
     };

     const handleAuditeePreview = () => {
          setOpenAuditeeDialog(true);
     };

     useEffect(() => {
          setLoading(true);
          if (props.auditSampleDataId !== '' && props.reviewStatus !== '') {
               AuthMethods['reviewer'].getARChecklistDetails(props.auditSampleDataId).then(data => {
                    // console.log(data);
                    setLoading(false);
                    if (_.size(data['data']) > 0) {
                         setCardData(data['data']);
                    }
               });
          }
     }, [props.auditSampleDataId, props.reviewStatus]);

     return (

          <React.Fragment>

               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Checklist Details
               </DialogTitle>
          
               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px',
                                             whiteSpace: 'break-spaces',
                                        }}
                                   >
                                        Equipment -
                                   </InputLabel>
                                   <Typography sx={{
                                        color: theme => theme.palette.blue.dark,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                   }}>
                                        {props.equipmentChecklistName[0]}
                                   </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px',
                                             whiteSpace: 'break-spaces',
                                        }}
                                   >
                                        Checklist Item -
                                   </InputLabel>
                                   <Typography sx={{
                                        color: theme => theme.palette.blue.dark,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                   }}>
                                        {props.equipmentChecklistName[1]}
                                   </Typography>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                   <Card
                                        sx={{
                                             p: 1,
                                             boxShadow: '0px 0px 8px 3px #DBD8F1',
                                        }}
                                   >
                                        <Grid container spacing={2}>
                                             <Grid item xs={12} sm={12}>
                                                  <Typography 
                                                       sx={{
                                                            color: theme => theme.palette.grey.main,
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                       }}
                                                  >
                                                       Auditor Submission
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={6}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Severity
                                                  </InputLabel>
                                                  <Typography 
                                                       sx={{
                                                            fontSize: '13px',
                                                       }}
                                                  >
                                                       {getSeverityName(cardData['severity'])}
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={6}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Image
                                                  </InputLabel>
                                                  {isNull(cardData['auditor_image_url']) ? (
                                                       <Typography sx={{ fontSize: '13px' }}>No Image</Typography>
                                                  ) : (
                                                       <CustomButton
                                                            variant="contained"
                                                            color="grey"
                                                            icon="getPreviewIcon" 
                                                            onClick={handlePreview}
                                                       >
                                                            Preview
                                                       </CustomButton>
                                                  )}
                                             </Grid>
                                             <Grid item xs={12} sm={12}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                       fontSize: '13px',
                                                       fontWeight: 'bold',
                                                       pb: '3px'
                                                       }}
                                                  >
                                                       Remarks 
                                                  </InputLabel>
                                                  <Typography sx={{
                                                       fontSize: '13px',
                                                  }}>
                                                       {!isNull(cardData['auditor_remarks']) ? cardData['auditor_remarks'] : '-'}
                                                  </Typography>
                                             </Grid>
                                        </Grid>
                                   </Card>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                   <Card
                                        sx={{
                                             p: 1,
                                             boxShadow: '0px 0px 8px 3px #DBD8F1',
                                        }}
                                   >
                                        <Grid container spacing={2}>
                                             <Grid item xs={12} sm={12}>
                                                  <Typography 
                                                       sx={{
                                                            color: theme => theme.palette.grey.main,
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                       }}
                                                  >
                                                       Auditee Submission
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={6}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Date of Issue Rectified
                                                  </InputLabel>
                                                  <Typography 
                                                       sx={{
                                                            fontSize: '13px',
                                                       }}
                                                  >
                                                       {!isNull(cardData['submitted_date_auditee']) ? cardData['submitted_date_auditee'] : '-'}
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={6}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Image
                                                  </InputLabel>
                                                  {isNull(cardData['auditee_image_url']) ? (
                                                       <Typography sx={{ fontSize: '13px' }}>No Image</Typography>
                                                  ) : (
                                                       <CustomButton
                                                            variant="contained"
                                                            color="grey"
                                                            icon="getPreviewIcon" 
                                                            onClick={handleAuditeePreview}
                                                       >
                                                            Preview
                                                       </CustomButton>
                                                  )}
                                             </Grid>
                                             <Grid item xs={12} sm={12}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                       fontSize: '13px',
                                                       fontWeight: 'bold',
                                                       pb: '3px'
                                                       }}
                                                  >
                                                       Remarks 
                                                  </InputLabel>
                                                  <Typography 
                                                       sx={{
                                                            fontSize: '13px',
                                                       }}
                                                  >
                                                       {!isNull(cardData['auditee_remarks']) ? cardData['auditee_remarks'] : '-'}
                                                  </Typography>
                                             </Grid>
                                        </Grid>
                                   </Card>
                              </Grid>

                                   
                              <Grid item xs={12} sm={12}>
                                   <Card
                                        sx={{
                                             p: 1,
                                             boxShadow: '0px 0px 8px 3px #DBD8F1',
                                        }}
                                   >
                                        <Grid container spacing={2}>
                                             <Grid item xs={12} sm={12}>
                                                  <Typography 
                                                       sx={{
                                                            color: theme => theme.palette.grey.main,
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                       }}
                                                  >
                                                       Reviewer1 Submission
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={12}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Issue Status
                                                  </InputLabel>
                                                  <Typography 
                                                       sx={{
                                                            fontSize: '13px',
                                                       }}
                                                  >
                                                       {allStatus[cardData['reviewer1_status']]}
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={12}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                       fontSize: '13px',
                                                       fontWeight: 'bold',
                                                       pb: '3px'
                                                       }}
                                                  >
                                                       Remarks 
                                                  </InputLabel>
                                                  <Typography sx={{
                                                       fontSize: '13px',
                                                  }}>
                                                       {!isNull(cardData['reviewer1_remarks']) ? cardData['reviewer1_remarks'] : '-'}
                                                  </Typography>
                                             </Grid>
                                        </Grid>
                                   </Card>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                   <Card
                                        sx={{
                                             p: 1,
                                             boxShadow: '0px 0px 8px 3px #DBD8F1',
                                        }}
                                   >
                                        <Grid container spacing={2}>
                                             <Grid item xs={12} sm={12}>
                                                  <Typography 
                                                       sx={{
                                                            color: theme => theme.palette.grey.main,
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                       }}
                                                  >
                                                       Reviewer2 Submission
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={12}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Issue Status
                                                  </InputLabel>
                                                  <Typography 
                                                       sx={{
                                                            fontSize: '13px',
                                                       }}
                                                  >
                                                       {allStatus[cardData['is_closed']]}
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={12}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                       fontSize: '13px',
                                                       fontWeight: 'bold',
                                                       pb: '3px'
                                                       }}
                                                  >
                                                       Remarks 
                                                  </InputLabel>
                                                  <Typography 
                                                       sx={{
                                                            fontSize: '13px',
                                                       }}
                                                  >
                                                       {!isNull(cardData['reviewer2_remarks']) ? cardData['reviewer2_remarks'] : '-'}
                                                  </Typography>
                                             </Grid>
                                        </Grid>
                                   </Card>
                              </Grid>

                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton 
                                   variant="contained" 
                                   color="primary" 
                                   icon="" 
                                   disabled={true}
                              >
                                   Save
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>

               <CustomDialog openDialog={openDialog} maxWidth="lg">
                    <CommonPreview setOpenDialog={setOpenDialog} image={cardData['auditor_image_url']} />
               </CustomDialog>

               <CustomDialog openDialog={openAuditeeDialog} maxWidth="lg">
                    <CommonPreview setOpenDialog={setOpenAuditeeDialog} image={cardData['auditee_image_url']} />
               </CustomDialog>

          </React.Fragment>
     );
}