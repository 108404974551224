import axios from "../config";

const Equipment = {

     getEquipmentBySite: (siteIds, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/assets/sites', {
                         site_id: siteIds
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     uploadExcel: (data, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/assets/bulk', data)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setMessage('Success');
                         } else { 
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     deleteEquipment: (ids) => {
          return new Promise((resolve, reject) => {
            // Check if 'ids' is an array and join it with commas, otherwise use it as it is
            const idParam = Array.isArray(ids) ? ids.join(',') : ids;
        
            axios
              .delete('/asset/' + idParam)
              .then(({ data }) => {
                if (data.status === 'success') {
                  resolve(data);
                } else {
                  reject(data);
                }
              })
              .catch(function(error) {
                reject(error);
              });
          });
     }

};

export default Equipment;