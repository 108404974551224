import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Breadcrumbs, CircularProgress, Grid, Typography } from '@mui/material';
import { encryptDecrypt } from '@custom/Functions/helperFunction';
import AuthMethods from 'app/services';
import _ from 'lodash';
import SolarTable from '@custom/Components/SolarTable';
import Highcharts from 'highcharts';
import solidGauge from "highcharts/modules/solid-gauge";
import GaugeHighcharts from '@custom/Charts/GaugeHighcharts';
import { useJumboTheme } from '@jumbo/hooks';
import ChartLoader from '@custom/Charts/ChartLoader';
import { AES } from 'crypto-js';
import InitialCurrentCard from './InitialCurrentCard';

solidGauge(Highcharts);

const Reports = () => {

     const {theme} = useJumboTheme();
     const location = useLocation();

     const userId = localStorage.getItem('userId');
     let value = (location.search.length) > 0 ? encryptDecrypt(location.search) : '';
     let fyId = value !== '' ? value.split(',')[0] : '';
     let stateName = value !== '' ? value.split(',')[1] : '';
     let stateId = value !== '' ? value.split(',')[2] : '';

     const [chartLoading, setChartLoading] = useState(false);
     const [cardLoading, setCardLoading] = useState(true);
     const [loading, setLoading] = useState(false);
     const [siteInitialColumn, setSiteInitialColumn] = useState([]);
     const [siteInitialData, setSiteInitialData] = useState([]);
     const [siteCurrentColumn, setSiteCurrentColumn] = useState([]);
     const [siteCurrentData, setSiteCurrentData] = useState([]);
     const [healthScore, setHealthScore] = useState([]);
     const [initialObservation, setInitialObservation] = useState({
          'high': '',
          'mid': '',
          'low': ''
     });
     const [currentObservation, setCurrentObservation] = useState({
          'high': '',
          'mid': '',
          'low': ''
     });

     const initialScore = {

          chart: {
               type: 'solidgauge',
          },

          title: {
               enabled: true,
               text: 'Initial Score'
          },

          pane:{
               center: ['50%', '80%'],
               size: '140%',
               startAngle: -90,
               endAngle: 90,
               background: {
                    backgroundColor:
                         Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                    innerRadius: '60%',
                    outerRadius: '90%',
                    shape: 'arc'
               }
          },

          exporting: {
               enabled: false
          },

          plotOptions: {
               solidgauge: {
                    dataLabels: {
                         y: 5,
                         borderWidth: 0,
                         useHTML: true
                    }
               }
          },

          yAxis: {
               min: 0,
               max: 100,
               stops: [
                    [0, '#DDDF0D']
               ],
               lineWidth: 0,
               tickWidth: 0,
               minorTickInterval: null,
               tickAmount: 2,
               title: {
                    y: -70
               },
               labels: {
                    y: 16
               }
          },

          series: [
               {
                    name: 'initial',
                    data: [{
                         y: parseFloat(healthScore[0]),
                         radius: 60,
                         innerRadius: 90
                    }],
                    dataLabels: {
                         format:
                              '<div style="text-align:center">' +
                              '<span style="font-size:25px">{y}</span><br/>' +
                              '<span style="font-size:12px;opacity:0.4">%</span>' +
                              '</div>'
                    },
                    tooltip: {
                         valueSuffix: ' %'
                    },
               }
          ],
     };

     const currentScore = {

          chart: {
               type: 'solidgauge',
          },

          title: {
               enabled: true,
               text: 'Current Score'
          },

          pane: {
               center: ['50%', '80%'],
               size: '140%',
               startAngle: -90,
               endAngle: 90,
               background: {
                    backgroundColor:
                         Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                    innerRadius: '60%',
                    outerRadius: '90%',
                    shape: 'arc'
               }
          },

          exporting: {
               enabled: false
          },

          plotOptions: {
               solidgauge: {
                    dataLabels: {
                         y: 5,
                         borderWidth: 0,
                         useHTML: true
                    }
               }
          },

          yAxis: {
               min: 0,
               max: 100,
               stops: [
                    [0, '#55BF3B']
               ],
               lineWidth: 0,
               tickWidth: 0,
               minorTickInterval: null,
               tickAmount: 2,
               title: {
                    y: -70
               },
               labels: {
                    y: 16
               }
          },

          series: [
               {
                    name: 'current',
                    data: [{
                         y: parseFloat(healthScore[1]),
                         radius: 60,
                         innerRadius: 90
                    }],
                    dataLabels: {
                         format:
                              '<div style="text-align:center">' +
                              '<span style="font-size:25px">{y}</span><br/>' +
                              '<span style="font-size:12px;opacity:0.4">%</span>' +
                              '</div>'
                    },
                    tooltip: {
                         valueSuffix: ' %'
                    },
               }
          ],
     };

     useEffect(() => {

          // console.log(location.search);
          if (location.search.length > 0) {

               setChartLoading(true);
               
               let healthScoreData = {
                    'fy_id': fyId,
                    'user_id': userId,
                    'state_id': stateId,
               };
               AuthMethods['chartAndTable'].getHealthScore(healthScoreData, setChartLoading).then(data => {
                    // console.log(data);
                    if (_.size(data['score']) > 0) {
                         setHealthScore([data['score']['initial'], data['score']['current']]);
                    };
               });

               let observationData = {
                    'fy_id': fyId,
                    'user_id': userId,
                    'state_id': stateId,
               };

               AuthMethods['chartAndTable'].getObservations(observationData, setCardLoading).then(data => {
                    // console.log(data);
                    if (_.size(data['data']) > 0) {
                         if (_.size(data['data']['initial_observations']) > 0) {
                              let initial = {
                                   'high': data['data']['initial_observations']['high'],
                                   'mid': data['data']['initial_observations']['mid'],
                                   'low': data['data']['initial_observations']['low'],
                              };
                              setInitialObservation(initial);
                         }
                         if (_.size(data['data']['current_observations']) > 0) {
                              let current = {
                                   'high': data['data']['current_observations']['high'],
                                   'mid': data['data']['current_observations']['mid'],
                                   'low': data['data']['current_observations']['low'],
                              };
                              setCurrentObservation(current);
                         }
                    }
               });
               
               let siteInitialData = {
                    'fy_id': fyId,
                    'user_id': userId,
                    'state_id': stateId,
               };

               setLoading(true);
               AuthMethods['chartAndTable'].siteWiseData(siteInitialData, setLoading).then(data => {
                    // console.log(data);

                    if (_.size(data['data']) > 0) {
                         if (_.size(data['data']['initial']) > 0) {
                              
                              let arr = [];
                              for (let key in data['data']['initial']) {
     
                                   const valueToEncrypt = data['data']['initial'][key]['audit_plan_data_id'].toString();
                                   // Encryption
                                   const encryptedValue = AES.encrypt(valueToEncrypt, 'secret-key').toString();
     
                                   let columnObj = {
                                        title: <Link 
                                             to={`/dashboard-checklist?value=${encodeURIComponent(encryptedValue)}`}
                                             style={{ color: '#55698C' }}
                                        >
                                             {key}
                                        </Link>,
                                        field: key,
                                        filtering: false,
                                   };
                                   arr.push(columnObj);
                              }
                              setSiteInitialColumn([
                                   {
                                        title: 'ID',
                                        field: 'id',
                                        filtering: false,
                                        hidden: true,
                                        hiddenByColumnsButton: true,
                                   },
                                   {
                                        title: '',
                                        field: 'blank',
                                        filtering: false,
                                   },
                                   ...arr
                              ]);
     
                              let firstRow = {
                                   'HIGH': 'high',
                                   'MID': 'mid',
                                   'LOW': 'low',
                                   'HS (%)': 'hs',
                              };
     
                              let dataArr = [];
                              for (let row in firstRow) {
                                   let dataObj = {
                                        id: row,
                                        blank: row
                                   };
     
                                   for (let key in data['data']['initial']) {
                                        // console.log(dataObj);
                                        dataObj[key] =  dataObj['id'] === 'HS (%)' ? (data['data']['initial'][key][firstRow[row]] === 0 ? '-' : data['data']['initial'][key][firstRow[row]]) : data['data']['initial'][key][firstRow[row]];
                                   }
                                   dataArr.push(dataObj);
                              }
     
                              setSiteInitialData(dataArr);
                         }

                         if (_.size(data['data']['current']) > 0) {
                              let arr = [];
                              for (let key in data['data']['current']) {
                                   let columnObj = {
                                        title: key,
                                        field: key,
                                        filtering: false,
                                   };
                                   arr.push(columnObj);
                              }

                              setSiteCurrentColumn([
                                   {
                                        title: 'ID',
                                        field: 'id',
                                        filtering: false,
                                        hidden: true,
                                        hiddenByColumnsButton: true,
                                   },
                                   {
                                        title: '',
                                        field: 'blank',
                                        filtering: false,
                                   },
                                   ...arr
                              ]);

                              let firstRow = {
                                   'HIGH': 'high',
                                   'MID': 'mid',
                                   'LOW': 'low',
                                   'HS (%)': 'hs',
                              };

                              let dataArr = [];
                              for (let row in firstRow) {
                                   let dataObj = {
                                        id: row,
                                        blank: row
                                   };

                                   for (let key in data['data']['current']) {
                                        dataObj[key] =  dataObj['id'] === 'HS (%)' ? (data['data']['initial'][key][firstRow[row]] === 0 ? '-' : data['data']['initial'][key][firstRow[row]]) : data['data']['initial'][key][firstRow[row]];
                                   }
                                   dataArr.push(dataObj);
                              }
                              setSiteCurrentData(dataArr);
                         }
                    }
               });
          }

     }, [location.search]);

     return (
          <React.Fragment>

               <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center" mb={3}>
                    <Box>
                         <Typography
                              sx={{ 
                                   color: theme => theme.palette.text.primary,
                                   fontSize: '21px',
                                   fontWeight: 'bold'
                              }}
                              >
                                   State Details - {stateName}
                         </Typography>
                    </Box>
                    <Box>
                         <Breadcrumbs
                              aria-label="breadcrumb" 
                              sx={{
                                   color: theme => theme.palette.grey.main,
                                   fontSize: '15px',
                                   fontWeight: 'bold',
                              }}
                         >
                              <Link
                                   to={"/dashboard"} 
                                   style={{
                                        textDecoration: 'none',
                                   }}
                              >
                                   Dashboard
                              </Link>
                              <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>State Details</Typography>
                         </Breadcrumbs>
                    </Box>
               </Box>

               <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                         <Grid container spacing={1} 
                              sx={{ 
                                   background: 'white', 
                                   boxShadow: '0px 0px 6px 0px #DBD8F1',
                                   borderRadius: '10px',
                              }}
                         >
                              <Grid item xs={12} sm={12}>
                                   <Box>
                                        <Typography
                                             sx={{
                                                  textAlign: 'center',
                                                  fontSize: '20px',
                                                  fontWeight: 'bold',
                                             }}
                                        >
                                             Health Score
                                        </Typography>
                                   </Box>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <Box
                                        sx={{ 
                                             height: '240px',
                                             p: '6px',
                                             [theme.breakpoints.down('sm')] : {
                                                  mb: 1,
                                             },
                                        }}
                                   >
                                        {chartLoading ? (<ChartLoader title="Loading..." />) : (
                                             <GaugeHighcharts chartData={initialScore} />
                                        )}
                                   </Box>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <Box
                                        sx={{ 
                                             height: '240px',
                                             p: '6px',
                                             [theme.breakpoints.down('sm')] : {
                                                  mb: 1,
                                             },
                                        }}
                                   >
                                        {chartLoading ? (<ChartLoader title="Loading..." />) : (
                                             <GaugeHighcharts chartData={currentScore} />                                   
                                        )}
                                   </Box>
                              </Grid>
                         </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                         <Grid container spacing={1}
                              sx={{ 
                                   background: 'white', 
                                   boxShadow: '0px 0px 6px 0px #DBD8F1',
                                   borderRadius: '10px',
                                   height: '286px',
                              }}
                         >
                              {cardLoading ? (
                                   <Box sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                   }}>
                                        <CircularProgress />
                                        <Typography sx={{ color: theme => theme.palette.grey.main }}>Loading...</Typography>
                                   </Box>
                              ) : (
                                   <React.Fragment>
                                        <Grid item xs={12} sm={12} md={12}>
                                             <InitialCurrentCard 
                                                  high={initialObservation['high']}
                                                  mid={initialObservation['mid']}
                                                  low={initialObservation['low']}
                                                  name="INITIAL OBSERVATIONS"
                                                  color="#6276F0"
                                             />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                             <InitialCurrentCard 
                                                  high={currentObservation['high']}
                                                  mid={currentObservation['mid']}
                                                  low={currentObservation['low']}
                                                  name="CURRENT OBSERVATIONS"
                                                  color="#0BBCBC"
                                             />
                                        </Grid>
                                   </React.Fragment>
                              )}
                         </Grid>
                    </Grid>
               
                    <Grid item xs={12} sm={12} md={6}>
                         <SolarTable
                              title="Site Wise Intial Status"
                              columns={siteInitialColumn}
                              data={siteInitialData}
                              filtering={false}
                              loading={loading}
                              search={false}
                              columnsButton={false}
                              paging={false}
                              actions={[]}
                              detailPanel={[]}
                         />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                         <SolarTable
                              title="Site Wise Current Status"
                              columns={siteCurrentColumn}
                              data={siteCurrentData}
                              filtering={false}
                              loading={loading}
                              search={false}
                              columnsButton={false}
                              paging={false}
                              actions={[]}
                              detailPanel={[]}
                         />
                    </Grid>
               </Grid>
          </React.Fragment>
     );
}

export default Reports;