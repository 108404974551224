import { AES, enc } from "crypto-js";
import { isUndefined } from "lodash";

const severityDropdown  = [
    {id: 0, value: 'Ok'},
    {id: 3, value: 'High'},
    {id: 2, value: 'Medium'},
    {id: 1, value: 'Low'},
];

export const getAge = (dateString) => {
    // console.log(dateString);
    
    let today = new Date();
    let doc = new Date(dateString);
    let totalMonths = (today.getFullYear() - doc.getFullYear()) * 12 + today.getMonth() - doc.getMonth();
    totalMonths += today.getDay() < doc.getDay() ? -1 : 0;
    let years = today.getFullYear() - doc.getFullYear();
    if (doc.getMonth() > today.getMonth())
        years = years - 1;
    else if (doc.getMonth() === today.getMonth())
    if (doc.getDate() > today.getDate())
    years = years - 1;

    let days;
    let months;
 
    if (doc.getDate() > today.getDate()) {
        months = (totalMonths % 12);
        if (months == 0)
            months = 11;
        let x = today.getMonth();
        switch (x) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12: {
                let a = doc.getDate() - today.getDate();
                days = 31 - a;
                break;
            }
            default: {
                let a = doc.getDate() - today.getDate();
                days = 30 - a;
                break;
            }
        }

    }
    else {
        days = today.getDate() - doc.getDate();
        if (doc.getMonth() === today.getMonth())
            months = (totalMonths % 12);
        else
            months = (totalMonths % 12) + 1;
    }
    let age = years + '.' + months;
    // console.log(age);
    return age;
};

export const statusName = {
    0: 'Pending',
    1: 'Open',
    2: 'Equipment Selected',
    3: 'Auditor Submitted',
    4: 'Auditor Review Submitted',
    5: 'Auditee Submitted',
    6: 'Reviewer1 Rejected',
    7: 'Reviewer1 Approved',
    8: 'Reviewer2 Rejected',
    9: 'Final Complete',
};

export const encryptDecrypt = (location) => {
    const searchParams = new URLSearchParams(location);
    const encryptedValue = searchParams.get('value');

    if (encryptedValue) {
        // Decryption

        const decryptedValue = AES.decrypt(encryptedValue, 'secret-key').toString(enc.Utf8);
        // console.log(decryptedValue);
        return decryptedValue;
    }

    return false;
};

export const getSeverityName = (value) => {
    let severityObj = {};
    severityDropdown.forEach(item => {
        if (isUndefined(severityObj[item['id']])) {
            severityObj[item['id']] = '';
        }
        severityObj[item['id']] = item['value'];
    });

    return severityObj[value];
};

export const getSeverityIds = (selectedSeverity) => {

    let obj = {};
    
    severityDropdown.forEach(item => {
        if (isUndefined(obj[item['value']])) {
                obj[item['value']] = '';
        }
        obj[item['value']] = item['id'];
    });

    let ids = [];
    for (let key in obj) {
        if (selectedSeverity.includes(key)) {
            ids.push(obj[key]);
        }
    }

    return ids;

};

export const financialYearData = (fyData) => {
    let arr = [];
    let fyObj = {};
    let fyFull = {};
    fyData.forEach(item => {

        if (isUndefined(fyObj[item['short_name']])) {
            fyObj[item['short_name']] = '';
        }
        fyObj[item['short_name']] = item['id'];

        if (isUndefined(fyFull[item['id']])) {
            fyFull[item['id']] = '';
        }
        fyFull[item['id']] = item['long_name'];

        let obj = {
            id: item['id'],
            name: item['long_name']
        };
        
        arr.push(obj);
    });
    // console.log(fyObj);
    
    let currentYear = new Date().getFullYear().toString().split('')[2] + new Date().getFullYear().toString().split('')[3]; 
    let currentFy = (currentYear + '-' + (parseInt(currentYear) + 1));

    // financial year dropdown data, financial year id, financial year full name
    return [arr, fyObj[currentFy], fyFull];
}

export const siteIds = (siteDropdown, selectedSite) => {

    let obj = {};
    siteDropdown.forEach(item => {
        if (isUndefined(obj[item['value']])) {
                obj[item['value']] = '';
        }
        obj[item['value']] = item['id'];
    });
    // console.log(obj);

    let sites = [];
    for (let key in obj) {
        // console.log(key);
        if (selectedSite.includes(key)) {
            sites.push(obj[key]);
        }
    }

    return sites;
};

// calculate no. of days between two dates 
export const getDays = (dateFirst, dateSec) => {

    if (dateSec === '') {
        let month = new Date().getMonth() > 9 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1);
        let day = new Date().getDate() > 9 ? new Date().getDate() : '0' + new Date().getDate();
        let year = new Date().getFullYear();

        dateSec = month + '/' + day + '/' + year;
    }

    let date1 = new Date(dateFirst); // mm/dd/yyyy
    let date2 = new Date(dateSec);

    let timeDifference = date2.getTime() - date1.getTime();
      
    // To calculate the no. of days between two dates
    let daysDifference = timeDifference / (1000 * 3600 * 24);
      
    return daysDifference;
};
