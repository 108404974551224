import React, { useEffect, useState } from 'react';
import AuthMethods from 'app/services';
import { Box, InputLabel, Typography } from '@mui/material';
import Dropdown from '@custom/Components/Dropdown';
import CustomButton from '@custom/Components/CustomButton';
import SolarTable from '@custom/Components/SolarTable';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import _ from 'lodash';
import SitesData from './SitesData';

const stateColumns = [
     { title: 'ID', field: 'id', hidden: true, hiddenByColumnsButton: true },
     { title: 'State', field: 'state' },
     { title: 'Health Score (%)', field: 'hs' },
];

const fYDropdown  = [
     {id: 0, name: '2022-2023'},
];

const fyData = {
     0: '2022-2023',
};

const PreviousYearAuditData = () => {

     const [loading, setLoading] = useState(false);
     const [financialYear, setFinancialYear] = useState(0);
     const [showTable, setShowTable] = useState(false);
     const [stateData, setStateData] = useState([]);
     const [data, setData] = useState([]);
     const [portfolioHs, setPortfolioHs] = useState('');

     useEffect(() => {
          if (stateData.length > 0) {
               let sumHs = 0;
               let stateTotal = stateData.length;
               stateData.forEach(item => {
                    // console.log(item);
                    sumHs += parseFloat(item['hs']);
               });
               let avg = sumHs/stateTotal;
               // console.log(avg);
               setPortfolioHs(avg.toFixed(2));
          }
     }, [stateData]);

     const handleView = () => {
          if (fyData[financialYear] !== '') {
          
               setLoading(true);
               setShowTable(true);
          
               AuthMethods['common'].getPreviousYearData(fyData[financialYear], setLoading).then(data => {
                    // console.log(data);
                    if (_.size(data['data']) > 0) {
                         setData(data['data']);
                         let arr = [];
                         for (let state in data['data']) {
                              let obj = {
                                   id: state,
                                   state: state,
                                   hs: '',
                              };
                              let sumHs= 0;
                              let sumC = 0;
                              if (data['data'][state].length > 0) {
                                   data['data'][state].forEach(item => {
                                        let kWCapacity = item['dc_capacity'] * 1000;
                                        sumC += kWCapacity;

                                        let calHs = item['health_score'] * kWCapacity;
                                        sumHs += calHs; 
                                   });

                                   let stateHs = sumHs/sumC;

                                   obj['hs'] = stateHs.toFixed(2);
                              }
                              // console.log(obj);
                              arr.push(obj);
                         }
                         setStateData(arr);
                    }
               });
          }
     };

     return (

          <React.Fragment>

               <Box mb={3}>
                    <Typography
                         sx={{ 
                         color: theme => theme.palette.text.primary,
                         fontSize: '21px',
                         fontWeight: 'bold'
                         }}
                    >
                         Previous Year Audit Data
                    </Typography>
               </Box>

               <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="flex-end" mb={3}>
                    <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                         <Box sx={{ p: 1, width: 200 }}>
                              <InputLabel
                                   sx={{ 
                                        color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                   }}
                              >
                                   Financial Year
                              </InputLabel>
                              <Dropdown
                                   labelkey="id"
                                   labelname="name"
                                   name="financialYear"
                                   placeholder="Select Financial Year"
                                   value={financialYear}
                                   dropdown={fYDropdown}
                                   onChange={e => {
                                        setFinancialYear(e.target.value);
                                        setPortfolioHs('');
                                        setStateData([]);
                                   }}
                                   disabled={false}
                              />
                         </Box>
                         <Box p={1}>
                              <CustomButton
                                   variant="contained"
                                   color="primary"
                                   icon=""
                                   onClick={handleView}
                                   >
                                        View
                              </CustomButton>
                         </Box>
                    </Box>
                    {showTable && (
                         <Box
                              sx={{ 
                                   p: 1,
                                   m: .6,
                                   background: theme => theme.palette.purple.main,
                                   borderRadius: '10px',
                                   boxShadow: '1px 1px 8px 3px #DBD8F1',
                              }}
                         >
                              <Typography sx={{ color: theme => theme.palette.secondary.main, fontSize: '16px', fontWeight: 'bold', }}>Portfolio Health Score (%) - {portfolioHs}</Typography>
                         </Box>
                    )}
                  
               </Box>

               {showTable && (
                    <SolarTable
                         title="State Table"
                         columns={stateColumns}
                         data={stateData}
                         loading={loading}
                         search={false}
                         columnsButton={false}
                         paging={false}
                         actions={[]}
                         detailPanel={[
                              {
                                   icon: () => {
                                        return <KeyboardArrowRightIcon />
                                   },
                                   tooltip: 'Show Sites',
                                   render: rowData => {
                                        // console.log(rowData);

                                        return (
                                             <SitesData
                                                  loading={loading}
                                                  rowData={rowData}
                                                  data={data}
                                             />
                                        )
                                   },
                              },
                         ]}
                    />
               )}

          
          </React.Fragment>
          
          
     )
};

export default PreviousYearAuditData;
