import React, { Suspense, useEffect, useState } from 'react';
import {Avatar, IconButton} from "@mui/material";
import menus from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import {DrawerHeader} from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import {SIDEBAR_STYLES, SIDEBAR_VIEWS} from "@jumbo/utils/constants/layout";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import { isNull } from 'lodash';

const Sidebar = () => {
    
    let roles = localStorage.getItem('roles');
    
    const [newMenus, setNewMenus] = useState([]);

    useEffect(() => {
        
        if (!isNull(roles)) {
            
            if (roles.includes('admin')) {
                // console.log(menus);
                setNewMenus(menus);
            } 
            
            if (roles.includes('approver_1')) {
                // console.log(menus);
                let arr = menus.filter(item => {
                    if (item.uri === '/dashboard' || item.uri === '/audit-plan' || item.uri === '/previous-year-audit-data') {
                        return item;
                    }
                });
                setNewMenus(prev => {
                    return [...new Set([...prev, ...arr])];
                });
            }

            if (roles.includes('approver_2')) {
                // console.log(menus);
                let arr = menus.filter(item => {
                    if (item.uri === '/dashboard' || item.uri === '/audit-plan' || item.uri === '/previous-year-audit-data') {
                        return item;
                    }
                });
                // console.log(arr);
                setNewMenus(prev => {
                    return [...new Set([...prev, ...arr])];
                });
            } 
            
            if (roles.includes('auditee')) {
                let arr = menus.filter(item => {
                    if (item.uri === '/auditee-summary') {
                        return item;
                    }
                });
                // console.log(arr);
                setNewMenus(prev => {
                    return [...new Set([...prev, ...arr])];
                });
            } 
            
            if (roles.includes('auditor')) {
                let arr = menus.filter(item => {
                    if (item.uri === '/auditor-summary') {
                        return item;
                    }
                });
                // console.log(arr);
                setNewMenus(prev => {
                    // console.log(prev);
                    return [...new Set([...prev, ...arr])];
                });
            } 
            
            if (roles.includes('reviewer_1')) {
                let arr = menus.filter(item => {
                    if (item.uri === '/dashboard' || item.uri === '/reviewer-summary') {
                        return item;
                    }
                });
                // console.log(arr);
                setNewMenus(prev => {
                    return [...new Set([...prev, ...arr])];
                });
            }

            if (roles.includes('reviewer_2')) {
                let arr = menus.filter(item => {
                    if (item.uri === '/dashboard' || item.uri === '/reviewer-summary') {
                        return item;
                    }
                });
                // console.log(arr);
                setNewMenus(prev => {
                    return [...new Set([...prev, ...arr])];
                });
            }

            if (roles.includes('cluster_in_charge')) {
                // console.log(menus);
                let arr = menus.filter(item => {
                    if (item.uri === '/dashboard') {
                        return item;
                    }
                });
                // console.log(arr);
                setNewMenus(prev => {
                    return [...new Set([...prev, ...arr])];
                });
            } 
            
            if (roles.includes('management')) {
                // console.log(menus);
                let arr = menus.filter(item => {
                    if (item.uri === '/dashboard' || item.uri === '/previous-year-audit-data') {
                        return item;
                    }
                });
                // console.log(arr);
                setNewMenus(prev => {
                    return [...new Set([...prev, ...arr])];
                });
            } 
            
        }

    }, [roles]);
    
    return (
        <React.Fragment>
            <SidebarHeader/>
            <JumboScrollbar
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                px: 3
                            }}
                        >
                            <SidebarSkeleton/>
                        </Div>
                    }
                >
                    <JumboVerticalNavbar translate items={newMenus} />
                </Suspense>
            </JumboScrollbar>
        </React.Fragment>
    );
};

const SidebarHeader = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();

    return (
        <React.Fragment>
            {
                sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <DrawerHeader sx={{ mb: 1 }}>
                    <Avatar
                        alt="Logo" src={'/images/auth/favicon.png'}
                        variant="square"
                    />
                    {
                        sidebarOptions?.view !== SIDEBAR_VIEWS.MINI &&
                        <Zoom in={sidebarOptions?.open}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ml: 0, mr: -1.5}}
                                onClick={() => setSidebarOptions({open: false})}
                            >
                                <MenuOpenIcon/>
                            </IconButton>
                        </Zoom>
                    }
                </DrawerHeader>
            }
        </React.Fragment>
    )
};

export default Sidebar;
