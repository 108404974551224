import axios from "../config";

const Auditee = {

     getAuditeeSummary: (auditeeData, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditee/auditee-summary', auditeeData)
                    .then(({ data }) =>{
                         // console.log(data);
                         if (data.status == 'success') {
                              resolve(data);
                         } else { 
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     getAuditeeSiteDetails: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditee/get-auditee-site-details', {
                         'audit_plan_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     getAuditeeChecklistCategory: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditee/get-checklist-category', {
                         'audit_plan_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else { 
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     getAuditeeChecklist: (auditeeChecklistData, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditee/get-auditee-checklist-details', auditeeChecklistData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status == 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                    });
          });
     },

     getAuditeeChecklistDetails: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditee/get-auditee-checklist-sample-data', {
                         'audit_sample_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     saveAuditeeChecklistDetails: (checklistData, setTableDataChanged, setCardDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditee/save-auditee-checklist-sample-data', checklistData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setTableDataChanged(1);
                              setCardDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage('Error');
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     submitAuditeeChecklist: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/auditee/submit-auditee-checklist', {
                         'audit_plan_data_id': id
                    })
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

};

export default Auditee;