import React, { useEffect, useState } from 'react';
import { Box, Card, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField, Typography } from '@mui/material';
import Dropdown from '@custom/Components/Dropdown';
import CustomButton from '@custom/Components/CustomButton';
import { makeStyles } from '@mui/styles';
import AuthMethods from 'app/services';
import PageLoader from '@custom/Components/PageLoader';
import _, { isNull } from 'lodash';
import CustomDialog from '@custom/Components/CustomDialog';
import { useSnackbar } from 'notistack';
import { getSeverityName } from '@custom/Functions/helperFunction';
import ErrorMessage from '@custom/Components/ErrorMessage';
import CommonPreview from '../AuditorSummary/CommonPreview';

const useStyles = makeStyles({
     required: {
          color: '#E73145',
     },
});

const issueStatusDropdown = [ 
     { id: 1, name: 'Approve' }, 
     { id: 2, name: 'Reject' }, 
];

const allStatus = {
     0: '-',
     1: 'Approved',
     2: 'Rejected'
};

export default function ReviewChecklistDetails(props) {

     const classes = useStyles();

     const userId = localStorage.getItem('userId');
     const roles = localStorage.getItem('roles').split(',');
     const { enqueueSnackbar } = useSnackbar();

     const [loading, setLoading] = useState(false);
     const [issueStatus, setIssueStatus] = useState('');
     const [issueStatusError, setIssueStatusError] = useState('');
     const [remark, setRemark] = useState('');
     const [openDialog, setOpenDialog] = useState(false);
     const [openAuditeeDialog, setOpenAuditeeDialog] = useState(false);
     const [message, setMessage] = useState('');
     const [cardData, setCardData] = useState({});

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const handlePreview = () => {
          setOpenDialog(true);
     };

     const handleAuditeePreview = () => {
          setOpenAuditeeDialog(true);
     }

     const saveForm = () => {

          let isValid = true;

          if (issueStatus === '') {
               setIssueStatusError('This field is required');
               isValid = false;
          }

          if (isValid) {

               setLoading(true);
     
               const formData = new FormData();
               
               formData.append('user_id', userId);
               formData.append('audit_sample_data_id', props.auditSampleDataId);
               formData.append('status', issueStatus);
               formData.append('remark', remark);

               AuthMethods['reviewer'].saveARChecklistDetails(formData, props.setTableDataChanged, props.setCardDataChanged, setMessage, setLoading);
          }

     };

     useEffect(() => {

          if (message !== '') {
               if (message === 'Success') {
                    props.setOpenDialog(false);
                    enqueueSnackbar('Details saved successfully', {
                         variant: 'success'
                    });
               } else {
                    enqueueSnackbar(message, {
                         variant: 'error'
                    });
               }
               setMessage('');
          }
          
     }, [message]);

     useEffect(() => {
          setLoading(true);
          if (props.auditSampleDataId !== '' && props.reviewStatus !== '') {
               AuthMethods['reviewer'].getARChecklistDetails(props.auditSampleDataId).then(data => {
                    // console.log(data);
                    setLoading(false);
                    if (_.size(data['data']) > 0) {
                         setCardData(data['data']);
                         setIssueStatus(roles.includes('reviewer_1') ? (data['data']['reviewer1_status'] === 0 ? '' : data['data']['reviewer1_status']) : (data['data']['is_closed'] === 0 ? '' : data['data']['is_closed']));
                         setRemark(roles.includes('reviewer_1') ? (!isNull(data['data']['reviewer1_remarks']) ? data['data']['reviewer1_remarks'] : '') : (!isNull(data['data']['reviewer2_remarks']) ? data['data']['reviewer2_remarks'] : ''));
                    }
               });
          }
     }, [props.auditSampleDataId, props.reviewStatus]);

     return (

          <React.Fragment>

               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Checklist Details
               </DialogTitle>
          
               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px',
                                             whiteSpace: 'break-spaces',
                                        }}
                                   >
                                        Equipment -
                                   </InputLabel>
                                   <Typography sx={{
                                        color: theme => theme.palette.blue.dark,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                   }}>
                                        {props.equipmentChecklistName[0]}
                                   </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px',
                                             whiteSpace: 'break-spaces',
                                        }}
                                   >
                                        Checklist Item -
                                   </InputLabel>
                                   <Typography sx={{
                                        color: theme => theme.palette.blue.dark,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                   }}>
                                        {props.equipmentChecklistName[1]}
                                   </Typography>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                   <Card
                                        sx={{
                                             p: 1,
                                             boxShadow: '0px 0px 8px 3px #DBD8F1',
                                        }}
                                   >
                                        <Grid container spacing={2}>
                                             <Grid item xs={12} sm={12}>
                                                  <Typography 
                                                       sx={{
                                                            color: theme => theme.palette.grey.main,
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                       }}
                                                  >
                                                       Auditor Submission
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={6}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Severity
                                                  </InputLabel>
                                                  <Typography 
                                                       sx={{
                                                            fontSize: '13px',
                                                       }}
                                                  >
                                                       {getSeverityName(cardData['severity'])}
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={6}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Image
                                                  </InputLabel>
                                                  {isNull(cardData['auditor_image_url']) ? (
                                                       <Typography sx={{ fontSize: '13px' }}>No Image</Typography>
                                                  ) : (
                                                       <CustomButton
                                                            variant="contained"
                                                            color="grey"
                                                            icon="getPreviewIcon" 
                                                            onClick={handlePreview}
                                                       >
                                                            Preview
                                                       </CustomButton>
                                                  )}
                                             </Grid>
                                             <Grid item xs={12} sm={12}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                       fontSize: '13px',
                                                       fontWeight: 'bold',
                                                       pb: '3px'
                                                       }}
                                                  >
                                                       Remarks 
                                                  </InputLabel>
                                                  <Typography sx={{
                                                       fontSize: '13px',
                                                  }}>
                                                       {!isNull(cardData['auditor_remarks']) ? cardData['auditor_remarks'] : '-'}
                                                  </Typography>
                                             </Grid>
                                        </Grid>
                                   </Card>
                              </Grid>

                              <Grid item xs={12} sm={12}>
                                   <Card
                                        sx={{
                                             p: 1,
                                             boxShadow: '0px 0px 8px 3px #DBD8F1',
                                        }}
                                   >
                                        <Grid container spacing={2}>
                                             <Grid item xs={12} sm={12}>
                                                  <Typography 
                                                       sx={{
                                                            color: theme => theme.palette.grey.main,
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                       }}
                                                  >
                                                       Auditee Submission
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={6}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Date of Issue Rectified
                                                  </InputLabel>
                                                  <Typography 
                                                       sx={{
                                                            fontSize: '13px',
                                                       }}
                                                  >
                                                       {!isNull(cardData['submitted_date_auditee']) ? cardData['submitted_date_auditee'] : '-'}
                                                  </Typography>
                                             </Grid>
                                             <Grid item xs={12} sm={6}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                       }}
                                                  >
                                                       Image
                                                  </InputLabel>
                                                  {isNull(cardData['auditee_image_url']) ? (
                                                       <Typography sx={{ fontSize: '13px' }}>No Image</Typography>
                                                  ) : (
                                                       <CustomButton
                                                            variant="contained"
                                                            color="grey"
                                                            icon="getPreviewIcon" 
                                                            onClick={handleAuditeePreview}
                                                       >
                                                            Preview
                                                       </CustomButton>
                                                  )}
                                             </Grid>
                                             <Grid item xs={12} sm={12}>
                                                  <InputLabel
                                                       sx={{ color: theme => theme.palette.text.secondary,
                                                       fontSize: '13px',
                                                       fontWeight: 'bold',
                                                       pb: '3px'
                                                       }}
                                                  >
                                                       Remarks 
                                                  </InputLabel>
                                                  <Typography 
                                                       sx={{
                                                            fontSize: '13px',
                                                       }}
                                                  >
                                                       {!isNull(cardData['auditee_remarks']) ? cardData['auditee_remarks'] : '-'}
                                                  </Typography>
                                             </Grid>
                                        </Grid>
                                   </Card>
                              </Grid>

                              {roles.includes('reviewer_2') && (
                                   
                                   <Grid item xs={12} sm={12}>
                                        <Card
                                             sx={{
                                                  p: 1,
                                                  boxShadow: '0px 0px 8px 3px #DBD8F1',
                                             }}
                                        >
                                             <Grid container spacing={2}>
                                                  <Grid item xs={12} sm={12}>
                                                       <Typography 
                                                            sx={{
                                                                 color: theme => theme.palette.grey.main,
                                                                 fontSize: '15px',
                                                                 fontWeight: 'bold',
                                                            }}
                                                       >
                                                            Reviewer1 Submission
                                                       </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={12}>
                                                       <InputLabel
                                                            sx={{ color: theme => theme.palette.text.secondary,
                                                                 fontSize: '13px',
                                                                 fontWeight: 'bold',
                                                                 pb: '3px'
                                                            }}
                                                       >
                                                            Issue Status
                                                       </InputLabel>
                                                       <Typography 
                                                            sx={{
                                                                 fontSize: '13px',
                                                            }}
                                                       >
                                                            {allStatus[cardData['reviewer1_status']]}
                                                       </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={12}>
                                                       <InputLabel
                                                            sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                            }}
                                                       >
                                                            Remarks 
                                                       </InputLabel>
                                                       <Typography sx={{
                                                            fontSize: '13px',
                                                       }}>
                                                            {!isNull(cardData['reviewer1_remarks']) ? cardData['reviewer1_remarks'] : '-'}
                                                       </Typography>
                                                  </Grid>
                                             </Grid>
                                        </Card>
                                   </Grid>
                              )}

                              {(roles.includes('reviewer_1')) && (props.reviewStatus === 5 || props.reviewStatus === 6 || props.reviewStatus === 7 || props.reviewStatus === 8 || props.reviewStatus === 9) && (!isNull(props.reviewer2Date)) ? (
                                   <Grid item xs={12} sm={12}>
                                        <Card
                                             sx={{
                                                  p: 1,
                                                  boxShadow: '0px 0px 8px 3px #DBD8F1',
                                             }}
                                        >
                                             <Grid container spacing={2}>
                                                  <Grid item xs={12} sm={12}>
                                                       <Typography 
                                                            sx={{
                                                                 color: theme => theme.palette.grey.main,
                                                                 fontSize: '15px',
                                                                 fontWeight: 'bold',
                                                            }}
                                                       >
                                                            Reviewer2 Submission
                                                       </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={12}>
                                                       <InputLabel
                                                            sx={{ color: theme => theme.palette.text.secondary,
                                                                 fontSize: '13px',
                                                                 fontWeight: 'bold',
                                                                 pb: '3px'
                                                            }}
                                                       >
                                                            Issue Status
                                                       </InputLabel>
                                                       <Typography 
                                                            sx={{
                                                                 fontSize: '13px',
                                                            }}
                                                       >
                                                            {allStatus[cardData['is_closed']]}
                                                       </Typography>
                                                  </Grid>
                                                  <Grid item xs={12} sm={12}>
                                                       <InputLabel
                                                            sx={{ color: theme => theme.palette.text.secondary,
                                                            fontSize: '13px',
                                                            fontWeight: 'bold',
                                                            pb: '3px'
                                                            }}
                                                       >
                                                            Remarks 
                                                       </InputLabel>
                                                       <Typography 
                                                            sx={{
                                                                 fontSize: '13px',
                                                            }}
                                                       >
                                                            {!isNull(cardData['reviewer2_remarks']) ? cardData['reviewer2_remarks'] : '-'}
                                                       </Typography>
                                                  </Grid>
                                             </Grid>
                                        </Card>
                                   </Grid>
                              ) : null
                              }

                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                             fontSize: '13px',
                                             fontWeight: 'bold',
                                             pb: '3px'
                                        }}
                                   >
                                        Issue Status <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select Issue Status"
                                        name="issueStatus"
                                        value={issueStatus}
                                        dropdown={issueStatusDropdown}
                                        onChange={e => {
                                             setIssueStatus(e.target.value);
                                             setIssueStatusError('');
                                        }}
                                        disabled={roles.includes('reviewer_1') ? ((props.reviewStatus === 5) ? false : true) : ((props.reviewStatus === 7) ? false : true)}
                                   />
                                   <ErrorMessage>{issueStatusError}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Remarks
                                   </InputLabel>
                                   <TextField
                                        name="remark"
                                        type="text"
                                        multiline
                                        rows={2}
                                        fullWidth
                                        size="small"
                                        value={remark}
                                        onChange={e => {
                                             setRemark(e.target.value);
                                        }}
                                        disabled={roles.includes('reviewer_1') ? ((props.reviewStatus === 5) ? false : true) : ((props.reviewStatus === 7) ? false : true)} 
                                   />
                              </Grid>

                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton 
                                   variant="contained" 
                                   color="primary" icon="" 
                                   onClick={saveForm} 
                                   disabled={roles.includes('reviewer_1') ? ((props.reviewStatus === 5) ? false : true) : ((props.reviewStatus === 7) ? false : true)}
                              >
                                   Save
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>

               <CustomDialog openDialog={openDialog} maxWidth="lg">
                    <CommonPreview setOpenDialog={setOpenDialog} image={cardData['auditor_image_url']} />
               </CustomDialog>

               <CustomDialog openDialog={openAuditeeDialog} maxWidth="lg">
                    <CommonPreview setOpenDialog={setOpenAuditeeDialog} image={cardData['auditee_image_url']} />
               </CustomDialog>

          </React.Fragment>
     );
}