import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles({

     loader: {
          display: 'block',
          width: 0,
          border: '20px solid #F8ECF1',
          animation: '$custom infinite 3s linear',
          borderTopColor: '#FFABAB',
          boxShadow: '0 0 0 1px #F8ECF1',
          borderRadius: '100%',
     },

     "@keyframes custom": {
          "0%": {
               borderColor: '#F8ECF1',
               borderTopColor: '#FFABAB',
          },
          "25%": {
               borderColor: '#F8ECF1',
               borderRightColor: '#FFABAB',
          },
          "50%": {
               borderColor: '#F8ECF1',
               borderBottomColor: '#FFABAB',
          },
          "75%": {
               borderColor: '#F8ECF1',
               borderLeftColor: '#FFABAB',
          }
     }
});

export default function ChartLoader(props) {
  
     const classes = useStyles();

     return (
          <Box
               sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
               }}
          >
               <Box className={classes.loader}></Box>
               <Box pt={1}>{props.title}</Box>
          </Box>
     );
}
