import React from 'react';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { useNavigate } from 'react-router-dom';
import { DialogActions, DialogTitle } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';

export default function LoginRedirect(props) {

     const { setAuthToken } = useJumboAuth();
     const navigate = useNavigate();

     const loginRedirect = () => {
          props.setOpenDialog(false);
          setAuthToken(null);
          navigate('/login');
     };

     return (

          <React.Fragment>
               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    Redirect to Login
               </DialogTitle>

               <DialogActions>
                    <CustomButton variant="contained" color="primary" icon="" onClick={loginRedirect}>
                         Go to Login
                    </CustomButton>
               </DialogActions>

          </React.Fragment>

     );
}