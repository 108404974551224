import axios from "../config";

const ChartAndTable = {

  stateWiseData: (postdata, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/dashboard/statewise", postdata)
        .then(({ data }) => {
          if (data.status === "success") {
            resolve(data);
          } else {
            resolve(data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          reject(error);
          setLoading(false);
        });
    });
  },

  componentWiseDataOverAll: (postdata, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/dashboard/component_wise_overall", postdata)
        .then(({ data }) => {
          if (data.status === "success") {
            resolve(data);
          } else {
            resolve(data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          reject(error);
          setLoading(false);
        });
    });
  },

  componentWiseData: (compData, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/dashboard/component_wise', compData)
        .then(({ data }) => {
          // console.log(data);
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
          setLoading(false);
        })
        .catch(function(error) {
          reject(error);
          setLoading(false);
        });
    });
  },
  
  auditStatusData: (postdata, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/dashboard/audit_status", postdata)
        .then(({ data }) => {
          if (data.status === "success") {
            resolve(data);
          } else {
            resolve(data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          reject(error);
          setLoading(false);
        });
    });
  },
  
  fyWiseAuditedData: (postdata, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/dashboard/financial_year_wise" , postdata)
        .then(({ data }) => {
          if (data.status === "success") {
            resolve(data);
          } else {
            reject(data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          reject(error);
          setLoading(false);
        });
    });
  },
  
  overAllAuditStatusData: (postdata, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/dashboard/overall_status", postdata)
        .then(({ data }) => {
          
          if (data.status === "success") {
            resolve(data);
          } else {
            
            resolve(data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          reject(error);
          setLoading(false);
        });
    });
  },
  
  siteWiseData: (siteData, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/dashboard/sitewise', siteData)
        .then(({ data }) => {
          // console.log(data);
          if (data.status === 'success') {
              resolve(data);
          } else {
              reject(data);
          }
          setLoading(false);
        })
        .catch(function(error) {
          reject(error);
          setLoading(false);
        });
    });
  },

  getHealthScore: (healthScoreData, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/dashboard/get-filtered-health-score', healthScoreData)
        .then(({ data }) => {
          
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
          setLoading(false);
        })
        .catch(function(error) {
          reject(error);
          setLoading(false);
        });
    });
  },

  getObservations: (observationData, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/dashboard/observations', observationData)
        .then(({ data }) => { 
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
          setLoading(false);
        })
        .catch(function(error) {
          reject(error);
          setLoading(false);
        });
    });
  },

  getStateSite: (stateSiteData, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/dashboard/graph_data', stateSiteData)
        .then(({ data }) => {
          // console.log(data);
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data); 
          }
          setLoading(false);
        })
        .catch(function(error) {
          reject(error);
          setLoading(false);
        });
    });
  },

  getTechnicalHealthData: (technicalData, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/dashboard/get-technical-health-assessment-data', technicalData)
        .then(({ data }) => {
          // console.log(data);
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
          setLoading(false);
        })
        .catch(function(error) {
          reject(error);
          setLoading(false);
        });
    })
  },

  getIssues: (issuesData, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/dashboard/check_all_issue', issuesData)
        .then(({ data }) => {
          // console.log(data);
          if (data.status === 'success') {
            resolve(data);
          } else {
            reject(data);
          }
          setLoading(false);
        })
        .catch(function(error) {
          reject(error);
          setLoading(false);
        });
    });
  },

  downloadIssuesData: (downloadData, fileName, setLoading) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/download/all_issue_data', downloadData , {
          responseType: 'blob'
        })
        .then(({ data }) => {
        
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
        
          link.href = url;
          link.setAttribute(`download`, `${fileName}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          
          setLoading(false);
        })
        .catch(function(error) {
          reject(error);
          setLoading(false);
        });
    });
  },

};

export default ChartAndTable;