import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import ErrorMessage from '@custom/Components/ErrorMessage';
import CustomButton from '@custom/Components/CustomButton';
import AuthMethods from 'app/services';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export default function ReviewerFooter(props) {
     
     const MySwal = withReactContent(Swal);
     const navigate = useNavigate();
     const userId = localStorage.getItem('userId');

     const submitChecklist = () => {
          let isValid = true;
          if (!props.radioValue) {
               props.setRadioError('Please select an option');
               isValid = false;
          }

          if (isValid) {
               let reviewData = {
                    "audit_plan_data_id": parseInt(props.auditPlanDataId),
                    "user_id": parseInt(userId),
                    "status": parseInt(props.radioValue),
               };
     
               MySwal.fire({
                    title: 'Are you sure to submit this checklist?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, submit!',
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true,
               }).then(result => {
                    if (result.value) {
                         props.setLoading(true);
                         AuthMethods['reviewer'].submitReviewChecklist(reviewData)
                              .then(data => {
                                   // console.log(data);
                                   Swal.fire('Submited!', 'Checklist has been submitted', 'success');
                                   props.setLoading(false);
                                   navigate('/reviewer-summary');
                              })
                              .catch(error => {
                                   Swal.fire('Error occured', '');
                                   props.setLoading(false);
                              });
                    } else if (
                         result.dismiss === Swal.DismissReason.cancel
                    ) {
                         Swal.fire('Cancelled!', 'Checklist has not been submitted', 'error');
                    }
               });
          }
     };

     return (
          <Box sx={{
               display: 'flex',
               justifyContent: 'flex-end',
               alignItems: 'flex-start',
          }}>
               <Box display="flex" flexDirection="column">
                    <Box pt="4px">
                         <RadioGroup
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={props.radioValue}
                              onChange={e => {
                                   props.setRadioValue(e.target.value);
                                   props.setRadioError('');
                              }}
                         >
                              <FormControlLabel
                                   value={props.roles.includes('reviewer_1') ? '7' : '9'}
                                   disabled={props.roles.includes('reviewer_1') ? (props.reviewStatus === 5 ? false : true) : (props.reviewStatus === 7 ? false : true)}
                                   control={<Radio color="blue" />} 
                                   label={<Typography
                                        sx={{
                                             color: theme => theme.palette.blue.main,
                                             fontSize: '15px',
                                             fontWeight: 'bold',
                                        }}
                                        >
                                             Approve
                                        </Typography>
                                   }
                              />
                              <FormControlLabel 
                                   value={props.roles.includes('reviewer_1') ? '6' : '8'}
                                   disabled={props.roles.includes('reviewer_1') ? (props.reviewStatus === 5 ? false : true) : (props.reviewStatus === 7 ? false : true)}
                                   control={<Radio color="grey" />} 
                                   label={<Typography
                                        sx={{
                                             color: theme => theme.palette.grey.main,
                                             fontSize: '15px',
                                             fontWeight: 'bold',
                                        }}
                                        >
                                             Reject
                                        </Typography>
                                   } 
                              />
                         </RadioGroup>
                    </Box>
                    <Box>
                         <ErrorMessage>{props.radioError}</ErrorMessage>
                    </Box>
               </Box>
               <Box p={1}>
                    <CustomButton
                         variant="contained"
                         color="primary"
                         icon=""
                         onClick={submitChecklist}
                         disabled={props.roles.includes('reviewer_1') ? (props.reviewStatus === 5 ? false : true) : (props.reviewStatus === 7 ? false : true)}
                    >
                         Review
                    </CustomButton>
               </Box>
          </Box>
     );
}
