import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import { useJumboTheme } from '@jumbo/hooks';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import AuthMethods from 'app/services';
import { useSnackbar } from 'notistack';

export default function EquipmentSelectionHeader(props) {

     const {theme} = useJumboTheme();
     const MySwal = withReactContent(Swal);
     const {enqueueSnackbar} = useSnackbar();

     const [message, setMessage] = useState('');
     const [disabledRow, setDisabledRow] = useState(false);

     const handleSubmit = () => {
          MySwal.fire({
               title: 'Are you sure to submit the selected equipments?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Yes, submit!',
               cancelButtonText: 'No, cancel!',
               reverseButtons: true,
          }).then(result => {
               if (result.value) {
                    props.setLoading(true);
                    AuthMethods['auditorEquipment'].submitEquipment(props.auditPlanDataId)
                         .then(data => {
                              // console.log(data);
                              Swal.fire('Submited!', 'Equipments have been submitted', 'success');
                              props.setLoading(false);
                              props.setAuditSummaryChanged(prev => !prev);
                              props.setOpenDialog(false);
                         })
                         .catch(error => {
                              // console.log(error);
                              if (error.data.length > 0) {
                                   let categoriesName = '';
                                   error.data.forEach(item => {

                                        categoriesName += `<Box style='font-size: 14px; font-weight: 100; display: flex; padding: 2px 0 2px 5px;'>`;
                                        categoriesName += `<ul><li>${item}</li></ul>`;
                                        categoriesName += `</Box>`;
                                   });
                                   // console.log(categoriesName);

                                   Swal.fire({
                                        title: `<p style='font-size: 18px; padding: 10px;'>Equipments selection is not done for categories -</p>${categoriesName}<p style='font-size: 18px; padding: 10px 0 0 0;'>Please do the needful.</p>`,
                                   });
                              } else {
                                   Swal.fire('Error occured', '');
                              }
                              props.setLoading(false);
                         });
               } else if (
                    result.dismiss === Swal.DismissReason.cancel
               ) {
                    Swal.fire('Cancelled!', 'Equipments have not been submitted', 'error');
               }
          });
     };

     const saveEquipment = () => {

          // console.log(props.checkedRows);
          let eqIds = props.checkedRows.map(item => item.id);
          // console.log(eqIds);

          let selectedData = {
               audit_plan_data_id: props.auditPlanDataId,
               audit_category_id: props.checklistCategory,
               equipments: eqIds,
          };

          props.setLoading(true);
          AuthMethods['auditorEquipment'].saveSelectedEquipment(selectedData, props.setTableDataChanged, setMessage, props.setLoading);
     };

     const handleEquipment = (e) => {
          e.preventDefault();

          // console.log(props.checkedRows);
          if (props.checkedRows.length > 0) {
               let isValid = true;
               if (disabledRow) {
                    props.setTableError('Selected equipment should be equal to total samples');
                    isValid = false;
               }
     
               if (isValid) {
                    saveEquipment();
               }
          }
     };

     useEffect(() => {
          if (message !== '') {
               if (message === 'Success') {
                    enqueueSnackbar('Equipment saved successfully', {
                         variant: 'success'
                    });
               } else {
                    enqueueSnackbar(message, {
                         variant: 'error'
                    });
               }
               setMessage('');
          }

     }, [message]);
     
     useEffect(() => {

          if (props.checkedRows.length > 0) {
               if (props.checkedRows.length !== props.totalSamples[props.checklistCategory]) {
                    setDisabledRow(true);
               } else {
                    setDisabledRow(false);
               }
          }

     }, [props.checkedRows]);
     
     return (
          <Box 
               sx={{ 
                    display: 'flex', 
                    justifyContent: 'flex-end',

                    [theme.breakpoints.down('sm')]: {
                         flexWrap: 'wrap',
                         justifyContent: 'flex-start',
                    },
               }}
          >
               <Box sx={{ p: 1, pb: 2.4 }}>
                    <CustomButton
                         variant="outlined"
                         color="primary"
                         icon=""
                         onClick={handleEquipment}
                         >
                              Save
                    </CustomButton>
               </Box>
               <Box sx={{ p: 1, pb: 2.4 }}>
                    <CustomButton 
                         variant="contained" 
                         color="primary" 
                         icon=""
                         onClick={handleSubmit}
                    >
                         Submit
                    </CustomButton>
               </Box>
               
          </Box>
     );
}