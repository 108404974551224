import React, { useEffect, useState } from 'react';
import SolarTable from '@custom/Components/SolarTable';
import { Box, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import CustomButton from '@custom/Components/CustomButton';
import CustomDialog from '@custom/Components/CustomDialog';
import AddEditState from './AddEditState';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AuthMethods from 'app/services';
import { isNull } from 'lodash';

const columns = [
     { title: "ID", field: "id", hidden: true, hiddenByColumnsButton: true },
     { title: 'State Name', field: 'stateName' },
     { title: 'Short Name', field: 'shortName' },
     { title: 'State Reliability Lead', field: 'stateReliabilityLead' },
     { title: 'State Operation Lead', field: 'stateOperationLead' },
];

export default function State() {

     const MySwal = withReactContent(Swal);

     const [openDialog, setOpenDialog] = useState(false);
     const [action, setAction] = useState('');
     const [tableData, setTableData] = useState([]);
     const [loading, setLoading] = useState(false);
     const [tableDataChanged, setTableDataChanged] = useState(false);
     const [stateId, setStateId] = useState('');
     
     const handleDialogOpen = () => {
          setAction('add');
          setOpenDialog(true);
     };

     const handleEditState = (rowData) => {
          setAction('edit');
          setOpenDialog(true);
          setStateId(rowData.id);
     };

     const handleDeleteState = (rowData) => {
          MySwal.fire({
               title: 'Are you sure to delete this state?',
               text: "You won't be able to revert this!",
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Yes, delete!',
               cancelButtonText: 'No, cancel!',
               reverseButtons: true,
          }).then(result => {
               if (result.value) {
                    setLoading(true);
                    AuthMethods['state'].deleteState(rowData.id)
                         .then(data => {

                              let tempData = [...tableData];
                              tempData = tempData.filter(item => 
                                   item.id !== rowData.tableData.id
                              );
                              setTableData(tempData);

                              Swal.fire('Deleted!', 'State has been deleted', 'success');
                              setLoading(false);
                         })
                         .catch(error => {
                              Swal.fire('Error occured while deleting', '');
                              setLoading(false);
                         });
               } else if (
                    result.dismiss === Swal.DismissReason.cancel
               ) {
                    Swal.fire('Cancelled!', 'State has not been deleted', 'error');
               }
          });
     };

     useEffect(() => {
          setLoading(true);
          AuthMethods['state'].getAllStates(setLoading).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let temp = data['data'].map((item, index) => {
                         return {
                              index: index,
                              id: item['id'],
                              stateName: item['name'],
                              shortName: item['short_name'],
                              stateReliabilityLead: isNull(item['state_reliability_lead']) ? '' : item['state_reliability_lead']['name'],
                              stateOperationLead: isNull(item['state_operation_lead']) ? '' : item['state_operation_lead']['name'],
                         }
                    });
                    // console.log(temp);
                    setTableData(temp);
               }
          });

     }, [tableDataChanged]);

     return (

          <React.Fragment>

               <Box display="flex" justifyContent="space-between" mb={3}>
                    <Box>
                         <Typography
                              sx={{ 
                                   color: theme => theme.palette.text.primary,
                                   fontSize: '21px',
                                   fontWeight: 'bold'
                                   }}
                              >
                                   State
                         </Typography>
                    </Box>
                    <Box>
                         <CustomButton variant="contained" color="primary" icon="getDomainAddIcon" onClick={handleDialogOpen}>
                              Add State
                         </CustomButton>
                    </Box>
               </Box>

               <SolarTable
                    title="State Table"
                    columns={columns}
                    data={tableData}
                    loading={loading}
                    actions={[
                         {
                              icon: () => {
                                   return <Edit 
                                   fontSize="small" 
                                   sx={{ color: theme => theme.palette.info.main }}
                                   />;
                              },
                              tooltip: "Edit State",
                              onClick: (event, rowData) => {
                                   handleEditState(rowData);
                              }
                         },
                         {
                              icon: () => {
                                   return <Delete 
                                   fontSize="small" 
                                   sx={{ color: theme => theme.palette.error.main }} 
                                   />;
                              },
                              tooltip: "Delete State",
                              onClick: (event, rowData) => {
                                   handleDeleteState(rowData);
                              }
                         },
                    ]}
                    detailPanel={[]}
               />

               <CustomDialog openDialog={openDialog}>
                    <AddEditState setOpenDialog={setOpenDialog} action={action} setTableDataChanged={setTableDataChanged} stateId={stateId} />
               </CustomDialog>

          </React.Fragment>
     );
}
