import React, { useEffect, useState } from 'react';
import CustomButton from '@custom/Components/CustomButton';
import Dropdown from '@custom/Components/Dropdown';
import SolarTable from '@custom/Components/SolarTable';
import { Box, InputLabel, Typography } from '@mui/material';
import AuthMethods from 'app/services';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { financialYearData, statusName } from '@custom/Functions/helperFunction';
import { AES } from 'crypto-js';


const ReviewerSummary = () => {

     const userId = localStorage.getItem('userId');
     const roles = localStorage.getItem('roles').split(',');

     const [loading, setLoading] = useState(false);
     const [fYDropdown, setFYDropdown] = useState([]);
     const [financialYear, setFinancialYear] = useState('');
     const [showTable, setShowTable] = useState(false);
     const [tableData, setTableData] = useState([]);

     const handleAuditTable = () => {
          setShowTable(true);
     };

     const columns = [
          { 
               title: "ID", 
               field: "id",
               filtering: false, 
               hidden: true, 
               hiddenByColumnsButton: true 
          },
          { 
               title: "State",
               field: "stateName",
               filtering: false, 
          },     
          { 
               title: "Site",
               field: "siteName",
               filtering: false, 
          },
          { 
               title: "Start Date",
               field: "startDate",
               filtering: false, 
          },
          { 
               title: "End Date", 
               field: "endDate",
               filtering: false,
          },
          { 
               title: "Auditor 1", 
               field: "auditor1",
               filtering: false, 
          },
          { 
               title: "Auditor 2", 
               field: "auditor2",
               filtering: false, 
          },
          { 
               title: "Auditee", 
               field: "auditee",
               filtering: false, 
          },
          {
               title: "Status",
               field: "statusName",
               filtering: false,
          },
          {
               title: "Checklist",
               field: "chechlist",
               filtering: false,
               render: rowData => {
                    // console.log(rowData);
                    const valueToEncrypt = rowData.auditPlanDataId.toString();

                    // Encryption
                    const encryptedValue = AES.encrypt(valueToEncrypt, 'secret-key').toString();
     
                    return (
                         (rowData.status === 1 || rowData.status === 2 || rowData.status === 3 || rowData.status === 4) ? (
                              <Box p={1}>
                                   <Link
                                        to={`/auditor-review-checklist?value=${encodeURIComponent(encryptedValue)}`}
                                        style={{
                                             textDecoration: 'none',
                                        }}
                                   >
                                        <CustomButton
                                             variant="contained"
                                             color="blue"
                                             icon=""
                                        >
                                             Checklist
                                        </CustomButton>
                                   </Link>
                              </Box>
                         ) : ((rowData.status === 5 || rowData.status === 6 || rowData.status === 7 || rowData.status === 8 || rowData.status === 9) ? (
                              <Box p={1}>
                                   <Link
                                        to={`/review-checklist?value=${encodeURIComponent(encryptedValue)}`}
                                        style={{
                                             textDecoration: 'none',
                                        }}
                                   >
                                        <CustomButton
                                             variant="contained"
                                             color="blue"
                                             icon=""
                                        >
                                             Checklist
                                        </CustomButton>
                                   </Link>
                              </Box>
                         ) : null)
                    )
               }
          },
     ];

     useEffect(() => {
          if (financialYear !== '') {
               setLoading(true);
               let reviewerData = {
                    'year': financialYear,
                    'user_id': userId,
                    "role": roles.includes('reviewer_1') ? 'reviewer-1' : 'reviewer-2',
               };
               AuthMethods['reviewer'].getReviewerSummary(reviewerData, setLoading).then(data => {
                    // console.log(data);
                    if (data['data'].length > 0) {
                         let arr = [];
                         data['data'].forEach(item => {
                              let obj = {
                                   id: item['id'],
                                   auditPlanDataId: item['id'],
                                   stateName: item['state_name'],
                                   siteName: item['site_name'],
                                   startDate: item['start_date'],
                                   endDate: item['end_date'],
                                   auditor1: item['auditor1_name'],
                                   auditor2: item['auditor2_name'],
                                   auditee: item['auditee_name'],
                                   status: item['status'],
                                   statusName: statusName[item['status']],
                              };
                              arr.push(obj);
                         });
                         setTableData(arr);
                    }
               });
          }

     }, [financialYear]);

     useEffect(() => {

          AuthMethods['common'].getFinancialYear().then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = financialYearData(data['data']);
                    // console.log(arr);
                    setFYDropdown(arr[0]);
                    setFinancialYear(arr[1]);
               }
          });

     }, []);

     return (

          <React.Fragment>

               <Box mb={3}>
                    <Typography
                         sx={{ 
                         color: theme => theme.palette.text.primary,
                         fontSize: '21px',
                         fontWeight: 'bold'
                         }}
                         >
                              Reviewer Summary
                    </Typography>
               </Box>

               <Box display="flex" flexWrap="wrap" alignItems="flex-end" mb={3}>
                    <Box sx={{ p: 1, width: 200 }}>
                         <InputLabel
                              sx={{ 
                                   color: theme => theme.palette.text.secondary,
                                   fontSize: '13px',
                                   fontWeight: 'bold',
                                   pb: '3px'
                              }}
                         >
                              Financial Year
                         </InputLabel>
                         <Dropdown
                              labelkey="id"
                              labelname="name"
                              name="financialYear"
                              placeholder="Select Financial Year"
                              value={financialYear}
                              dropdown={fYDropdown}
                              onChange={e => {
                                   setFinancialYear(e.target.value);
                                   setShowTable(false);
                                   setTableData([]);
                              }}
                              disabled={false}
                         />
                    </Box>
                    <Box sx={{ p: 1, width: 200 }}>
                         <CustomButton variant="contained" color="primary" icon="" onClick={handleAuditTable}>
                              View
                         </CustomButton>
                    </Box>            
               </Box>

               {showTable && (
                    <SolarTable
                         title="Reviewer Summary Table"
                         columns={columns}
                         data={tableData}
                         filtering={false}
                         loading={loading}
                         actions={[]}
                         detailPanel={[]}
                    />
               )}

          </React.Fragment>

     );
}

export default ReviewerSummary;