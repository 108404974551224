import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import Dropdown from '@custom/Components/Dropdown';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import AuthMethods from 'app/services';
import PageLoader from '@custom/Components/PageLoader';
import ErrorMessage from '@custom/Components/ErrorMessage';
import { useSnackbar } from 'notistack';
import { isNull } from 'lodash';
import BasicDatePicker from '@custom/Components/BasicDatePicker';
import MultiSelect from '@custom/Components/MultiSelect';

const useStyles = makeStyles({
     required: {
          color: '#E73145'
     },
});

const levelDropdown = [
     { id: 'Central Inverter', name: 'Central Inverter' },
     { id: 'String Inverter', name: 'String Inverter' }
];

const trackerDropdown = [
     { id: '', name: 'None' },
     { id: 'block', name: 'Block' },
     { id: 'line', name: 'Line' }
];

export default function AddEditSite(props) {

     const classes = useStyles();
     const { enqueueSnackbar } = useSnackbar();
     const roleAuditee = {
          'short_name': 'auditee'
     };
     const roleClusterIncharge = {
          'short_name': 'cluster_in_charge'
     };
     const siteId = props.siteId;

     const [stateDropdown, setStateDropdown] = useState([]);
     const [auditeeDropdown, setAuditeeDropdown] = useState([]);
     const [inchargeDropdown, setInchargeDropdown] = useState([]);
     const [inverterOemDropdown, setInverterOemDropdown] = useState([]);   
     const [loading, setLoading] = useState(false);
     const [message, setMessage] = useState('');
     const [selectedOem, setSelectedOem] = useState([]);

     const [formData, setFormData] = useState({
          stateName: '',
          siteName: '',
          date: dayjs(new Date().toLocaleDateString()),
          level: '',
          acCapacity: '',
          dcCapacity: '',
          auditee: '',  
          incharge: '',   
          tracker: '',
          inverterOem: '',   
          spv: ''
     });

     const [errorFormData, setErrorFormData] = useState({
          stateName: '',
          siteName: '',
          date: '',
          level: '',
          acCapacity: '',
          dcCapacity: '',
          auditee: '',  
          incharge: '',
     });

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const handleChange = (e, required) => {
          const { name, value } = e.target;

          setFormData(prev => {
               return { ...prev, [name]: value };
          });

          if (required) {
               setErrorFormData(prev => {
                    return { ...prev, [name]: '' };
               });
          }

     };

     const saveSiteForm = () => {

          let finalDate = formData['date']['$d'].getFullYear() + '-' + ((formData['date']['$d'].getMonth() + 1) > 9 ? (formData['date']['$d'].getMonth() + 1) : '0' + (formData['date']['$d'].getMonth() + 1)) + '-' + (formData['date']['$d'].getDate() > 9 ? formData['date']['$d'].getDate() : '0' + formData['date']['$d'].getDate());

          function getOemIds(selectedOem, inverterOemDropdown) {
               return selectedOem.map(oem => {
                   const foundOem = inverterOemDropdown.find(item => item.value === oem);
                   return foundOem ? foundOem.id : null;
               }).filter(id => id !== null);
          }
          
          const oemByIds = getOemIds(selectedOem, inverterOemDropdown);

          let siteData = {
               state: formData['stateName'],
               name: formData['siteName'],
               commission_date: finalDate,
               monitoring_level: formData['level'],
               ac_capacity: formData['acCapacity'],
               dc_capacity: formData['dcCapacity'],
               auditee: formData['auditee'],
               cluster_incharge: formData['incharge'],
               tracker_type: formData['tracker'],
               // inverter_oem: formData['inverterOem'],
               inverter_oem: oemByIds.toString(),
               spv: formData['spv']
          }
          console.log(siteData)
          setLoading(true);
          if (props.action === 'add') {
               AuthMethods['site'].addSite(siteData, props.setTableDataChanged, setMessage, setLoading);
          } else {
               AuthMethods['site'].updateSite(siteId, siteData, props.setTableDataChanged, setMessage, setLoading);
          }

     };

     const checkFormData = (name) => {

          if (!formData[name]) {
               setErrorFormData(prev => {
                    return { ...prev, [name]: 'This field is required' };
               });
               return false;
          }
          return true;

     };

     const saveForm = (e) => {
          e.preventDefault();

          let isValid = true;

          isValid = checkFormData('stateName') && isValid;
          isValid = checkFormData('siteName') && isValid;
          isValid = checkFormData('date') && isValid;
          isValid = checkFormData('level') && isValid;
          isValid = checkFormData('acCapacity') && isValid;
          isValid = checkFormData('dcCapacity') && isValid;
          isValid = checkFormData('auditee') && isValid;
          isValid = checkFormData('incharge') && isValid;

          if (isValid) {
               saveSiteForm();
          }
     };

     useEffect(() => {
          if (message !== '') {
               if (props.action === 'add') {
                    if (message === 'Success') {
                         props.setOpenDialog(false);
                         enqueueSnackbar('Site added successfully', {
                              variant: 'success'
                         });
                    } else {
                         enqueueSnackbar(message, {
                              variant: 'error'
                         });
                    }
                    setMessage('');
               } else {
                    if (message === 'Success') {
                         props.setOpenDialog(false);
                         enqueueSnackbar('Site updated successfully', {
                              variant: 'success'
                         });
                    } else {
                         enqueueSnackbar(message, {
                              variant: 'error'
                         });
                    }
                    setMessage('');
               }
               
          }

     }, [message]);

     useEffect(() => {
          if (props.action === 'edit') {
               setLoading(true);
               AuthMethods['site'].getSiteById(siteId).then(data => {
                    // console.log(data);
                    setLoading(false);

                    if (Object.entries(data['data']).length > 0) {

                         let date = data['data']['commission_date'].split('-');
                         let finalDate = dayjs(date[1] + '/' + date[2] + '/' + date[0]);

                         setFormData({
                              stateName: !isNull(data['data']['state']) ? data['data']['state']['id']: '',
                              siteName: data['data']['name'],
                              date: finalDate,
                              level: data['data']['monitoring_level'],
                              acCapacity: data['data']['ac_capacity'],
                              dcCapacity: data['data']['dc_capacity'],
                              auditee: !isNull(data['data']['auditee']) ? data['data']['auditee']['id']: '',
                              incharge: !isNull(data['data']['cluster_incharge']) ? data['data']['cluster_incharge']['id'] : '',
                              tracker: !isNull(data['data']['tracker_type']) ? data['data']['tracker_type'] : '',
                              inverterOem: !isNull(data['data']['inverter_oem']) ? data['data']['inverter_oem']['id'] : '',
                              spv: data['data']['spv']
                         });

                         function extractNames(data) {
                              if (Array.isArray(data) && data.length > 0) {
                                const namesArray = data.map(item => item.name);
                                return namesArray;
                              } else {
                                return [];
                              }
                         }
                         const namesArray = extractNames(data['data']['inverter_oems']);
                         // console.log(data['data']['inverter_oems'])
                         setSelectedOem(namesArray)
                    }
               });

          }

     }, [siteId]);

     useEffect(() => {

          setLoading(true);
          AuthMethods['state'].getAllStates(setLoading).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = [];
                    data['data'].forEach(item => {
                         let obj = {
                              id: item.id,
                              name: item.name
                         }
                         arr.push(obj);
                    });
                    setStateDropdown(arr);
               }
          });

          AuthMethods['roles'].getUsersByRole(roleAuditee, setLoading).then(data => {
               // console.log(data);
               if (data['data'][0]['user'].length > 0) {
                    let arr = [];
                    data['data'][0]['user'].forEach(item => {
                         let obj = {
                              id: item.id,
                              name: item.name
                         }
                         arr.push(obj);
                    });
                    setAuditeeDropdown(arr);
               }
          });

          AuthMethods['roles'].getUsersByRole(roleClusterIncharge, setLoading).then(data => {
               // console.log(data);
               if (data['data'][0]['user'].length > 0) {
                    let arr = [];
                    data['data'][0]['user'].forEach(item => {
                         let obj = {
                              id: item.id,
                              name: item.name
                         }
                         arr.push(obj);
                    });
                    setInchargeDropdown(arr);
               }
          });

          AuthMethods['inverterOem'].getAllInverterOem(setLoading).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = [];
                    data['data'].forEach(item => {
                         let obj = {
                              id: item.id,
                              value: item.name
                         }
                         arr.push(obj);
                    });
                    setInverterOemDropdown(arr);
               }
          });

     }, []);
console.log(selectedOem)
     return (

          <React.Fragment>

               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    {props.action === 'add' ? 'Add New Site' : 'Edit Site'}
               </DialogTitle>
          
               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2} mb={2}>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        State Name <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select State"
                                        name="stateName"
                                        value={formData['stateName']}
                                        dropdown={stateDropdown}
                                        onChange={e => handleChange(e, true)}
                                        disabled={false}
                                   />
                                   <ErrorMessage>{errorFormData['stateName']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Site Name <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        name="siteName"
                                        type="text"
                                        fullWidth
                                        size="small"
                                        value={formData['siteName']}
                                        onChange={e => handleChange(e, true)}
                                   />
                                   <ErrorMessage>{errorFormData['siteName']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Date of Commissioning <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <BasicDatePicker
                                             name="date"
                                             value={formData['date']}
                                             onChange={newDate => {
                                                  setFormData(prev => {
                                                       return { ...prev, 'date': newDate }
                                                  });
                                                  setErrorFormData(prev => {
                                                       return { ...prev, 'date': '' }
                                                  });
                                             }}
                                   />
                                   <ErrorMessage>{errorFormData['date']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Monitoring Level <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select Monitoring Level"
                                        name="level"
                                        value={formData['level']}
                                        dropdown={levelDropdown}
                                        onChange={e => handleChange(e, true)}
                                        disabled={false}
                                   />
                                   <ErrorMessage>{errorFormData['level']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Capacity AC (MW) <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        type="number"
                                        fullWidth
                                        size="small"
                                        name="acCapacity"
                                        value={formData['acCapacity']}
                                        onChange={e => handleChange(e, true)}
                                   />
                                   <ErrorMessage>{errorFormData['acCapacity']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Capacity DC (MWp) <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <TextField
                                        type="number"
                                        fullWidth
                                        size="small"
                                        name="dcCapacity"
                                        value={formData['dcCapacity']}
                                        onChange={e => handleChange(e, true)}
                                   />
                                   <ErrorMessage>{errorFormData['dcCapacity']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Auditee <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select Auditee"
                                        name="auditee"
                                        value={formData['auditee']}
                                        dropdown={auditeeDropdown}
                                        onChange={e => handleChange(e, true)}
                                        disabled={false}
                                   />
                                   <ErrorMessage>{errorFormData['auditee']}</ErrorMessage>
                              </Grid>                              
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Cluster Incharge <span className={classes.required}>*</span>
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select Cluster Incharge"
                                        name="incharge"
                                        value={formData['incharge']}
                                        dropdown={inchargeDropdown}
                                        onChange={e => handleChange(e, true)}
                                        disabled={false}
                                   />
                                   <ErrorMessage>{errorFormData['incharge']}</ErrorMessage>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Tracker Type
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select Tracker Type"
                                        name="tracker"
                                        value={formData['tracker']}
                                        dropdown={trackerDropdown}
                                        onChange={e => handleChange(e)}
                                        disabled={false}
                                   />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Inverter OEM
                                   </InputLabel>
                                   <MultiSelect
                                        placeholder="Select Inverter OEM"
                                        selected={selectedOem}
                                        setSelected={setSelectedOem}
                                        dropdown={inverterOemDropdown}
                                   />
                                   {/* <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="Select Inverter OEM"
                                        name="inverterOem"
                                        value={formData['inverterOem']}
                                        dropdown={inverterOemDropdown}
                                        onChange={e => handleChange(e)}
                                        disabled={false}
                                   /> */}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        SPV
                                   </InputLabel>
                                   <TextField
                                        type="text"
                                        fullWidth
                                        size="small"
                                        name="spv"
                                        value={formData['spv']}
                                        onChange={e => handleChange(e)}

                                   />
                              </Grid>
                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton variant="contained" color="primary" icon="" onClick={saveForm}>
                                   {props.action === 'add' ? 'Add' : 'Update'}
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>
          </React.Fragment>
     );
}
