import React, { useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, TextField } from '@mui/material';
import CustomButton from '@custom/Components/CustomButton';
import Dropdown from '@custom/Components/Dropdown';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import AuthMethods from 'app/services';
import PageLoader from '@custom/Components/PageLoader';
import { useSnackbar } from 'notistack';
import ErrorMessage from '@custom/Components/ErrorMessage';

const useStyles = makeStyles({
     required: {
          color: '#E73145'
     },
});

export default function AddOem(props) {

     const classes = useStyles();
     const { enqueueSnackbar } = useSnackbar();
   
     const siteId = props.siteId;

     const [inverterOemDropdown, setInverterOemDropdown] = useState([]);   
     const [loading, setLoading] = useState(false);
     const [message, setMessage] = useState('');

     const [formData, setFormData] = useState({
          inverterOem: '',   
          newOem: '',
     });

     const [errorFormData, setErrorFormData] = useState({
          inverterOem: '',
          newOem: '',   
     });

     const handleDialogClose = () => {
          props.setOpenDialog(false);
     };

     const handleChange = (e, required) => {
          const { name, value } = e.target;
          if (name === 'inverterOem') {
            console.log("Selection disabled for Inverter OEM");
            return;
          }
          setFormData(prev => {
               return { ...prev, [name]: value };
          });

          if (required) {
               setErrorFormData(prev => {
                    return { ...prev, [name]: '' };
               });
          }

     };

     const saveSiteForm = () => {

        let oemData = {
            name: formData['newOem']
        }
        setLoading(true);

        AuthMethods['inverterOem'].addOem(oemData, setMessage, setLoading);
     };

     const checkFormData = (name) => {

          if (!formData[name]) {
               setErrorFormData(prev => {
                    return { ...prev, [name]: 'This field is required' };
               });
               return false;
          }
          return true;

     };

     const saveForm = (e) => {
          e.preventDefault();

          let isValid = true;

          isValid = checkFormData('newOem') && isValid;

          if (isValid) {
               saveSiteForm();
          }
     };

     useEffect(() => {
          setLoading(true);

          AuthMethods['inverterOem'].getAllInverterOem(setLoading).then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = [];
                    data['data'].forEach(item => {
                         let obj = {
                              id: item.id,
                              name: item.name
                         }
                         arr.push(obj);
                    });
                    setInverterOemDropdown(arr);
               }
          });
     }, []);

     useEffect(() => {
        if (message !== '') {
                  if (message === 'Success') {
                       props.setOpenDialog(false);
                       enqueueSnackbar('OEM added successfully', {
                            variant: 'success'
                       });
                  } else {
                       enqueueSnackbar(message, {
                            variant: 'error'
                       });
                  }
                  setMessage('');
        }

   }, [message]);

     return (

          <React.Fragment>

               {loading && <PageLoader title="Loading..." />}

               <DialogTitle sx={{ 
                    m: 0, 
                    p: 2.3, 
                    color: '#505F79', 
                    fontSize: '19px', 
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: theme => theme.palette.primary.main,
                    }}
               >
                    {props.action === 'add' ? 'Add New OEM' : 'Edit Site'}
               </DialogTitle>
          
               <form>
                    <DialogContent dividers>
                         <Grid container spacing={2} mb={2}>
                              
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        All Inverter OEMs
                                   </InputLabel>
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        placeholder="See all OEM"
                                        name="inverterOem"
                                        value={formData['inverterOem']}
                                        dropdown={inverterOemDropdown}
                                        onChange={e => handleChange(e)}
                                   />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                   <InputLabel
                                        sx={{ color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                        }}
                                   >
                                        Add New OEM *
                                   </InputLabel>
                                   <TextField
                                        type="text"
                                        fullWidth
                                        size="small"
                                        name="newOem"
                                        value={formData['newOem']}
                                        onChange={e => handleChange(e, true)}
                                   />
                                   <ErrorMessage>{errorFormData['newOem']}</ErrorMessage>
                              </Grid>
                         </Grid>
                    </DialogContent>

                    <DialogActions>
                         <Box xs={12} sm={6} sx={{ width: '100%', display:'flex', justifyContent:'space-between' }}>
                              <CustomButton variant="outlined" color="primary" onClick={handleDialogClose} icon="">
                                   Cancel
                              </CustomButton>
                              <CustomButton variant="contained" color="primary" icon="" onClick={saveForm}>
                                   {props.action === 'add' ? 'Add' : 'Update'}
                              </CustomButton>
                         </Box>
                    </DialogActions>
                         
               </form>
          </React.Fragment>
     );
}
