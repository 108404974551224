import axios from "../config";

const Site = {

     getAllSites: () => {
          return new Promise((resolve, reject) => {
               axios
                    .get('/sites')
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     getSitesByStateId: (siteData) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/sites/state', siteData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     addSite: (siteData, setTableDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .post('/site/create', siteData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setTableDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setMessage('Error');
                         setLoading(false);
                    });
          });
     },

     getSiteById: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .get('/site/' + id)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

     updateSite: (id, siteData, setTableDataChanged, setMessage, setLoading) => {
          return new Promise((resolve, reject) => {
               axios
                    .put('/site/' + id, siteData)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                              setTableDataChanged(prev => !prev);
                              setMessage('Success');
                         } else {
                              reject(data);
                              setMessage(data.message);
                         }
                         setLoading(false);
                    })
                    .catch(function(error) {
                         reject(error);
                         setLoading(false);
                         setMessage('Error');
                    });
          });
     },

     deleteSite: (id) => {
          return new Promise((resolve, reject) => {
               axios
                    .delete('/site/' + id)
                    .then(({ data }) => {
                         // console.log(data);
                         if (data.status === 'success') {
                              resolve(data);
                         } else {
                              reject(data);
                         }
                    })
                    .catch(function(error) {
                         reject(error);
                    });
          });
     },

}

export default Site;