
import React, { useEffect, useRef, useState } from 'react';
import SolarTable from "@custom/Components/SolarTable";
import { Box, InputLabel, Typography } from "@mui/material";
import CustomButton from "@custom/Components/CustomButton";
import Dropdown from '@custom/Components/Dropdown';
import BasicDatePicker from '@custom/Components/BasicDatePicker';
import dayjs from 'dayjs';
import AuthMethods from 'app/services';
import _, { isUndefined } from 'lodash';
import AuditPlanHeader from './AuditPlanHeader';
import CustomDialog from '@custom/Components/CustomDialog';
import AuditPlanChatBox from './AuditPlanChatBox';
import { CsvBuilder } from 'filefy';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { getAge } from '@custom/Functions/helperFunction';
import GanttChart from '@custom/Charts/GanttChart';
import { useSnackbar } from 'notistack';

export default function AuditPlan() {

     const userId = localStorage.getItem('userId');
     const {enqueueSnackbar} = useSnackbar();
     
     const roleName = {
          short_name: 'auditor'
     };

     const [loading, setLoading] = useState(false);
     const [fYDropdown, setFYDropdown] = useState([]);
     const [financialYear, setFinancialYear] = useState('');
     const [showTable, setShowTable] = useState(false);
     const [showCreateAudit, setShowCreateAudit] = useState(false);
     const [showViewAudit, setShowViewAudit] = useState(false);
     const [tableData, setTableData] = useState([]);
     const [auditor1Dropdown, setAuditor1Dropdown] = useState([]);
     const [auditor2Dropdown, setAuditor2Dropdown] = useState([]);
     const [tableError, setTableError] = useState('');
     const checkedRows = useRef([]);
     const [tableDataChanged, setTableDataChanged] = useState(false);
     const [auditPlanID, setAuditPlanID] = useState('');
     const [minDate, setMinDate] = useState('');
     const [maxDate, setMaxDate] = useState('');
     const [adminRole, setAdminRole] = useState(false);
     const [approver1Role, setApprover1Role] = useState(false);
     const [approver2Role, setApprover2Role] = useState(false);
     const [adminStatus, setAdminStatus] = useState('');
     const [approver1Status, setApprover1Status] = useState('');
     const [approver2Status, setApprover2Status] = useState('');
     const [openDialog, setOpenDialog] = useState(false);
     const [openDialogGantt, setOpenDialogGantt] = useState(false);
     const [showGantt, setShowGantt] = useState(false);
     const [longName, setLongName] = useState({});
     const [fy, setFY] = useState('');
     const [fyForExport, setFyForExport] = useState('');
     const [message, setMessage] = useState('');

     const columns = [
          { 
               title: "Id", 
               field: "index",
               filtering: false, 
               hidden: true, 
               hiddenByColumnsButton: true 
          },
          { 
               title: "Sr. No.", 
               field: "srNo",
               filtering: false, 
               hidden: true, 
               hiddenByColumnsButton: true 
          },
          { 
               title: "State",
               field: "stateName",
               filtering: false,
               defaultSort: 'asc',
          },     
          { 
               title: "Site",
               field: "siteName",
               filtering: false, 
          },
          { 
               title: "Auditee",
               field: "auditee",
               filtering: false, 
          },
          { 
               title: "Capacity", 
               field: "capacity",
               filtering: false,
          },
          { 
               title: "Date of Commissioning", 
               field: "doc",
               filtering: false, 
          },
          { 
               title: "Age (Yrs)", 
               field: "age",
               filtering: false,
          },
          { 
               title: "Start Date", 
               field: "startDate",
               filtering: false,
               render: rowData => {
                    // console.log(tableData);
                    return (   
                         <React.Fragment>
                              {adminRole ? (
                                   <BasicDatePicker
                                        name="startDate"
                                        minDate={dayjs(minDate)}
                                        maxDate={dayjs(maxDate)}
                                        value={tableData[rowData['index']]['startDate']}
                                        onChange={newDate => {
                                             let tempData = [...tableData];
                                             tempData[rowData['index']]['startDate'] = newDate;
                                             setTableData(tempData);
                                             updateCheckedRows(rowData.id, 'startDate', newDate);
                                             setTableError('');
                                        }}
                                        width="150px"
                                   />
                              ) : (
                                   <Typography>{tableData[rowData['index']]['startDate']}</Typography>
                              )}
                         </React.Fragment>
                    );
               }
          },
          { 
               title: "End Date", 
               field: "endDate",
               filtering: false,
               render: rowData => {
                    // console.log(rowData);
                    return (   
                         <React.Fragment>
                              {adminRole ? (
                                   <BasicDatePicker
                                        name="endDate"
                                        minDate={dayjs(minDate)}
                                        maxDate={dayjs(maxDate)}
                                        value={tableData[rowData['index']]['endDate']}
                                        onChange={newDate => {
                                             let tempData = [...tableData];
                                             tempData[rowData['index']]['endDate'] = newDate;
                                             setTableData(tempData);
                                             updateCheckedRows(rowData.id, 'endDate', newDate);
                                             setTableError('');
                                        }}
                                        width="150px"
                                   />
                              ) : (
                                   <Typography>{tableData[rowData['index']]['endDate']}</Typography>
                              )}
                         </React.Fragment>
                    );
               }
          },
          { 
               title: "Auditor 1", 
               field: "auditor1",
               filtering: false,
               render: rowData => {
                    // console.log(rowData);
                    return (
                         <React.Fragment>
                              {adminRole ? (
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        name="auditor1"
                                        placeholder="Select Auditor 1"
                                        value={tableData[rowData['index']]['auditor1']}
                                        dropdown={auditor1Dropdown}
                                        onChange={e => {
                                             let tempData = [...tableData];
                                             tempData[rowData['index']]['auditor1'] = e.target.value;
                                             setTableData(tempData);
                                             updateCheckedRows(rowData.id, 'auditor1', e.target.value);
                                             setTableError('');
                                        }}
                                   />
                              ) : (
                                   <Typography>
                                        {
                                             tableData[rowData['index']]['auditor1Name'] !== '' ? tableData[rowData['index']]['auditor1Name'] : '-'
                                        }
                                   </Typography>
                              )}
                         </React.Fragment>
                    );
               },
          },
          { 
               title: "Auditor 2", 
               field: "auditor2",
               filtering: false,
               render: rowData => {
                    // console.log(rowData);
                    return (
                         <React.Fragment>
                              {adminRole ? (
                                   <Dropdown
                                        labelkey="id"
                                        labelname="name"
                                        name="auditor2"
                                        placeholder="Select Auditor 2"
                                        value={tableData[rowData['index']]['auditor2']}
                                        dropdown={auditor2Dropdown}
                                        onChange={e => {
                                             let tempData = [...tableData];
                                             tempData[rowData['index']]['auditor2'] = e.target.value;
                                             setTableData(tempData);
                                             updateCheckedRows(rowData.id, 'auditor2', e.target.value);
                                        }}
                                   />
                              ) : (
                                   <Typography>
                                        {
                                             tableData[rowData['index']]['auditor2Name'] !== '' ? tableData[rowData['index']]['auditor2Name'] : '-'
                                        }
                                   </Typography>
                              )}
                         </React.Fragment>
                    );
               }
          },
          { 
               title: "Update Counter",
               field: "update_counter",
               filtering: false, 
          },
          ...((adminRole && (approver1Status === 1) && (approver2Status === 1)) ? [
               { 
                    title: "Actions", 
                    field: "sendMail",
                    filtering: false,
                    render: rowData => {
                         // console.log(rowData['tableData']['checked']);
                         return (
                              <React.Fragment>
                                   {(rowData['tableData']['checked'] === true) && (
                                        <CustomButton
                                             iconButton={true}
                                             tooltip="Send Mail"
                                             variant="contained"
                                             color="info"
                                             icon="getMarkEmailReadIcon"
                                             onClick={e => {
                                                  handleSendMail(rowData['siteId']);
                                             }}
                                        />
                                   )}
                              </React.Fragment>
                         );
                    }
               }
          ] : []),
     ];

     const handleSendMail = (siteId) => {
          let mailData = {
               "fy_id" : financialYear,
               "site_id": siteId,
          };

          AuthMethods['auditPlan'].sendAuditMail(mailData, setMessage)
               .then(data => {
                    if (data.status ==='success') {
                         enqueueSnackbar('Mail sent successfully', {
                              variant: 'success'
                         });
                    }
               })
               .catch(error => {
                    // console.log(error);
                    if (error.code === 200 && error.status === 'error') {
                         enqueueSnackbar('Save plan details first and then send mail', {
                              variant: 'error'
                         });
                    }
               });
     };

     const handleAuditTable = () => {
          setShowTable(true);
          if (showViewAudit) {
               setShowGantt(true);
          } else {
               setShowGantt(false);
          }
     };

     const handleChatDialogOpen = () => {
          setOpenDialog(true);
     };

     const handleGanttDialogOpen = () => {
          setOpenDialogGantt(true);
     };
     const updateCheckedRows = (id, name, value) => {
          // console.log(checkedRows);
          checkedRows['current'].forEach((item, index) => {
               if (item.id === id) {
                    checkedRows['current'][index][name] = value;
               }
          });
     };

     const handleCheckedRows = (rowData) => {
          // console.log(rowData);
          // console.log(tableData);
          let idArr = rowData.map(item => item.id);
          // console.log(idArr);
          let newTableData = tableData.map(item => {
               if (idArr.includes(item.id)) {
                    item['tableData']['checked'] = true;
               } else {
                    item['tableData']['checked'] = false;
               }
               // console.log(item);
               return item;
          });
          setTableData(newTableData);
          
          checkedRows.current = rowData;
     };

     const getFYDates = (year) => {

          if (year !== '') {
               // console.log(year);
               let startDate = new Date(year, 3, 1); // April is month 3 (0-based index)
               let endDate = new Date(parseInt(year) + 1, 2, 31); // March is month 2 (0-based index)

               // console.log('S', startDate);
               // console.log('E', endDate);
     
               setMinDate(startDate);
               setMaxDate(endDate);
          }
     };

     const handleExport = (rowData) => {
          let downloadColumns = ['srNo', 'stateName', 'siteName', 'auditee', 'capacity', 'doc', 'age', 'startDate', 'endDate', 'auditor1', 'auditor2'];

          // console.log(rowData);

          let newRowData = rowData.map(item => {
               // console.log(item);
               if (adminRole) {
                    if (item['startDate'] && item['endDate']) {
                         let startDate = item['startDate']['$d'].getFullYear() + '-' + ((item['startDate']['$d'].getMonth() + 1) > 9 ? (item['startDate']['$d'].getMonth() + 1) : '0' + (item['startDate']['$d'].getMonth() + 1)) + '-' + (item['startDate']['$d'].getDate() > 9 ? item['startDate']['$d'].getDate() : '0' + item['startDate']['$d'].getDate());
                         
                         let endDate = item['endDate']['$d'].getFullYear() + '-' + ((item['endDate']['$d'].getMonth() + 1) > 9 ? (item['endDate']['$d'].getMonth() + 1) : '0' + (item['endDate']['$d'].getMonth() + 1)) + '-' + (item['endDate']['$d'].getDate() > 9 ? item['endDate']['$d'].getDate() : '0' + item['endDate']['$d'].getDate());
     
                         item['startDate'] = startDate;
                         item['endDate'] = endDate;
                    }
                    item['auditor1'] = item['auditor1Name'];
                    item['auditor2'] = item['auditor2Name'];
                    return item;
               } else {
                    item['auditor1'] = item['auditor1Name'];
                    item['auditor2'] = item['auditor2Name'];
                    return item;
               }
          });

          new CsvBuilder(`AHA_SOLAR_${fyForExport}_AUDIT_PLAN.csv`)
               .setColumns(downloadColumns.map(item => {
                    let cols = [];
                    columns.forEach(el => {
                         if (el['field'] === item) {
                              cols.push(el['title']);
                         }
                    });
                    return cols.join();
               }))
               .addRows(
                    newRowData.map(item => 
                         downloadColumns.map(el => 
                              item[el]
                         )
                    )
               )
               .exportFile();
     };

     useEffect(() => {
          if (financialYear !== '') {

               // console.log(longName[financialYear]);
               setFY(longName[financialYear]);
               setLoading(true);

               AuthMethods['roles'].getUsersByRole(roleName, setLoading).then(data => {
                    // console.log(data);
                    if (data['data'].length > 0) {
                         if (data['data'][0]['user'].length > 0) {
                              let tempData = [];
                              data['data'][0]['user'].forEach(item => {
                                   let obj = {
                                        id: item.id,
                                        name: item.name
                                   }
                                   tempData.push(obj);
                              });
                              // console.log(tempData);
                              setAuditor1Dropdown(tempData);
                              setAuditor2Dropdown(tempData);
                         }
                    }
               });

               let yearName = '';
               fYDropdown.forEach(item => {
                    if (item['id'] === financialYear) {
                         yearName = item['name'];
                    }
               });
               getFYDates(yearName.split('-')[0]);

               let auditPlanData = {
                    fy_id: financialYear,
                    user_id: userId,
               };
               AuthMethods['auditPlan'].getAuditPlan(auditPlanData, setLoading).then(data => {
                    // console.log(data);

                    if (adminRole) {
                         if (data['audited_data'].length === 0) {
                              if (data['site'].length > 0) {
                                   let unauditedArr = [];
                                   data['site'].forEach((item, index) => {
                                        let unauditedObj = {
                                             index: index,
                                             id: item.id,
                                             srNo: index + 1,
                                             stateId: _.size(item['state']) > 0 ? item['state']['id'] : '',
                                             stateName: _.size(item['state']) > 0 ? item['state']['name'] : '',
                                             siteId: item.id,
                                             siteName: item.name,
                                             auditee: item['auditee'],
                                             capacity: item['ac_capacity'],
                                             doc: item['commission_date'],
                                             age: getAge(item['commission_date']),
                                             startDate: '',
                                             endDate: '',
                                             auditor1: '',
                                             auditor1Name: '',
                                             auditor2: '',
                                             auditor2Name: '',
                                             tableData: {
                                                  checked: false
                                             },
                                        };
                                        unauditedArr.push(unauditedObj);
                                   });
          
                                   // console.log(unauditedArr);
                                   setTableData(unauditedArr);
                                   setShowCreateAudit(true);
                                   setShowViewAudit(false);
                              }
                         } else {
                              // console.log(data);
                              let bothArr = [];
                              setAuditPlanID(data['audited_data'][0]['audit_plan']['id']);
                              setAdminStatus(data['audited_data'][0]['audit_plan']['admin_status']);
                              setApprover1Status(data['audited_data'][0]['audit_plan']['approver_1_status']);
                              setApprover2Status(data['audited_data'][0]['audit_plan']['approver_2_status']);
                              
                              data['audited_data'].forEach((item, index) => {
          
                                   let startDate = item['start_date'].split('-');
                                   let finalStartDate = dayjs(startDate[1] + '/' + startDate[2] + '/' + startDate[0]);
                                   let endDate = item['end_date'].split('-');
                                   let finalEndDate = dayjs(endDate[1] + '/' + endDate[2] + '/' + endDate[0]);
          
                                   let auditedData = {
                                        index: index,
                                        id: _.size(item['site']) > 0 ? item['site']['id'] : '',
                                        srNo: index + 1,
                                        stateId: _.size(item['state']) > 0 ? item['state']['id'] : '',
                                        stateName: _.size(item['state']) > 0 ? item['state']['name'] : '',
                                        siteId: _.size(item['site']) > 0 ? item['site']['id'] : '',
                                        siteName: _.size(item['site']) > 0 ? item['site']['name'] : '',
                                        auditee: _.size(item['site']) > 0 ? item['site'] ['auditee'] : '',
                                        capacity: _.size(item['site']) > 0 ? item['site'] ['ac_capacity'] : '',
                                        doc: _.size(item['site']) > 0 ? item['site']['commission_date'] : '',
                                        age: _.size(item['site']) > 0 ? getAge(item['site']['commission_date']) : '',
                                        startDate: finalStartDate,
                                        endDate: finalEndDate,
                                        auditor1: _.size(item['auditor_1']) > 0 ? item['auditor_1']['id'] : '',
                                        auditor1Name: _.size(item['auditor_1']) > 0 ? item['auditor_1']['name'] : '',
                                        auditor2: _.size(item['auditor_2']) > 0 ? item['auditor_2']['id'] : '',
                                        auditor2Name: _.size(item['auditor_2']) > 0 ? item['auditor_2']['name'] : '',
                                        update_counter: item['update_counter'],
                                        tableData: {
                                             checked: true
                                        },
                                   };
                                   // console.log(auditedData);

                                   bothArr.push(auditedData);
                              });
                              checkedRows.current = [...bothArr];
     
                              let length = bothArr.length;
                              // console.log(length);
     
                              if (data['site'].length > 0) {
                                   data['site'].forEach((item, index) => {
          
                                        let restData = {
                                             index: index + length,
                                             id: item.id,
                                             srNo: index + 1 + length,
                                             stateId: _.size(item['state']) > 0 ? item['state']['id'] : '',
                                             stateName: _.size(item['state']) > 0 ? item['state']['name'] : '',
                                             siteId: item.id,
                                             siteName: item.name,
                                             auditee: item.auditee,
                                             capacity: item['ac_capacity'],
                                             doc: item['commission_date'],
                                             age: getAge(item['commission_date']),
                                             startDate: '',
                                             endDate: '',
                                             auditor1: '',
                                             auditor1Name: '',
                                             auditor2: '',
                                             auditor2Name: '',
                                             tableData: {
                                                  checked: false
                                             },
                                        };
                                        // console.log(restData);
                                        
                                        bothArr.push(restData); 
                                   });
                              }                         
          
                              // console.log(bothArr);
                              setTableData(bothArr);
                              setShowCreateAudit(false);
                              setShowViewAudit(true);
                         }
                    } else {

                         if (data['audited_data'].length > 0) {
                              setAuditPlanID(data['audited_data'][0]['audit_plan']['id']);
                              setAdminStatus(data['audited_data'][0]['audit_plan']['admin_status']);
                              setApprover1Status(data['audited_data'][0]['audit_plan']['approver_1_status']);
                              setApprover2Status(data['audited_data'][0]['audit_plan']['approver_2_status']);
                              
                              let approverArr = [];
                              data['audited_data'].forEach((item, index) => {
                                   let approverObj = {
                                        index: index,
                                        id: _.size(item['site']) > 0 ? item['site']['id'] : '',
                                        srNo: index + 1,
                                        stateId: _.size(item['state']) > 0 ? item['state']['id'] : '',
                                        stateName: _.size(item['state']) > 0 ? item['state']['name'] : '',
                                        siteId: _.size(item['site']) > 0 ? item['site']['id'] : '',
                                        siteName: _.size(item['site']) > 0 ? item['site']['name'] : '',
                                        auditee: _.size(item['site']) > 0 ? item['site'] ['auditee'] : '',
                                        capacity: _.size(item['site']) > 0 ? item['site'] ['ac_capacity'] : '',
                                        doc: _.size(item['site']) > 0 ? item['site']['commission_date'] : '',
                                        age: _.size(item['site']) > 0 ? getAge(item['site']['commission_date']) : '',
                                        startDate: item['start_date'],
                                        endDate: item['end_date'],
                                        auditor1: _.size(item['auditor_1']) > 0 ? item['auditor_1']['id'] : '',
                                        auditor1Name: _.size(item['auditor_1']) > 0 ? item['auditor_1']['name'] : '',
                                        auditor2: _.size(item['auditor_2']) > 0 ? item['auditor_2']['id'] : '',
                                        auditor2Name: _.size(item['auditor_2']) > 0 ? item['auditor_2']['name'] : '',
                                        update_counter: item['update_counter'],
                                        tableData: {
                                             checked: true
                                        },

                                   };
                                   approverArr.push(approverObj);
                              });
                              // console.log(approverArr);
                              setTableData(approverArr);
                              setShowCreateAudit(false);
                              setShowViewAudit(true);
                         } else {
                              setTableData([]);
                              setShowCreateAudit(false);
                              setShowViewAudit(true);
                         }

                    }
               });
          }

     }, [financialYear, tableDataChanged]);

     useEffect(() => {

          let roles = localStorage.getItem('roles').split(',');

          if (roles.length > 0) {
               let adminRole = ''
               let approver1Role = '';
               let approver2Role = '';

               roles.forEach(item => {
                    if (item === 'admin') {
                         adminRole = true;
                    }
                    if (item === 'approver_1') {
                         approver1Role = true;
                    }
                    if (item === 'approver_2') {
                         approver2Role = true;
                    }
               });
               setAdminRole(adminRole);
               setApprover1Role(approver1Role);
               setApprover2Role(approver2Role);
          }

          AuthMethods['common'].getFinancialYear().then(data => {
               // console.log(data);
               if (data['data'].length > 0) {
                    let arr = [];
                    let shortNameToIdObj = {};
                    let idToLongNameObj = {};
                    data['data'].forEach(item => {
                         if (isUndefined(shortNameToIdObj[item['short_name']])) {
                              shortNameToIdObj[item['short_name']] = '';
                         }
                         shortNameToIdObj[item['short_name']] = item['id'];
                         if (isUndefined(idToLongNameObj[item['id']])) {
                              idToLongNameObj[item['id']] = '';
                         }
                         idToLongNameObj[item['id']] = item['long_name'];
                         let obj = {
                              id: item['id'],
                              name: item['long_name']
                         };
                         arr.push(obj);
                    });
                    setFYDropdown(arr);
                    setLongName(idToLongNameObj); 
                    
                    let currentYear = new Date().getFullYear().toString().split('')[2] + new Date().getFullYear().toString().split('')[3]; 
                    let currentFy = (currentYear + '-' + (parseInt(currentYear) + 1));
                    // console.log(currentFy);
                    setFinancialYear(shortNameToIdObj[currentFy]);
                    setFyForExport(currentFy);
               }
          });

     }, []);

     return (

          <React.Fragment>

               <Box mb={3}>
                    <Typography
                         sx={{ 
                         color: theme => theme.palette.text.primary,
                         fontSize: '21px',
                         fontWeight: 'bold'
                         }}
                         >
                              Audit Plan
                    </Typography>
               </Box>

               <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="flex-end">               
                    <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                         <Box sx={{ p: 1, width: 200 }}>
                              <InputLabel
                                   sx={{ 
                                        color: theme => theme.palette.text.secondary,
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                        pb: '3px'
                                   }}
                              >
                                   Financial Year
                              </InputLabel>
                              <Dropdown
                                   labelkey="id"
                                   labelname="name"
                                   name="financialYear"
                                   placeholder="Select Financial Year"
                                   value={financialYear}
                                   dropdown={fYDropdown}
                                   onChange={e => {
                                        setFinancialYear(e.target.value);
                                        setShowTable(false);
                                        setTableData([]);
                                        setShowCreateAudit(false);
                                        setShowViewAudit(false);
                                        setShowGantt(false);
                                        setTableError('');
                                        setAuditPlanID('');
                                        setAdminStatus('');
                                        setApprover1Status('');
                                        setApprover2Status('');
                                   }}
                                   disabled={false}
                              />
                         </Box>
                         {showCreateAudit &&
                              <Box p={1}>
                                   <CustomButton
                                        variant="contained"
                                        color="primary"
                                        icon=""
                                        onClick={handleAuditTable}
                                        >
                                             Create Audit Plan
                                   </CustomButton>
                              </Box>
                         }
                         {showViewAudit && 
                              <Box p={1}>
                                   <CustomButton variant="contained" color="primary" icon="" onClick={handleAuditTable}>
                                        View Audit Plan
                                   </CustomButton>
                              </Box>            
                         }   
                         {showGantt && 
                              <Box p={1}>
                                   <CustomButton
                                        variant="contained"
                                        color="purple"
                                        icon=""
                                        onClick={handleGanttDialogOpen}
                                   >
                                        View Gantt Chart
                                   </CustomButton>
                              </Box>            
                         }
                    </Box>

                    {showTable && (
                         <AuditPlanHeader
                              financialYear={financialYear}
                              checkedRows={checkedRows} 
                              setTableError={setTableError}
                              setTableDataChanged={setTableDataChanged}
                              setLoading={setLoading}
                              auditPlanID={auditPlanID}
                              adminRole={adminRole}
                              approver1Role={approver1Role}
                              approver2Role={approver2Role}
                              adminStatus={adminStatus}
                              approver1Status={approver1Status}
                              approver2Status={approver2Status}
                         />
                    )}
                    
               </Box>

               {showTable && (
                    <React.Fragment>

                         <Box p={1} mb={2}>
                              <CustomButton
                                   variant="contained"
                                   color="purple"
                                   icon=""
                                   onClick={handleChatDialogOpen}
                              >
                                   Audit Plan Comments
                              </CustomButton>
                         </Box>

                         <SolarTable
                              title="Audit Plan Table"
                              columns={columns}
                              data={tableData}
                              filtering={false}
                              loading={loading}
                              selection={true}
                              onSelectionChange={rowData => handleCheckedRows(rowData)}
                              selectionProps={{disabled: adminRole ? false : true}}
                              tableError={tableError}
                              showError={true}
                              columnsButton={false}
                              sendMail={true}
                              actions={[
                                   {
                                        icon: () => { return <SaveAltIcon color="primary" />; },
                                        tooltip: "Export",
                                        onClick: (event, rowData) => {
                                             handleExport(rowData);
                                        }
                                   },
                              ]}
                              detailPanel={[]}
                         />

                         <CustomDialog openDialog={openDialog}>
                              <AuditPlanChatBox 
                                   setOpenDialog={setOpenDialog} 
                                   auditPlanID={auditPlanID}
                              />
                         </CustomDialog>

                         <CustomDialog maxWidth={'xl'} openDialog={openDialogGantt}>
                              <GanttChart 
                                   fyId={financialYear}
                                   fy={fy}
                                   setOpenDialog={setOpenDialogGantt} 
                              />
                         </CustomDialog>
          
                    </React.Fragment>
               )}

          </React.Fragment>

     );
}